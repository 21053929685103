import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService, TranslatePipe } from '@keystone-angular/core';
import { catchError, concatMap, Observable, throwError } from 'rxjs';
import { ModalService } from '../../framework/modal/modal.service';
import { Inventory } from '../../home/product/models/inventory';
import { InventorySize } from '../../home/product/models/inventory-size';
import { InventorySizeRange } from '../../home/product/models/inventory-size-range';
import { SizeField } from '../../home/product/models/size-field';
import { SizeFieldRow } from '../../home/product/models/size-field-row';
import { PrintLabelsDialogCloseEvent } from '../models/print-labels-dialog-close-event';
import { PrintLabelsDialogComponent } from '../print-labels-dialog/print-labels-dialog.component';
import { AgentlessPrintingService } from './agentless-printing.service';

@Injectable()
export abstract class PrintService {
  protected pCanPrintBarcodeLabels: boolean;
  protected pCanPrintRfidLabels: boolean;
  protected pHasAgents: boolean;

  constructor(private agentlessPrintingService: AgentlessPrintingService,
              private httpClient: HttpClient,
              private modalService: ModalService,
              private tokenService: TokenService,
              private translatePipe: TranslatePipe) {
    this.pHasAgents = false;
    this.pCanPrintBarcodeLabels = true;
    this.pCanPrintRfidLabels = false;
  }

  canPrintLabels(rfidPrintingForbiddenForProduct: boolean): boolean {
    return this.pCanPrintBarcodeLabels || (rfidPrintingForbiddenForProduct === false && this.pCanPrintRfidLabels);
  }

  canPrintLabelsWithAgent(rfidPrintingForbiddenForProduct: boolean): boolean {
    return this.pHasAgents && this.canPrintLabels(rfidPrintingForbiddenForProduct);
  }

  getPrintersStatus(rfidPrintingForbiddenForProduct: boolean): string {
    if (!this.pHasAgents) {
      return 'PrintAgentless';
    }

    if (rfidPrintingForbiddenForProduct && this.pCanPrintBarcodeLabels) {
      return 'PrintRfidDisabledBarcodeAvailable';
    }

    if (rfidPrintingForbiddenForProduct && !this.pCanPrintBarcodeLabels) {
      return 'PrintRfidDisabledSetDefaultPrinter';
    }

    if (!this.pCanPrintBarcodeLabels && this.pCanPrintRfidLabels) {
      return 'PrintRfidLabels';
    }

    if (!this.pCanPrintRfidLabels && this.pCanPrintBarcodeLabels) {
      return 'PrintBarcodeLabels';
    }

    if (!this.pCanPrintBarcodeLabels && !this.pCanPrintRfidLabels) {
      return 'SetDefaultPrinter';
    }

    if (this.pCanPrintBarcodeLabels && this.pCanPrintRfidLabels) {
      return 'PrintBarcodeAndRfidLabels';
    }

    return null;
  }

  hasAgents(): boolean {
    return this.pHasAgents;
  }

  print(labels: SizeFieldRow[] | InventorySizeRange[], productId: string, stockId: number, rfidPrintingForbiddenForProduct: boolean,
        successFn?: (successMessage: string) => void, errorFn?: (errorMessage: string) => void): void {
    if (this.pHasAgents) {
      this.printWithAgent(labels, productId, stockId, rfidPrintingForbiddenForProduct);
    } else {
      this.printWithoutAgent(labels, productId, successFn, errorFn);
    }
  }

  printWithAgent(labels: SizeFieldRow[] | InventorySizeRange[], productId: string, stockId: number,
                 rfidPrintingForbiddenForProduct: boolean,
                 successFn?: (successMessage: string) => void, errorFn?: (errorMessage: string) => void): void {
    const labelsToPrint = this.buildAgentPrintableLabels(labels, productId, stockId);

    const printModal = this.modalService.open(PrintLabelsDialogComponent, {
      backdrop: 'static',
      class: 'modal-xl',
      initialState: {
        title: (selectedPrinter: string) => this.translatePipe.transform(
          selectedPrinter === 'barcode' ? 'Pro_PrintBarcodeLabels' : 'Pro_PrintRfidLabels'
        ),
        type: 'product',
        toPrint: labelsToPrint,
        allowRfidPrint: !rfidPrintingForbiddenForProduct
      }
    });

    (printModal.content as PrintLabelsDialogComponent).close.subscribe(
      (closeEvent: PrintLabelsDialogCloseEvent) => {
        if (closeEvent.requestPerformed) {
          if (closeEvent.requestSucceeded) {
            if (successFn != null) {
              successFn(this.translatePipe.transform('App_LabelsSentForPrinting'));
            }
          } else {
            if (errorFn != null) {
              errorFn(closeEvent.errorMessage);
            }
          }
        }
      }
    );
  }

  printWithoutAgent(labels: SizeFieldRow[] | InventorySizeRange[], productId: string, successFn?: (successMessage: string) => void,
                    errorFn?: (errorMessage: string) => void): void {
    const labelsToPrint = this.buildAgentlessPrintableLabels(labels, productId);
    const printRequest = this.buildAgentlessPrintRequest(labelsToPrint);

    this.modalService.spin({
      animated: false,
      backdrop: 'static',
      initialState: {
        title: 'App_Loading',
        message: 'Pro_PrintBarcodeLabels_Processing',
        waitUntil: printRequest,
        successFn: (result: any) => successFn(null),
        errorFn
      }
    });
  }

  private buildAgentPrintableLabels(labels: SizeFieldRow[] | InventorySizeRange[], productId: string, stockId: number): Inventory {
    const isSizeFieldRow = (labels[0] as any)?.fields != null;
    const sizePropName = isSizeFieldRow ? 'fields' : 'sizes';
    const indexPropName = isSizeFieldRow ? 'size' : 'index';
    const qtyPropName = isSizeFieldRow ? 'qty' : 'quantity';

    return {
      productId: parseInt(productId, 10),
      stockId,
      sizeRanges: labels.map((inventoryLine: SizeFieldRow | InventorySizeRange) => {
        return {
          id: inventoryLine.id,
          name: inventoryLine.name,
          sizes: inventoryLine[sizePropName].map((sizeItem: SizeField | InventorySize) => {
            return {
              id: sizeItem.id,
              index: sizeItem[indexPropName],
              label: sizeItem.label,
              quantity: sizeItem[qtyPropName]
            };
          })
        };
      })
    };
  }

  private buildAgentlessPrintableLabels(labels: SizeFieldRow[] | InventorySizeRange[], productId: string): { [sku: string]: number } {
    const isSizeFieldRow = (labels[0] as any)?.fields != null;
    const sizePropName = isSizeFieldRow ? 'fields' : 'sizes';
    const indexPropName = isSizeFieldRow ? 'size' : 'index';
    const qtyPropName = isSizeFieldRow ? 'qty' : 'quantity';

    const labelsToPrint = {};

    labels.forEach((sizeRange: SizeFieldRow | InventorySizeRange) => {
      sizeRange[sizePropName].filter((sizeItem: SizeField | InventorySize) => sizeItem[qtyPropName] > 0)
        .forEach((sizeItem: SizeField | InventorySize) => {
          const sku = productId.toString().padStart(8, '0') +
            sizeRange.id.toString().padStart(2, '0') +
            sizeItem[indexPropName].toString().padStart(2, '0');

          labelsToPrint[sku] = sizeItem[qtyPropName];
        });
    });

    return labelsToPrint;
  }

  private buildAgentlessPrintRequest(labelsToPrint: { [sku: string]: number }): Observable<any> {
    return this.httpClient.post('api/labels', labelsToPrint, {
      headers: new HttpHeaders({
        Authorization: `Token ${this.tokenService.getToken()}`
      }),
      observe: 'response'
    }).pipe(
      catchError(reason => {
        const error = this.translatePipe.transform(`Pro_${this.getPrintRequestErrorCode(reason.status)}`);

        return throwError(error);
      }),
      concatMap(result => {
        return this.agentlessPrintingService.print({
          sameWindow: false,
          url: result.headers.get('location')
        });
      })
    );
  }

  private getPrintRequestErrorCode(statusCode: number) {
    switch (statusCode) {
      case 404:
        return 'ErrorGeneratingLabels404';
      case 500:
        return 'ErrorGeneratingLabels500';
      default:
        return 'ErrorGeneratingLabelsOther';
    }
  }
}

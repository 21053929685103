import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@keystone-angular/core';

const genderLabel = {
    m: 'Pro_GenderMale',
    f: 'Pro_GenderFemale',
    'm,f': 'Pro_GenderUnisex',
    'f,m': 'Pro_GenderUnisex',
    c: 'Pro_GenderChild',
    b: 'Pro_GenderBoy',
    g: 'Pro_GenderGirl',
    i: 'Pro_GenderInfant',
    y: 'Pro_GenderYouth'
};

@Pipe({
    name: 'gender'
})
export class GenderPipe implements PipeTransform {
    private translatePipe: TranslatePipe;

    constructor(translatePipe: TranslatePipe) {
        this.translatePipe = translatePipe;
    }

    transform(value: string) {
        const labelKey = value != null ? genderLabel[value.toLowerCase()] : null;

        return labelKey != null ? this.translatePipe.transform(labelKey) : '';
    }
}

import { Component } from '@angular/core';
import { FilterDefinition } from '../../../filtered-search/models/filter-definition';
import { FilterComponent } from '../filter.component';

@Component({
  selector: 'app-boolean-filter',
  templateUrl: './boolean-filter.component.html',
  styleUrls: ['./boolean-filter.component.scss']
})
export class BooleanFilterComponent extends FilterComponent<boolean> {
  initFromData(filterDefinition: FilterDefinition, filterValue: any) {
    super.initFromData(filterDefinition, filterValue);

    this.value = filterValue ?? filterDefinition.defaultValue;
  }
}

<div class="form-group" [ngClass]="{'invalid-field': hasErrors()}">
    <label *ngIf="hasLabel()" [for]="id">{{label}} <span *ngIf="mandatory">*</span></label>
    <div *ngIf="readOnly">
        <em *ngIf="!hasValue()" class="text-muted">{{noValueMessage || '-'}}</em>
        <span *ngIf="hasValue()">{{getValue()}}</span>
    </div>
    <dx-select-box [id]="id" [name]="id" class="form-devexpress"
                   *ngIf="!readOnly"
                   [noDataText]="canAddValues ? addValueMessage : 'No data to display'"
                   placeholder="{{'App_Select' | translate}}"
                   [acceptCustomValue]="canAddValues"
                   [displayExpr]="getDisplayExpr()"
                   [valueExpr]="getValueExpr()"
                   [searchExpr]="getSearchExpr()"
                   [searchEnabled]="true"
                   [dataSource]="options"
                   [disabled]="isDisabled"
                   [value]="value"
                   (onCustomItemCreating)="handleAdd($event)"
                   (onValueChanged)="handleChange($event)"
                   (onClosed)="handleTouch()"
                   (onFocusOut)="handleTouch()">
    </dx-select-box>
    <div *ngIf="hasErrors()" class="validation-error-msg">{{error}}</div>
    <div *ngIf="hasFooter" class="form-footer">
        <ng-content select="field-footer"></ng-content>
    </div>
</div>

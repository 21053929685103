import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent {
    // tslint:disable-next-line: no-output-on-prefix
    @Output() onShown: EventEmitter<void> = new EventEmitter();
    // tslint:disable-next-line: no-output-on-prefix
    @Output() onHidden: EventEmitter<void> = new EventEmitter();

    isShown: boolean;

    setShownStatus(isShown: boolean) {
        this.isShown = isShown;

        if (isShown) {
            this.onShown.emit();
        } else {
            this.onHidden.emit();
        }
    }
}

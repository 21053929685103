export class StoreAccess {
  id: string;
  tempId?: number;
  storeId: number;
  storeName?: string;
  roleId: number;
  roleName?: string;
  changed?: boolean;
  removed?: boolean;
  hasAccessToPos: boolean;

  // TODO: Should we move this out of here? And give proper type to store
  storeChanged(store: any) {
    this.storeId = store.id;
    this.storeName = store.name;
    this.changed = true;
  }

  // TODO: Should we move this out of here? And give proper type to role
  roleChanged(role: any) {
    this.roleId = role ? role.id : null;
    this.roleName = role ? role.name : null;
    this.changed = true;
  }
}

import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService, FormUrlEncoder } from '@keystone-angular/core';
import { Observable } from 'rxjs';

export interface LogInResponse {
    permissions: {};
    token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationDataService {
    private baseUrl = 'authentication';

    constructor(private dataService: DataService) { }

    addUser(user: any): Observable<any> {
        const payload = new HttpParams({
            encoder: new FormUrlEncoder(),
            fromObject: user
        });

        return this.dataService.post(`${this.baseUrl}/user/register/`, payload, {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        });
    }

    changePassword(changePassword: any): Observable<any> {
        const payload = new HttpParams({
            encoder: new FormUrlEncoder(),
            fromObject: {
                newPassword: changePassword.newPassword,
                passwordConfirmation: changePassword.passwordConfirmation,
                token: changePassword.token
            }
        });

        return this.dataService.post(`${this.baseUrl}/password/change/`, payload, {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        });
    }

    confirmUserNameChange(token: any): Observable<any> {
        const payload = new HttpParams({
            encoder: new FormUrlEncoder(),
            fromObject: {
                token
            }
        });

        return this.dataService.post(`${this.baseUrl}/email/confirm/`, payload, {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        });
    }

    getPermissions(): Observable<any> {
        return this.dataService.get(`${this.baseUrl}/permissions`);
    }

    getPersonInfo(token: any): Observable<any> {
        return this.dataService.get(`${this.baseUrl}/user/register/${token}`, {
            isBlocking: false
        });
    }

    getStores(): Observable<any> {
        return this.dataService.get(`${this.baseUrl}/stores`);
    }

    logIn(credentials: any): Observable<HttpResponse<LogInResponse>> {
        const payload = new HttpParams({
            encoder: new FormUrlEncoder(),
            fromObject: {
                UserName: credentials.userName,
                Password: credentials.password
            }
        });

        return this.dataService.post(`${this.baseUrl}/login/?offlineAccess=true`, payload, {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            }),
            observe: 'response',
            withCredentials: true
        });
    }

    logOut(): Observable<any> {
        return this.dataService.post(`${this.baseUrl}/logout`, {}, {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        });
    }

    refreshToken(): Observable<HttpResponse<LogInResponse>> {
        return this.dataService.post(`${this.baseUrl}/refresh`, {}, {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
            observe: 'response',
            withCredentials: true
        });
    }

    resetPassword(emailAddress: string): Observable<any> {
        const payload = new HttpParams({
            encoder: new FormUrlEncoder(),
            fromObject: {
                emailAddress
            }
        });

        return this.dataService.post(`${this.baseUrl}/password/reset`, payload, {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        });
    }
}

import { MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { environment } from '../../environments/environment';

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  const scopes: any = [...new Set(environment.b2c.protectedResources
    .map(resource => resource.scopes || [])
    .reduce((cur, acc) => acc.concat(cur), [])
  )];

  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes
    },
    loginFailedRoute: 'auth/login-failed'
  };
}

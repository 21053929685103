import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'key-checkbox-field',
    templateUrl: './checkbox-field.component.html',
    styleUrls: ['./checkbox-field.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CheckboxFieldComponent),
        multi: true
    }]
})
export class CheckboxFieldComponent implements ControlValueAccessor {
    @Input() id = '';
    @Input() label: string;
    @Input() mandatory: boolean;
    @Input() readOnly: boolean;
    @Input() error: string;

    value: boolean;
    isDisabled: boolean;

    onChange: (_: any) => void = (_: any) => { };
    onTouched: () => void = () => { };

    constructor() {
        this.value = false;
        this.isDisabled = false;
    }

    handleChange(checked: any) {
        this.value = checked || false;

        this.handleTouch();
        this.onChange(this.value);
    }

    handleTouch() {
        this.onTouched();
    }

    hasErrors() {
        return this.error != null && this.error !== '';
    }

    hasLabel() {
        return this.label != null && this.label !== '';
    }

    writeValue(value: boolean): void {
        this.value = value || false;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}

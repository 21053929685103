import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { ContextService } from './context.service';

@Directive({
  selector: '[appIfSuperAdmin]'
})
export class IfSuperAdminDirective {
    constructor(contextService: ContextService, templateRef: TemplateRef<any>, viewContainer: ViewContainerRef) {
        if (contextService.user?.isSuperAdmin) {
            viewContainer.createEmbeddedView(templateRef);
        } else {
            viewContainer.clear();
        }
    }
}

// tslint:disable: no-unused-expression
import { AfterContentInit, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

export type MinimumTaxableAmount = {
  minimumAmount: number;
  taxationOnlyOnExceedingAmount: boolean;
};

@Component({
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MinimumTaxableAmountFieldComponent)
    }
  ],
  selector: 'app-minimum-taxable-amount-field',
  templateUrl: './minimum-taxable-amount-field.component.html',
  styleUrls: ['./minimum-taxable-amount-field.component.scss']
})
export class MinimumTaxableAmountFieldComponent implements AfterContentInit, ControlValueAccessor, OnDestroy  {
  @Input() id = '';
  @Input() isDisabled: boolean;

  minimumTaxableAmountFormControl: FormControl;
  minimumTaxableAmount: MinimumTaxableAmount;
  taxableAmountFormControl: FormControl;

  private minimumTaxableAmountValueChangesSubscription: Subscription;
  private onChange: (minimumTaxableAmount: MinimumTaxableAmount) => void;
  private onTouched: (minimumTaxableAmount: MinimumTaxableAmount) => void;
  private taxableAmountValueChangesSubscription: Subscription;

  ngAfterContentInit(): void {
    this.minimumTaxableAmountFormControl = new FormControl({
      disabled: this.isDisabled,
      value: this.minimumTaxableAmount && this.minimumTaxableAmount.minimumAmount
    });
    this.minimumTaxableAmountValueChangesSubscription =
      this.minimumTaxableAmountFormControl.valueChanges.subscribe(minimumTaxableAmount => {
        this.minimumTaxableAmount.minimumAmount = minimumTaxableAmount;

        this.setFormValues();
        if (this.onChange) {
          this.onChange(this.minimumTaxableAmount);
        }
      });

    this.taxableAmountFormControl = new FormControl({
      disabled: this.isDisabled,
      value: this.minimumTaxableAmount && this.minimumTaxableAmount.taxationOnlyOnExceedingAmount
    });
    this.taxableAmountValueChangesSubscription =
      this.taxableAmountFormControl.valueChanges.subscribe(taxableAmount => {
        this.minimumTaxableAmount.taxationOnlyOnExceedingAmount = taxableAmount;

        this.setFormValues();
        if (this.onChange) {
          this.onChange(this.minimumTaxableAmount);
        }
      });
  }

  ngOnDestroy(): void {
    this.minimumTaxableAmountValueChangesSubscription.unsubscribe();
    this.taxableAmountValueChangesSubscription.unsubscribe();
  }

  registerOnChange(onChange: (minimumTaxableAmount: MinimumTaxableAmount) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: (minimumTaxableAmount: MinimumTaxableAmount) => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.setFormIsDisabled();
  }

  writeValue(minimumTaxableAmount: MinimumTaxableAmount): void {
    this.minimumTaxableAmount = minimumTaxableAmount;
    this.setFormValues();
    this.setFormIsDisabled();
  }

  private setFormIsDisabled(): void {
    if (this.isDisabled) {
      this.minimumTaxableAmountFormControl
        && this.minimumTaxableAmountFormControl.enabled
        && this.minimumTaxableAmountFormControl.disable();
      this.taxableAmountFormControl
        && this.taxableAmountFormControl.enabled
        && this.taxableAmountFormControl.disable();
    } else {
      this.minimumTaxableAmountFormControl
        && this.minimumTaxableAmountFormControl.disabled
        && this.minimumTaxableAmountFormControl.enable();
      this.taxableAmountFormControl
        && this.taxableAmountFormControl.disabled
        && this.taxableAmountFormControl.enable();
    }
  }

  private setFormValues(): void {
    this.minimumTaxableAmountFormControl && this.minimumTaxableAmountFormControl.setValue(
      (this.minimumTaxableAmount && this.minimumTaxableAmount.minimumAmount) || 0,
      { emitEvent: false }
    );
    this.taxableAmountFormControl && this.taxableAmountFormControl.setValue(
      (this.minimumTaxableAmount && this.minimumTaxableAmount.taxationOnlyOnExceedingAmount) || false,
      { emitEvent: false }
    );
  }
}

<div>
  <div class="row">
    <div class="col-xs-12 text-center">
      <h3>{{header}}</h3>
      <span class="text-muted">{{hint}}</span>
    </div>
  </div>
  <div class="row margin-25">
    <div class="col-xs-12 text-center">
      <img src="/images/horizontal-loader.gif" alt="...">
    </div>
  </div>
</div>

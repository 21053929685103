<div class="form-group">
  <label for="name">{{label}}</label>
  <dx-select-box id="name"
                 placeholder="{{'App_Select' | translate}}"
                 displayExpr="label"
                 valueExpr="value"
                 noDataText="{{'App_NoDataToDisplay' | translate}}"
                 [searchEnabled]="true"
                 [dataSource]="options"
                 [value]="value"
                 (onValueChanged)="handleSelection($event)"
                 (onOpened)="blockBody()"
                 (onClosed)="releaseBody()"
                 (onItemClick)="releaseBody()">
  </dx-select-box>
</div>

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from '@keystone-angular/core';
import { pickBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { Group } from './models/group';

export interface GroupPage {
    items: Group[];
    totalCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class GroupDataService {
    private baseUrl = 'product-group';

    constructor(private dataService: DataService) { }

    deleteGroup(id: any): Observable<any> {
        return this.dataService.delete(`${this.baseUrl}/${id}`);
    }

    deleteSubgroup(id: any): Observable<any> {
        return this.dataService.delete(`${this.baseUrl}/subgroup/${id}`);
    }

    getProductGroup(id: any): Observable<any> {
        return this.dataService.get(`${this.baseUrl}/${id}`);
    }

    getProductGroups(params: any): Observable<GroupPage> {
        return this.dataService.get(this.baseUrl, {
            params: new HttpParams({
                fromObject: pickBy(params)
            })
        });
    }

    getProductGroupsOnly(): Observable<any> {
        return this.dataService.get(`${this.baseUrl}/groups`);
    }

    getProductSubgroup(id: any): Observable<any> {
        return this.dataService.get(`${this.baseUrl}/subgroup/${id}`);
    }

    getVATList(): Observable<any> {
        return this.dataService.get(`${this.baseUrl}/vatlist`);
    }

    saveProductGroup(productGroup: Group): Observable<any> {
        return this.dataService.post(this.baseUrl, productGroup);
    }

    updateProductGroup(productGroup: Group): Observable<any> {
        return this.dataService.post(`${this.baseUrl}/${productGroup.id}`, productGroup);
    }
}

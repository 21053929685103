// tslint:disable: no-unused-expression
import { AfterContentInit, Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { MinimumTaxableAmount } from '../../../../../../framework/ui/form-fields/minimum-taxable-amount-field/minimum-taxable-amount-field.component';
import { DefaultRegionVAT } from '../../../../../../shared/settings-old/models/default-region-vat';
import { RegionVATBase } from '../../../../../../shared/settings-old/models/region-vat-base';

@Component({
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RegionVatFieldComponent)
    }
  ],
  // tslint:disable-next-line: component-selector
  selector: 'key-region-vat-field',
  templateUrl: './region-vat-field.component.html',
  styleUrls: ['./region-vat-field.component.scss']
})
export class RegionVatFieldComponent implements ControlValueAccessor, OnDestroy, AfterContentInit {
  @Input()
  set description(description: string) {
    this._description = description || '';
  }

  get description(): string {
    return this._description;
  }

  @Input()
  set isLocked(isLocked: boolean) {
    this._isLocked = isLocked || false;
    this.setFormIsDisabled();
  }

  get isLocked(): boolean {
    return this._isLocked;
  }

  @Input()
  set title(title: string) {
    this._title = title || '';
  }

  get title(): string {
    return this._title;
  }

  @Input() id: string;
  @Input() lockable: boolean;
  @Input() lockTitle: string;
  @Input() vats: Observable<any[]>;

  @Output() toggleLock = new EventEmitter<boolean>();

  isDisabled: boolean;
  minimumTaxableAmountFormControl: FormControl;
  regionVAT: RegionVATBase;
  vatIdFormControl: FormControl;

  // tslint:disable: variable-name
  private _description: string;
  private _isLocked: boolean;
  private _title: string;

  get isDefaultRegionVAT(): boolean {
    return this.regionVAT instanceof DefaultRegionVAT;
  }

  get isHighVATArea(): boolean {
    return this.regionVAT.vatAreaId === 1;
  }

  private minimumTaxableAmountValueChangesSubscription: Subscription;
  private onChange: (regionVAT: RegionVATBase) => void;
  private onTouched: (regionVAT: RegionVATBase) => void;
  private vatIdValueChangesSubscription: Subscription;

  ngOnDestroy(): void {
    this.minimumTaxableAmountValueChangesSubscription.unsubscribe();
    this.vatIdValueChangesSubscription.unsubscribe();
  }

  ngAfterContentInit(): void {
    this.minimumTaxableAmountFormControl = new FormControl({
      disabled: this.isDisabled || (this.lockable && this.isLocked),
      value: this.regionVAT && {
        minimumAmount: this.regionVAT.minimumAmount,
        taxationOnlyOnExceedingAmount: this.regionVAT.taxationOnlyOnExceedingAmount
      }
    });
    this.minimumTaxableAmountValueChangesSubscription =
      this.minimumTaxableAmountFormControl.valueChanges.subscribe((minimumTaxableAmount: MinimumTaxableAmount) => {
        this.regionVAT.minimumAmount = minimumTaxableAmount.minimumAmount;
        this.regionVAT.taxationOnlyOnExceedingAmount = minimumTaxableAmount.taxationOnlyOnExceedingAmount;

        this.setFormValues();
        if (this.onChange) {
          this.onChange(this.regionVAT);
        }
      });

    this.vatIdFormControl = new FormControl({
      disabled: this.isDisabled || (this.lockable && this.isLocked),
      value: this.regionVAT && this.regionVAT.vatId
    });
    this.vatIdValueChangesSubscription =
      this.vatIdFormControl.valueChanges.subscribe(vatId => {
        this.regionVAT.vatId = vatId;

        this.setFormValues();
        if (this.onChange) {
          this.onChange(this.regionVAT);
        }
      });
  }

  onToggleLock(): void {
    this.isLocked = !this.isLocked;
    this.toggleLock.emit(this.isLocked);
  }

  registerOnChange(onChange: (regionVAT: RegionVATBase) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: (regionVAT: RegionVATBase) => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.setFormIsDisabled();
  }

  writeValue(regionVAT: RegionVATBase): void {
    this.regionVAT = regionVAT;
    this.setFormValues();
    this.setFormIsDisabled();
  }

  private setFormIsDisabled(): void {
    if (this.isDisabled || (this.lockable && this.isLocked)) {
      this.minimumTaxableAmountFormControl
        && this.minimumTaxableAmountFormControl.enabled
        && this.minimumTaxableAmountFormControl.disable();
      this.vatIdFormControl
        && this.vatIdFormControl.enabled
        && this.vatIdFormControl.disable();
    } else {
      this.minimumTaxableAmountFormControl
        && this.minimumTaxableAmountFormControl.disabled
        && this.minimumTaxableAmountFormControl.enable();
      this.vatIdFormControl
        && this.vatIdFormControl.disabled
        && this.vatIdFormControl.enable();
    }
  }

  private setFormValues(): void {
    this.minimumTaxableAmountFormControl && this.minimumTaxableAmountFormControl.setValue(this.regionVAT && {
      minimumAmount: this.regionVAT.minimumAmount || 0,
      taxationOnlyOnExceedingAmount: this.regionVAT.taxationOnlyOnExceedingAmount || false
    }, { emitEvent: false });
    this.vatIdFormControl && this.vatIdFormControl.setValue(
      this.regionVAT && this.regionVAT.vatId,
      { emitEvent: false }
    );
  }
}

// tslint:disable
declare const analytics: any;

import { Injectable } from '@angular/core';
import { ContextService } from '../framework/auth/context.service';

@Injectable({
  providedIn: 'root'
})
export class SegmentService {
  constructor(private contextService: ContextService) {
    (window as any).analytics = (window as any).analytics || []; if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
      analytics.invoked = !0; analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"]; analytics.factory = function (e) { return function () { var t = Array.prototype.slice.call(arguments); t.unshift(e); analytics.push(t); return analytics } }; for (var e = 0; e < analytics.methods.length; e++) { var key = analytics.methods[e]; analytics[key] = analytics.factory(key) } analytics.load = function (key, e) { var t = document.createElement("script"); t.type = "text/javascript"; t.async = !0; t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js"; var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(t, n); analytics._loadOptions = e }; analytics.SNIPPET_VERSION = "4.13.1";
      analytics.load("eFlyx33MeUCE17r69OBJ0cZLWEx20mcM");
      //analytics.page();
    }

    const user = this.contextService.user;
    if (user) {
      analytics.identify(user.userId, {
        name: user.fullName,
        email: user.email,
        language: user.locale,
        subscription: user.store?.currentSubscription,
        storeCluster: user.storeCluster,
      });
    }
  }

  logPageView(previousUrl: string, url: string) {
    const user = this.contextService.user;
    if (analytics !== 'undefined' && !!user) {
      analytics.page(document.title,
        {
          type: 'page',
          name: document.title,
          properties: {
            url
          },
          referrer: previousUrl,
          userId: user.userId,
          groupId: 'StoreId_' + user.storeId,
          title: document.title
        });
    }
  }
}

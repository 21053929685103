<div class="dropdown">
  <button #dropdownDateRange id="dropdown-date-range" class="dropdown-toggle dropdown-menu-end date-range-button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ getLabel() }}</button>
  <div class="dropdown-menu dropdown-date-range-picker"
       aria-labelledby="dropdown-date-range"
       (click)="$event.stopPropagation()">
    <div class="date-range-manu">
      <button class="btn btn-default btn-small btn-block" [ngClass]="{'btn-primary' : type === DateRangeType.Custom }" (click)="setType(DateRangeType.Custom)" *ngIf="allowCustomRange" bsDateRangePicker>{{'Rep_DateRangeCustom' | translate}}</button>
      <div *ngIf="type === DateRangeType.Custom" class="custom-date-range">
        <label>{{ 'App_From' | translate }}</label>
        <dx-date-box [(value)]="dateFrom"
                     (onOpened)="blockBody()"
                     (onClosed)="releaseBody()"
                     (onItemClick)="releaseBody()"
                     [displayFormat]="displayFormat">
        </dx-date-box>
        <label>{{ 'App_To' | translate }}</label>
        <dx-date-box [(min)]="dateFrom"
                     [(value)]="dateTo"
                     (onOpened)="blockBody()"
                     (onClosed)="releaseBody()"
                     (onItemClick)="releaseBody()"
                     [displayFormat]="displayFormat">
        </dx-date-box>
      </div>
      <button class="btn btn-default btn-small btn-block" [ngClass]="{'btn-primary' : type === DateRangeType.Today }" (click)="setType(DateRangeType.Today)" *ngIf="allowToday">{{'Rep_DateRangeToday' | translate}}</button>
      <button class="btn btn-default btn-small btn-block" [ngClass]="{'btn-primary' : type === DateRangeType.Yesterday }" (click)="setType(DateRangeType.Yesterday)">{{'Rep_DateRangeYesterday' | translate}}</button>
      <button class="btn btn-default btn-small btn-block" [ngClass]="{'btn-primary' : type === DateRangeType.ThisWeek }" (click)="setType(DateRangeType.ThisWeek)">{{'Rep_DateRangeThisWeek' | translate}}</button>
      <button class="btn btn-default btn-small btn-block" [ngClass]="{'btn-primary' : type === DateRangeType.LastWeek }" (click)="setType(DateRangeType.LastWeek)">{{'Rep_DateRangeLastWeek' | translate}}</button>
      <button class="btn btn-default btn-small btn-block" [ngClass]="{'btn-primary' : type === DateRangeType.ThisMonth }" (click)="setType(DateRangeType.ThisMonth)">{{'Rep_DateRangeThisMonth' | translate}}</button>
      <button class="btn btn-default btn-small btn-block" [ngClass]="{'btn-primary' : type === DateRangeType.LastMonth }" (click)="setType(DateRangeType.LastMonth)">{{'Rep_DateRangeLastMonth' | translate}}</button>
      <button class="btn btn-default btn-small btn-block" [ngClass]="{'btn-primary' : type === DateRangeType.ThisYear }" (click)="setType(DateRangeType.ThisYear)">{{'Rep_DateRangeThisYear' | translate}}</button>
      <button class="btn btn-default btn-small btn-block" [ngClass]="{'btn-primary' : type === DateRangeType.LastYear }" (click)="setType(DateRangeType.LastYear)">{{'Rep_DateRangePreviousYear' | translate}}</button>
    </div>
  </div>
</div>

import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { FilterDefinition } from '../filtered-search/models/filter-definition';
import { BooleanFilterComponent } from './filters/boolean-filter/boolean-filter.component';
import { DateFilterComponent } from './filters/date-filter/date-filter.component';
import { FilterComponent } from './filters/filter.component';
import { MultiSelectFilterComponent } from './filters/multi-select-filter/multi-select-filter.component';
import { SelectFilterComponent } from './filters/select-filter/select-filter.component';

const filterDict = {
    bool: BooleanFilterComponent,
    date: DateFilterComponent,
    dropdown: SelectFilterComponent,
    array: MultiSelectFilterComponent
};

@Component({
    selector: 'app-filter-button',
    templateUrl: './filter-button.component.html',
    styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent implements OnInit {
    @Input() set filters(filters: FilterDefinition[]) {
        this._filters = filters;
        this.buildFilters();
    }

    @Input() set value(value: { [filterName: string]: string }) {
        this._value = value || {};
        this.buildFilters();
    }

    get value(): { [filterName: string]: string } {
        return this._value;
    }

    // tslint:disable-next-line: no-output-on-prefix
    @Output() onChange: EventEmitter<{ [filterName: string]: string }> = new EventEmitter();

    @ViewChild('filterHost', { read: ViewContainerRef, static: true }) dropdownContentRef: ViewContainerRef;

    // tslint:disable-next-line: variable-name
    private _filters: FilterDefinition[] = [];
    // tslint:disable-next-line: variable-name
    private _value: { [filterName: string]: string } = {};

    constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

    ngOnInit() {
        this.buildFilters();
    }

    private buildFilters(): void {
        const viewContainerRef = this.dropdownContentRef;
        viewContainerRef.clear();

        if (this._filters && this._filters.length > 0) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this._filters.length; i++) {
                const filterDefinition = this._filters[i];
                const filterValue = this.value && this.value[filterDefinition.name];
                const filterComponent = this.getFilterComponent(filterDefinition);

                if (filterComponent != null) {
                    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(filterComponent);
                    const componentRef = viewContainerRef.createComponent(componentFactory);
                    const componentInstance = (componentRef.instance as FilterComponent<any>);

                    componentInstance.initFromData(filterDefinition, filterValue);
                    componentInstance.onChange.subscribe((value: any) => {
                        this.value[filterDefinition.name] = value;
                        this.onChange.emit(this.value);
                    });
                }
            }
        }
    }

    private getFilterComponent(filter: any): Type<FilterComponent<any>> {
        if (filter && filter.type) {
            return filterDict[filter.type] || null;
        }

        return null;
    }
}

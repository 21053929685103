import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { LabelSelectorStatus } from '../models/label-selector-status';
import { PurchaseOrderLabelsSelector } from './purchase-order-labels-selector';
import { OrderLine } from '../../home/flow-of-goods/receive-goods/models/order-line';
import { StockMovement } from '../../home/flow-of-goods/receive-goods/models/stock-movement';

@Component({
  selector: 'app-delivery-labels-selector',
  templateUrl: './purchase-order-labels-selector.component.html',
  styleUrls: ['./purchase-order-labels-selector.component.scss']
})
export class PurchaseOrderLabelsSelectorComponent implements OnInit, OnChanges {
  @Input() orderType: string;
  @Input() orderLines: any[];
  @Input() usingRfid: boolean;
  @Input() printReceivedItemsOnly: boolean;
  @Input() hideSizesWithoutGtinOrSku: boolean;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onChange: EventEmitter<LabelSelectorStatus> = new EventEmitter();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onLoad: EventEmitter<LabelSelectorStatus> = new EventEmitter();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onSet: EventEmitter<LabelSelectorStatus> = new EventEmitter(true);

  private initialized: boolean;

  private selector: PurchaseOrderLabelsSelector;

  constructor() {
    this.selector = new PurchaseOrderLabelsSelector();
    this.initialized = false;
  }

  ngOnInit(): void {
    this.initialized = this.selector.initialize(this.orderLines, this.getOrderType() === 'twostep',
      this.usingRfid, this.printReceivedItemsOnly);

    if (this.initialized) {
      this.onLoad.emit(this.selector.getCurrentStatus());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initialized) {
      if (changes.usingRfid != null) {
        this.selector.configure(this.getOrderType() === 'twostep', changes.usingRfid.currentValue, this.printReceivedItemsOnly);

        this.onSet.emit(this.selector.getCurrentStatus());
      }
    }
  }

  allItemsHaveBeenReceived(stockMovement: any): boolean {
    return stockMovement.quantityReceived >= stockMovement.quantityOrdered;
  }

  getTotalItems(stockMovement: any, orderLine: any): number {
    return this.selector.getTotalItems(orderLine.productId, stockMovement.sizeLabelId, stockMovement.size);
  }

  getTotalLabelsToPrint(stockMovement: any, orderLine: any): number {
    return this.selector.getTotalLabelsToPrint(orderLine.productId, stockMovement.sizeLabelId, stockMovement.size);
  }

  getOrderType(): string {
    return this.orderType === 'onestep' ? 'onestep' : 'twostep';
  }

  getTotalUnprintedLabels(stockMovement: any, orderLine: any): number {
    return this.selector.getTotalUnprintedLabels(orderLine.productId, stockMovement.sizeLabelId, stockMovement.size);
  }

  hasSingleSelectedOrderLine(): boolean {
    return this.orderLines && this.orderLines.length === 1;
  }

  updateTotalLabelsToPrint(stockMovement: any, orderLine: any, event: any): void {
    this.selector.setTotalLabelsToPrint(orderLine.productId, stockMovement.sizeLabelId, stockMovement.size, event.value);

    this.onChange.emit(this.selector.getCurrentStatus());
  }

  hasGtinOrSku(orderLine: OrderLine, stockMovement: StockMovement): boolean {
    if (!this.hideSizesWithoutGtinOrSku) {
      return true;
    }

    return orderLine?.product?.productIdentifiers?.find(x => x.label === stockMovement.sizeLabel) ?? false;
  }
}

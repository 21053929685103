import { PrintLabel } from './print-label';

export class PrintLabelsRequest {
    printerId: string;
    productId: number;
    size: number;
    sizeLabel: number;
    count: number;

    constructor(printerId: string, labelData: PrintLabel) {
        this.printerId = printerId;
        this.productId = labelData.productId;
        this.size = labelData.size;
        this.sizeLabel = labelData.sizeRange;
        this.count = labelData.count;
    }
}

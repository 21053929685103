import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService, TranslatePipe } from '@keystone-angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-tag-dialog',
  templateUrl: './tag-dialog.component.html',
  styleUrls: ['./tag-dialog.component.scss']
})
export class TagDialogComponent {
  // tslint:disable-next-line: no-output-rename no-output-native no-output-on-prefix
  @Output('close') onClose: EventEmitter<string> = new EventEmitter<string>();

  tagForm: FormGroup;
  showValidation: boolean;

  constructor(private bsModalRef: BsModalRef,
              private notificationService: NotificationService,
              private translatePipe: TranslatePipe) {
    this.tagForm = new FormGroup({
      tag: new FormControl(null, Validators.required)
    });

    this.showValidation = false;
  }

  close(): void {
    this.bsModalRef.hide();
    this.onClose.emit();
  }

  ok(): void {
    this.showValidation = false;

    if (this.tagForm.valid) {
      const tagFormValues = this.tagForm.getRawValue();
      const tag = tagFormValues.tag.trim();

      this.notificationService.showSuccess(this.translatePipe.transform('Pro_TagWasSuccessfullyAdded'));

      this.bsModalRef.hide();
      this.onClose.emit(tag);
    } else {
      this.showValidation = true;
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-print-notification',
  templateUrl: './print-notification.component.html',
  styleUrls: ['./print-notification.component.scss']
})
export class PrintNotificationComponent implements OnInit {

    @Input() show: boolean;
    @Input() singleOrderLine: boolean;

    constructor() { }

    ngOnInit() { }

}

import { Injectable } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SegmentService } from '../../vendors/segment.service';
import { ApplicationInsightsService } from '../logging/application-insights.service';

@Injectable({
    providedIn: 'root'
})
export class RouterHistoryService {
    get history(): string[] {
        return this._history;
    }

    get previousUrl(): string {
        return this.history[this.history.length - 2];
    }

    // tslint:disable-next-line: variable-name
    private _history: string[] = [];

    constructor(private applicationInsightsService: ApplicationInsightsService,
                private router: Router,
                private segmentService: SegmentService) { }

    public getHistory(): string[] {
        return this.history;
    }

    public getLastEntryOf(url: string): string {
        if (this.history.length <= 1) {
            return null;
        }

        for (let i = this.history.length - 2; i >= 0; i--) {
            const path = this.history[i].split(';')[0];

            if (path === url) {
                return this.history[i];
            }
        }

        return null;
    }

    public start(): void {
        this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe(({ urlAfterRedirects }: ResolveEnd) => {
            this._history = [...this._history, urlAfterRedirects];

            this.applicationInsightsService.logPageView({
                name: urlAfterRedirects,
                refUri: this.previousUrl && `${location.origin}${this.previousUrl}`,
                uri: `${location.origin}${urlAfterRedirects}`
            });

            this.segmentService.logPageView(
              this.previousUrl && `${location.origin}${this.previousUrl}`,
              `${location.origin}${urlAfterRedirects}`
            );
        });
    }
}

import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RouterHistoryService } from './router-history.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[keyRouterBack]'
})
export class RouterBackDirective {
    constructor(private router: Router,
                private routerHistoryService: RouterHistoryService) { }

    @Input() keyRouterBack: string = null;

    @HostListener('click') onClick() {
        if (!this.keyRouterBack) {
            this.router.navigateByUrl(this.routerHistoryService.previousUrl);
        } else {
            this.router.navigateByUrl(this.routerHistoryService.getLastEntryOf(this.keyRouterBack) || this.keyRouterBack);
        }
    }
}

import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import { ProductGroupsDataSourceBuilderService } from '../../../../home/product/catalog/services/data-sources/product-groups-data-source-builder.service';
import { ProductDataService } from '../../../../home/product/product-data.service';
import { GroupModalComponent } from '../../../../home/product/setup/group/group-modal/group-modal.component';
import { Group } from '../../../../home/product/setup/group/models/group';
import { ModalService } from '../../../modal/modal.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'key-product-group-select-field',
    templateUrl: './product-group-select-field.component.html',
    styleUrls: ['./product-group-select-field.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ProductGroupSelectFieldComponent),
        multi: true
    }]
})
export class ProductGroupSelectFieldComponent implements ControlValueAccessor, OnInit {
    @Input() id = '';
    @Input() label: string;
    @Input() mandatory: boolean;
    @Input() readOnly: boolean;
    @Input() error: string;
    @Input() noValueMessage: string;
    @Input() canCreateGroups: boolean;
    @Input() groups: Group[];

    productGroups: DataSource;
    isDisabled: boolean;
    value: Group;

    onChange: (_: any) => void = (_: any) => { };
    onTouched: () => void = () => { };

    constructor(private modalService: ModalService,
                private productGroupsDataSourceBuilder: ProductGroupsDataSourceBuilderService) {
        this.value = null;
        this.isDisabled = false;
    }

    ngOnInit(): void {
        if (this.groups) {
            this.productGroups = new DataSource(this.groups);
        } else {
            this.productGroups = this.productGroupsDataSourceBuilder.buildDataSource();
        }
    }

    getValue(): string {
        return this.value != null ? this.value.name || '' : '';
    }

    handleAdd() {
        const groupModal = this.modalService.open(GroupModalComponent, {
            backdrop: 'static',
            initialState: {
                group: new Group()
            },
            keyboard: false
        });

        return (groupModal.content as GroupModalComponent).onClose
            .subscribe((productGroupData: any) => {
                if (productGroupData) {
                    const productGroup = Object.assign(new Group(), {
                        id: productGroupData.id,
                        name: productGroupData.name,
                        vat: productGroupData.vat || 0.25
                    });

                    this.value = productGroup;
                    this.productGroups.pageIndex(0);
                    this.productGroups.reload();
                }
            });
    }

    handleChange(event: any) {
        this.value = event.value || null;

        this.onChange(this.value);
    }

    handleTouch() {
        this.onTouched();
    }

    hasErrors() {
        return this.error != null && this.error !== '';
    }

    hasLabel() {
        return this.label != null && this.label !== '';
    }

    hasValue() {
        if (this.value == null) {
            return false;
        }

        const value = this.value.name;

        return value && value.length;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(value: Group): void {
        this.value = value || null;
    }
}

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from '@keystone-angular/core';
import { pickBy } from 'lodash-es';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CompanyPickerDataService {
    private baseUrl = 'authentication/access';

    constructor(private dataService: DataService) { }

    getCompanyStoreList(params: any): Observable<any> {
        return this.dataService.get(this.baseUrl, {
            params: new HttpParams({
                fromObject: pickBy({
                    storeId: params.storeId,
                    companyId: params.companyId
                })
            })
        });
    }

    getCompanyPickerData(queryString: string, blockingRequest: boolean): Observable<any> {
        return this.dataService.get(`${this.baseUrl}/company-picker${queryString}`, {
            isBlocking: blockingRequest !== false
        });
    }

    getCompanyPickerDataByKey(queryString: string, blockingRequest: boolean): Observable<any> {
        return this.dataService.get(`${this.baseUrl}/company-picker-by-key${queryString}`, {
            isBlocking: blockingRequest !== false
        });
    }

    setCurrentStock(stockId: number): Observable<{ token: string }> {
        return this.dataService.post(`${this.baseUrl}/stock`, {
            stockId
        });
    }

    setCurrentCompanyStore(companyId: number, storeId: number): Observable<{ permissions: any, token: string }> {
        return this.dataService.post(this.baseUrl, {
            companyId,
            storeId
        });
    }
}

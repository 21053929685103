import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
    @Input() current: number;
    @Input() total: number;

    get currentPercentage(): number {
        return this.current * 100 / this.total;
    }
}

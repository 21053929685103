<div dropdown class="dropdown-button-container"
     [insideClick]="true"
     (onShown)="setShownStatus(true)"
     (onHidden)="setShownStatus(false)">
  <button dropdownToggle
          class="btn btn-default dropdown-button"
          [ngClass]="{'active': isShown}">
    <span class="dropdown-button-caption"><ng-content select="button-caption"></ng-content></span>
    <i class="fa" [ngClass]="{'fa-caret-up': isShown, 'fa-caret-down': !isShown}"></i>
  </button>
  <div *dropdownMenu class="dropdown-menu dropdown-filter">
    <ng-content select="dropdown-content"></ng-content>
  </div>
</div>

<div class="modal-header">
  <h4 class="modal-title">{{ getTitle() }}</h4>
  <button type="button" class="btn btn-close" id="closeButton" aria-label="Close" (click)="closeModal(false)"></button>
</div>
<div class="modal-body">
  <div *ngIf="canPrint()" class="printer-selector">
    <dx-button-group [items]="((canPrintWithRfidPrinter() && canPrintWithBarcodePrinter()) ? [ 'rfid', 'barcode' ] : (canPrintWithRfidPrinter() ? [ 'rfid' ] : (canPrintWithBarcodePrinter() ? [ 'barcode' ] : [])))">
      <button class="btn btn-default" *dxTemplate="let selectedPrinterType of 'item'"
              [ngClass]="{ 'active': selectedPrinter === selectedPrinterType }"
              [disabled]="processingPrint" (click)="changePrinter(selectedPrinterType)">
        {{ (selectedPrinterType === 'rfid' ? 'App_RFID' : 'App_Barcode') | translate }}
      </button>
    </dx-button-group>
  </div>
  <div *ngIf="selectedPrinter == 'rfid'">
    <key-note type="danger"><i class="fa fa-exclamation-triangle"></i><strong>&nbsp;{{ 'App_DeprecatedRfidLabels' | translate }}</strong></key-note>
  </div>
  <ng-container *ngIf="displayNotification$ | async as displayNotification">
    <app-print-notification [show]="displayNotification" [singleOrderLine]="hasSingleSelectedOrderLine()"></app-print-notification>
  </ng-container>
  <app-serials-generation-progress *ngIf="generatingSerials"
                                   [orderId]="serialsGenerationData.orderId"
                                   [productId]="serialsGenerationData.productId"
                                   [labelSelectorStatus]="serialsGenerationData.labelSelectorStatus"
                                   [startTimestamp]="serialsGenerationData.startTimestamp"
                                   (generationCompleted)="onGenerationCompleted($event)">
  </app-serials-generation-progress>
  <app-product-labels-selector *ngIf="isProductModal() && !generatingSerials"
                               [inventory]="toPrint"
                               [usingRfid]="isRfidPrinterSelected()"
                               [hideSizesWithoutGtinOrSku]="hideSizesWithoutGtinOrSku"
                               (onChange)="updateLabelsToPrint($event)"
                               (onLoad)="updateLabelsToPrint($event)"
                               (onSet)="updateLabelsToPrint($event)">
  </app-product-labels-selector>
  <app-delivery-labels-selector *ngIf="isPurchaseOrderModal() && !generatingSerials"
                                [orderType]="subType"
                                [orderLines]="toPrint"
                                [printReceivedItemsOnly]="printReceivedItemsOnly"
                                [usingRfid]="isRfidPrinterSelected()"
                                [hideSizesWithoutGtinOrSku]="hideSizesWithoutGtinOrSku"
                                (onChange)="updateLabelsToPrint($event)"
                                (onLoad)="updateLabelsToPrint($event)"
                                (onSet)="updateLabelsToPrint($event)">
  </app-delivery-labels-selector>
  <div class="print-summary">
    <div><span class="fw-bold">{{ 'App_NumberOfLabels' | translate }}</span>: {{ totalLabelsToPrint$ | async }}</div>
    <ng-container *ngIf="printerSummary$ | async as printerSummary">
      <div><span class="fw-bold">{{ 'App_PrintingTo' | translate }}</span>: {{ printerSummary.printerName }}</div>
      <div>
        <span class="fw-bold">{{ 'App_LastKnownStatus' | translate }}</span>:
        <span class="printer-status-summary">
          <span class="printer-status-summary-item">
            <span class="printer-status-summary-item-title">{{ 'App_PrintAgent' | translate }}</span>
            <span *ngIf="printerSummary.printAgentStatus === 'A'" class="badge bg-success">{{ 'App_Online' | translate }}</span>
            <span *ngIf="printerSummary.printAgentStatus === 'D'" class="badge bg-default">{{ 'App_Offline' | translate }}</span>
          </span>
          <span class="printer-status-summary-item">
            <span class="printer-status-summary-item-title">{{ 'App_Printer' | translate }}</span>
            <span *ngIf="printerSummary.printAgentStatus === 'A' && printerSummary.printerStatus === 'A'" class="badge bg-success">{{ 'App_Online' | translate }}</span>
            <span *ngIf="printerSummary.printAgentStatus === 'A' && printerSummary.printerStatus === 'D'" class="badge bg-default">{{ 'App_Offline' | translate }}</span>
            <span *ngIf="printerSummary.printAgentStatus === 'D'" class="badge bg-default">{{ 'App_Unknown' | translate }}</span>
          </span>
        </span>
      </div>
    </ng-container>
  </div>
</div>
  <div class="modal-footer">
    <div *ngIf="!isBarcodePrinterSetUp() || !isRfidPrinterSetUp()" class="printer-notes">
      <span *ngIf="!isBarcodePrinterSetUp()">{{ 'App_SetBarcodePrinterToPrintBarcodeLabels' | translate }}</span>
      <span *ngIf="!isRfidPrinterSetUp()">{{ 'App_SetRfidPrinterToPrintRfidLabels' | translate }}</span>
    </div>
    <button class="btn btn-primary" type="button" id="printButton"
            [disabled]="!canPrint() || processingPrint || getTotalLabelsToPrint() <= 0"
            (click)="print()">
      {{ 'App_Print' | translate }}
    </button>
    <button class="btn btn-default" type="button" id="cancelButton" (click)="closeModal(false)">{{ 'App_Cancel' | translate }}</button>
  </div>


<div class="form-group" [ngClass]="{'invalid-field': hasErrors()}">
    <label *ngIf="hasLabel()" [for]="id">{{label}} <span *ngIf="mandatory">*</span></label>
    <div *ngIf="readOnly">
        <em *ngIf="!hasValue()" class="text-muted">{{noValueMessage || '-'}}</em>
        <span *ngIf="hasValue()"
              [attr.title]="valueTooltip">{{value}}</span>
    </div>
    <input type="text" [id]="id" [name]="id" class="form-control"
           *ngIf="!readOnly"
           placeholder="{{ placeholder || '' }}"
           [disabled]="isDisabled"
           [attr.maxlength]="maxLength"
           [attr.title]="valueTooltip"
           [value]="value"
           (change)="handleChange($event)"
           (blur)="handleTouch()" />
    <div *ngIf="hasErrors()" class="validation-error-msg">{{error}}</div>
    <div class="form-footer">
        <ng-content select="field-footer"></ng-content>
    </div>
</div>

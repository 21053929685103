import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { ContextService } from './context.service';

@Directive({
  selector: '[appIfCompanyAdmin]'
})
export class IfCompanyAdminDirective {
    constructor(contextService: ContextService, templateRef: TemplateRef<any>, viewContainer: ViewContainerRef) {
      if (contextService.user?.isCompanyAdmin) {
            viewContainer.createEmbeddedView(templateRef);
        } else {
            viewContainer.clear();
        }
    }
}

import {
    Directive, Input, OnInit, OnDestroy, HostBinding, HostListener,
    OnChanges, SimpleChanges, ElementRef, Output, EventEmitter
} from '@angular/core';
import { DragAndDropService } from './drag-and-drop.service';
import { DragItem } from './core/drag-item';
import { DroppableDirective } from './droppable.directive';

interface DragEventData {
    sourceIndex: number;
    targetIndex: number;
}

interface DragHandle {
    element: Element;
    disableFn: (disable: boolean) => void;
}

interface DragHandlers {
    dragGhost: Element;
    dragStart: () => boolean;
    dragEnd: () => DragEventData;
    dragEmit: (data: DragEventData) => void;
}

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[keyDraggable]'
})
export class DraggableDirective implements OnInit, OnDestroy, OnChanges {
    constructor(private dndService: DragAndDropService,
                private parent: DroppableDirective,
                private draggableElement: ElementRef) { }

    @Input() dragDisabled: boolean;
    @Input() dragGroup: string;

    @Output() dragFinished: EventEmitter<DragEventData> = new EventEmitter();

    @HostBinding('attr.draggable')
    draggable: boolean;

    private dragItem: DragItem;
    private handle: DragHandle;

    ngOnInit() {
        if (this.parent != null) {
            this.draggable = !this.dragDisabled && this.handle == null;

            const draggableEl = this.draggableElement.nativeElement as Element;
            const parentEl = this.parent.getElement();

            this.dragItem = this.dndService.addDraggable(this.dragGroup, parentEl, draggableEl);
        }
    }

    ngOnDestroy() {
        this.dndService.deleteDraggable(this.dragItem);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dragDisabled != null) {
            this.draggable = !changes.dragDisabled.currentValue && this.handle == null;

            if (this.handle != null) {
                this.handle.disableFn(changes.dragDisabled.currentValue);
            }
        }
    }

    @HostListener('dragstart', ['$event'])
    onDragStart(event: any) {
        const dragSource = event.srcElement;
        const dragStarted = this.dragStart(dragSource, this.dragItem);

        if (!dragStarted) {
            return false;
        }

        event.dataTransfer.effectAllowed = 'move';

        event.stopPropagation();
    }

    @HostListener('dragend', ['$event'])
    onDragEnd(event: any) {
        const dragSource = event.srcElement;
        const dragEventData = this.dragEnd(dragSource, this.dragItem);

        if (dragEventData != null) {
            this.dragEmit(dragEventData);
        }

        event.stopPropagation();
    }

    registerHandle(handle: Element, handleDisableFn: (disable: boolean) => void): DragHandlers {
        const dragSource = this.draggableElement.nativeElement;

        this.draggable = false;
        this.handle = {
            element: handle,
            disableFn: handleDisableFn
        };

        handleDisableFn(this.dragDisabled);

        return {
            dragGhost: dragSource,
            dragStart: this.dragStart.bind(this, dragSource, this.dragItem),
            dragEnd: this.dragEnd.bind(this, dragSource, this.dragItem),
            dragEmit: this.dragEmit.bind(this)
        };
    }

    private dragStart(dragSource: Element, dragItem: DragItem): boolean {
        if (!dragItem) {
            return false;
        }

        const dragStarted = this.dndService.startDrag(dragItem);

        if (!dragStarted) {
            return false;
        }

        dragSource.classList.add('dragging');

        return true;
    }

    private dragEnd(dragSource: Element, dragItem: DragItem): DragEventData {
        if (!dragItem) {
            return null;
        }

        dragSource.classList.remove('dragging');

        const activeDrag = this.dndService.getActiveDrag(dragItem.group);
        const dragEventData: DragEventData = {
            sourceIndex: activeDrag.sourceIndex,
            targetIndex: activeDrag.targetIndex
        };

        const dragFinished = this.dndService.finishDrag(dragItem);

        return dragFinished ? dragEventData : null;
    }

    private dragEmit(data: DragEventData) {
        this.dragFinished.emit(data);
    }
}

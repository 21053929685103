import { EventEmitter, Inject, Injectable, Injector } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { SpinnerModalComponent } from './spinner-modal/spinner-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  modalRef: BsModalRef;

  private pBsModalService: BsModalService;

  get bsModalService(): BsModalService {
    if (this.pBsModalService == null) {
      this.pBsModalService = this.injector.get(BsModalService);
    }

    return this.pBsModalService;
  }

  constructor(@Inject(Injector) private injector: Injector) { }

  close(): void {
      if (this.modalRef) {
          this.modalRef.hide();
      }
  }

  open(modalComponent: any, config?: ModalOptions): BsModalRef {
      if (!!config.backdrop) {
          const existingBackdrops = document.getElementsByClassName('modal-backdrop');
          if (existingBackdrops.length > 0) {
              this.modalRef = this.bsModalService.show(modalComponent, config);

              const newBackdrop = existingBackdrops[0].cloneNode() as Element;
              newBackdrop.setAttribute('style', `z-index: ${1049 + existingBackdrops.length * 2}`);

              document.body.appendChild(newBackdrop);

              const existingModals = document.getElementsByClassName('modal');
              const newModal = existingModals[existingModals.length - 1];
              newModal.setAttribute('style', `${newModal.getAttribute('style')} z-index: ${1050 + (existingBackdrops.length - 1) * 2}`);

              const originalHide = this.modalRef.hide;
              this.modalRef.hide = () => {
                  originalHide();
                  newBackdrop.remove();
              };
          } else {
              this.modalRef = this.bsModalService.show(modalComponent, config);
          }
      } else {
          this.modalRef = this.bsModalService.show(modalComponent, config);
      }

      return this.modalRef;
  }

  // TODO: Remove this once the migration is over
  confirm(config?: ModalOptions): EventEmitter<boolean> {
      this.modalRef = this.open(ConfirmationModalComponent, config);
      return (this.modalRef.content as ConfirmationModalComponent).closed;
  }

  // TODO: Remove this once the migration is over
  confirmUnsavedChanges(): EventEmitter<boolean> {
      return this.confirm({
          backdrop: 'static',
          initialState: {
              message: 'RGo_LeavePageConfirmation',
              cancelClass: 'btn-default',
              cancelText: 'App_Cancel',
              okClass: 'btn-primary',
              okText: 'RGo_LeavePageConfirmation_IgnoreChanges',
              title: 'RGo_LeavePageConfirmation_Title'
          }
      });
  }

  // TODO: Remove this once the migration is over
  spin(config?: ModalOptions) {
      this.modalRef = this.open(SpinnerModalComponent, config);
  }
}

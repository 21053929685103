<ng-container *ngIf="groupForm">
  <div class="modal-header">
    <h4 class="modal-title">{{ (!group?.id ? 'PGr_AddProductGroup' : 'PGr_EditProductGroup') | translate }}</h4>
    <button type="button" class="btn btn-close" aria-label="Close" id="closeButton" (click)="close()"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="groupForm" class="form" role="form" name="productGroupForm" novalidate autocomplete="off" id="submitProductGroupForm">
      <div class="form-group">
        <label for="productGroupName">{{ 'PGr_Name' | translate }}<span class="form-mandatory-notice">*</span></label>
        <input type="text" class="form-control" id="productGroupName"
               name="productGroupName" formControlName="name"
               [ngClass]="{'input-validation' : showValidation}"
               maxlength="50"
               autofocus />
        <div *ngIf="groupForm.get('name').invalid && showValidation" class="validation-error-msg">
          <span *ngIf="groupForm.get('name').hasError('required')">{{ 'PGr_ProductGroupDlg_ProductGroupName_Required' | translate }}</span>
        </div>
      </div>
      <div class="form-group" *ngIf="!!group?.parentId || !group?.id">
        <label for="parentProductGroupName">{{ 'PGr_ParentProductGroup' | translate }}</label>
        <select type="text" class="form-select" id="parentProductGroupName" formControlName="parentId">
          <option *ngFor="let parentGroup of groups" [disabled]="group != null && parentGroup.id === group?.id" [ngValue]="parentGroup.id">
            {{ parentGroup.name || 'PGr_NONAME' | translate }}
          </option>
        </select>
      </div>
      <div *ngIf="!groupForm.get('parentId').value" class="product-group-vat-section">
        <!-- LEGACY CODE FOR NON-REGIONAL STORES -->
        <ng-container *ngIf="!regionId">
          <key-field id="alternativeVAT" formControlName="alternativeVAT" [inlineLabel]="true" class="alternative-vat-field">
            <key-field-label>{{ 'PGr_ProductGroupDlg_AlternativeVAT' | translate }}</key-field-label>
            <key-switch keyFieldControl captionOn="{{ 'PGr_ON' | translate }}" captionOff="{{ 'PGr_OFF' | translate }}"></key-switch>
          </key-field>
          <div class="form-group">
            <div class="product-group-vat-frame" *ngIf="groupForm.get('alternativeVAT').value">
              <div class="product-group-vat-frame-header">{{ 'PGr_ProductGroupDlg_VatHigh_Header' | translate }}</div>
              <div class="product-group-vat-frame-body">
                <p>{{ 'PGr_ProductGroupDlg_VatHigh_Description' | translate }}</p>
                <div class="row">
                  <div class="col-lg-6">
                    <label for="highestVAT"> {{ 'PGr_VatRate' | translate }}</label>
                    <select type="text"
                            class="form-select"
                            id="highestVAT"
                            formControlName="vatHighId">
                      <option *ngFor="let vat of vats" [ngValue]="vat.id">
                        {{ vat.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="product-group-vat-frame" *ngIf="groupForm.get('alternativeVAT').value">
              <div class="product-group-vat-frame-header">{{ 'PGr_ProductGroupDlg_VatLow_Header' | translate }}</div>
              <div class="product-group-vat-frame-body">
                <p>{{ 'PGr_ProductGroupDlg_VatLow_Description' | translate }}</p>
                <div class="row">
                  <div class="col-lg-6">
                    <label for="lowestVAT">{{ 'PGr_VatRate' | translate }}</label>
                    <select type="text"
                            class="form-select"
                            id="lowestVAT"
                            formControlName="vatLowId">
                      <option *ngFor="let vat of vats" [ngValue]="vat.id">
                        {{ vat.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- CODE FOR REGIONAL STORES -->
        <ng-container *ngIf="region">
          <div class="form-group">
            <div class="toggle-custom">
              <label>{{ 'PGr_VAT' | translate }}</label>
            </div>
          </div>

          <key-region-vat-field description="{{ 'PGr_ProductGroupDlg_VatHigh_Description' | translate }}"
                                id="high"
                                [isLocked]="isRegionVATLocked(groupForm.get('vatHigh').value)"
                                [lockable]="true"
                                [lockTitle]="getRegionVATLockTitle(groupForm.get('vatHigh').value)"
                                (toggleLock)="onToggleLock(1, $event)"
                                formControlName="vatHigh"
                                title="{{ 'PGr_ProductGroupDlg_VatHigh_Header' | translate }}"
                                [vats]="vats"></key-region-vat-field>
          <!-- Hidden until Cornerstone supports it
          <key-region-vat-field description="{{ 'PGr_ProductGroupDlg_VatLow_Description' | translate }}"
                            id="low"
                            [isLocked]="isRegionVATLocked(groupForm.get('vatLow').value)"
                            [lockable]="true"
                            [lockTitle]="getRegionVATLockTitle(groupForm.get('vatLow').value)"
                            (toggleLock)="onToggleLock(2, $event)"
                            formControlName="vatLow"
                            title="{{ 'PGr_ProductGroupDlg_VatLow_Header' | translate }}"
                            [vats]="vats"></key-region-vat-field>-->
        </ng-container>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button id="addProductGroupBtn" class="btn btn-primary" type="button" (click)="ok()"
            *permission="{domain: 'product', rule: 'productGroup', action: !group?.id ? 'canAdd' : 'canEdit'}"
            [disabled]="groupForm.pristine ? 'disabled' : ''">
      {{ (!group?.id ? 'PGr_ProductGroupDlg_Submit_Add' : 'PGr_ProductGroupDlg_Submit_Update') | translate }}
    </button>
    <button class="btn btn-default" type="button" (click)="close()">{{ 'App_Cancel' | translate }}</button>
  </div>
</ng-container>

import { ErrorHandler, Injector } from '@angular/core';
import { ApplicationInsightsService } from './framework/logging/application-insights.service';

export class ErrorHandlerImplementation implements ErrorHandler {
  constructor(private injector: Injector) { }

  handleError(error: Error): void {
    const applicationInsightsService = this.injector.get(ApplicationInsightsService);
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      if (applicationInsightsService != null) {
        applicationInsightsService.logEvent({
          name: 'AppReload'
        });
      }
      window.location.reload();
    }

    if (applicationInsightsService != null) {
      applicationInsightsService.logException({
        exception: error
      });
    }

    console.error(error);
  }
}

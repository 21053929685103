import { DefaultRegionVAT } from './default-region-vat';
import { RegionVAT } from './region-vat';

export class Region {
  countryId: number;
  culture: string;
  currencyCode: string;
  defaultAggregators: string;
  defaultReceiptService: string;
  defaultRegionVATs: DefaultRegionVAT[];
  defaultSigningService: string;
  id: number;
  name: string;
  regionVATs: RegionVAT[];
  timeZoneInfo: string;

  constructor(data: any = null) {
    this.countryId = data && data.countryId;
    this.culture = data && data.culture;
    this.currencyCode = data && data.currencyCode;
    this.defaultAggregators = data && data.defaultAggregators;
    this.defaultReceiptService = data && data.defaultReceiptService;
    this.defaultRegionVATs =
      data && data.defaultRegionVATs instanceof Array
      && (data.defaultRegionVATs as Array<any>).map(drv => new DefaultRegionVAT(drv));
    this.defaultSigningService = data && data.defaultSigningService;
    this.id = data && data.id;
    this.name = data && data.name;
    this.regionVATs =
      data && data.regionVATs instanceof Array
      && (data.regionVATs as Array<any>).map(rv => new RegionVAT(rv))
    this.timeZoneInfo = data && data.timeZoneInfo;
  }
}

import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'key-tag-field',
    templateUrl: './tag-field.component.html',
    styleUrls: ['./tag-field.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TagFieldComponent),
        multi: true
    }]
})
export class TagFieldComponent implements ControlValueAccessor {
    @Input() canCreateTags: boolean;
    @Input() id = '';
    @Input() label: string;
    @Input() mandatory: boolean;
    @Input() multiSelect: boolean;
    @Input() readOnly: boolean;
    @Input() options: DataSource;
    @Input() error: string;

    value: string[];
    isDisabled: boolean;

    onChange: (_: any) => void = (_: any) => { };
    onTouched: () => void = () => { };

    constructor() {
        this.value = [];
        this.isDisabled = false;
        this.canCreateTags = true;
        this.multiSelect = true;
    }

    handleAdd(event: any) {
        if (event != null && event.customItem != null && event.customItem !== '') {
            this.value = this.value.concat(event.customItem);
        }
    }

    handleChange(event: any) {
        this.value = event.value || [];

        if (!this.multiSelect && this.value.length > 1) {
            this.value = [this.value[this.value.length - 1]];
        }

        this.handleTouch();
        this.onChange(this.multiSelect ? this.value : this.value[0]);
    }

    handleTouch() {
        this.onTouched();
    }

    hasErrors() {
        return this.error != null && this.error !== '';
    }

    hasLabel() {
        return this.label != null && this.label !== '';
    }

    hasTags() {
        return this.value != null && this.value.length > 0;
    }

    writeValue(value: string | string[]): void {
        if (typeof value === 'string') {
            this.value = [value as string];
        } else {
            this.value = (value as string[]) || [];
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}

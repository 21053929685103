import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { MsalBroadcastService, MsalInterceptor, MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { ServerSettingsManagerService } from '@keystone-angular/core';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';


export class NoopInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req);
  }
}

export function MSALInterceptorFactory(msalInterceptorConfiguration: MsalInterceptorConfiguration,
                                       authService: MsalService,
                                       location: Location,
                                       serverSettingsManagerService: ServerSettingsManagerService,
                                       msalBroadcastService: MsalBroadcastService): HttpInterceptor {
  if (serverSettingsManagerService.getEnableB2C()) {
    return new MsalInterceptor(msalInterceptorConfiguration, authService, location, msalBroadcastService, document);
  } else {
    return new NoopInterceptor();
  }
}

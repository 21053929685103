import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    static readonly PermissionKey = 'KS_Permissions';

    setPermissions(permissions: any) {
        sessionStorage.setItem(PermissionService.PermissionKey, JSON.stringify(permissions ?? {}));
    }

    hasPermission(domain: string, rule: string, action: string): boolean {
        const multiPermissionRegExp = /^.*\|.*$/g;

        if (domain.match(multiPermissionRegExp)) {
            const domains = domain.split('|');
            const rules = rule.split('|');
            const actions = action.split('|');

            if (domains.length !== rules.length || domains.length !== actions.length) {
                return false;
            }

            let actionPermission = false;

            for (let index = 0; index < domains.length; index++) {
                const setDomain = domains[index];
                const setRule = rules[index];
                const setAction = actions[index];

                actionPermission = actionPermission || this.hasAllPermissions(setDomain, setRule, setAction);
            }

            return actionPermission;
        } else {
            return this.hasAllPermissions(domain, rule, action);
        }
    }

    private hasAllPermissions(domain: string, rule: string, action: string): boolean {
        const multiPermissionRegExp = /^.*\|.*$/g;

        if (domain.match(multiPermissionRegExp)) {
            const domains = domain.split('|');
            const rules = rule.split('|');
            const actions = action.split('|');

            if (domains.length !== rules.length || domains.length !== actions.length) {
                return false;
            }

            let actionPermission = true;

            for (let index = 0; index < domains.length; index++) {
                const setDomain = domains[index];
                const setRule = rules[index];
                const setAction = actions[index];

                actionPermission = actionPermission && this.isDomainRuleActionDefined(setDomain, setRule, setAction);
            }

            return actionPermission;
        } else {
            return this.isDomainRuleActionDefined(domain, rule, action);
        }
    }

    private isDomainRuleActionDefined(domain: string, rule: string, action: string): boolean {
        const permissionSet = JSON.parse(sessionStorage.getItem(PermissionService.PermissionKey));

        if (!permissionSet || !permissionSet[domain]) {
            return false;
        }

        if (!permissionSet[domain][rule]) {
            return false;
        }

        const ruleSet = permissionSet[domain][rule];
        const actionPermission = ruleSet[action];

        return (actionPermission === true);
    }
}

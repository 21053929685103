<div class="fsearch-container">
  <div class="fsearch-components">
    <app-filter-button *ngIf="filters != null" class="fsearch-filter"
                       [filters]="filters"
                       [value]="value?.filters"
                       (onChange)="onFilterChanged($event)">
    </app-filter-button>
    <app-search-box id="search" class="fsearch-search-box"
                    [placeholder]="placeholder"
                    [value]="searchText"
                    [clearable]="true"
                    (onSearch)="onSearch($event)">
    </app-search-box>
  </div>
  <div class="fsearch-search-text">{{formattedFilter}}</div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { uniqBy } from 'lodash-es';

@Pipe({
    name: 'uniqBy',
    pure: false
})
export class UniqByPipe implements PipeTransform {
    transform(value: any, by: any) {
        return uniqBy(value, by);
    }
}

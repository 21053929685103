<div class="key-editable-field" [ngClass]="{ 'inline-label': inlineLabel }">
  <div *ngIf="showFieldLabel()" class="key-editable-field-label-container">
    <div *ngIf="hasLabel()" class="key-editable-field-label">
      <ng-container *ngTemplateOutlet="getLabel()"></ng-container>
    </div>
    <div *ngIf="mandatory" class="key-editable-field-mandatory-notice">*</div>
  </div>
  <div class="key-editable-field-element">
    <div class="key-editable-field-control">
      <ng-content select="[keyFieldControl]"></ng-content>
    </div>
    <div class="key-editable-field-actions" *ngIf="hasActions()">
      <key-button *ngIf="editable !== false && hasAction('add') && !readOnly"
                  type="default"
                  [disabled]="isDisabled"
                  (click)="showEditor('add')">
        <i class="fa fa-plus"></i>
      </key-button>
      <key-button *ngIf="editable !== false && hasAction('delete') && !readOnly"
                  type="danger"
                  [disabled]="isDisabled || value == null"
                  (click)="showEditor('delete')">
        <i class="fa fa-trash"></i>
      </key-button>
    </div>
    <div *ngIf="showFieldError()" class="key-editable-field-error-message">
      <ng-container *ngTemplateOutlet="getError()"></ng-container>
    </div>
    <div *ngIf="showFieldFooter()" class="key-editable-field-footer">
      <ng-container *ngTemplateOutlet="getFooter()"></ng-container>
    </div>
  </div>
</div>

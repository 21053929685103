import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './authentication/auth.guard';
import { UnsupportedBrowserGuard } from './unsupported-browser/unsupported-browser.guard';

const routes: Routes = [
    {
        canActivate: [UnsupportedBrowserGuard],
        canActivateChild: [AuthGuard],
        path: '',
        loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
    },
    {
        canActivate: [UnsupportedBrowserGuard],
        path: 'auth',
        loadChildren: () => import('./authentication/authentication.module').then(mod => mod.AuthenticationModule)
    },
    {
        path: 'unsupported-browser',
        loadChildren: () => import('./unsupported-browser/unsupported-browser.module').then(mod => mod.UnsupportedBrowserModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }

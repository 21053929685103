<div *ngIf="regionVAT" class="form-group">
  <div style="display: flex; flex-flow: row nowrap; align-items: flex-start;">
    <button *ngIf="lockable"
            [disabled]="isDisabled ? 'disabled' : ''"
            class="btn product-group-vat-frame-lock"
            type="button"
            id="{{ id + 'Lock' }}"
            title="{{ lockTitle }}"
            (click)="onToggleLock()"
            tabindex="-1">
      <span class="fa" tabindex="-1"
            [ngClass]="{'fa-lock-open': !isLocked, 'fa-lock': isLocked}"></span>
    </button>

    <div class="product-group-vat-frame">
      <div class="product-group-vat-frame-header">
        {{ title }}
      </div>
      <div class="product-group-vat-frame-body">
        <p>{{ description | translate }}</p>
        <div class="row">
          <div class="col-lg-3">
            <label for="{{ id + 'VATId' }}">
              {{ 'PGr_VatRate' | translate }}
            </label>
            <select type="text"
                    class="form-select"
                    id="{{ id + 'VATId' }}"
                    [formControl]="vatIdFormControl">
              <option *ngFor="let vat of vats" [ngValue]="vat.id">
                {{ vat.name }}
              </option>
            </select>
          </div>
          <div class="col-lg-9">
            <app-minimum-taxable-amount-field [id]="id"
                                              [formControl]="minimumTaxableAmountFormControl">
            </app-minimum-taxable-amount-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

export enum CommunicationType {
    COM = 'COM',
    IP = 'IP'
}

export enum Model {
    TSC = 'TSC',
    SATO = 'SATO',
    zt410 = 'Zebra zt410',
    rz400 = 'Zebra rz400',
    rz600 = 'Zebra rz600'
}

export class Printer {
    comPort: number;
    comPortSpeed: number;
    communicationType: CommunicationType;
    id: string;
    ipAddress: string;
    isRfid: boolean;
    macAddress: string;
    model: Model;
    name: string;
    portNumber: number;
    printAgentId: string;
    status: string;
    templateOverrideId: string;

    public constructor(init?: Partial<Printer>) {
        Object.assign(this, init);
    }
}

<div *ngIf="hasSingleSelectedOrderLine()">
  <div class="table-responsive label-selector-table">
    <table class="qty-table">
      <tbody *ngFor="let sizeSystem of orderLines[0].sizeSystems">
        <tr>
          <td [attr.rowspan]="usingRfid ? 4 : 3" class="print-size-range-label-cell">{{ sizeSystem.name }}</td>
          <td>&nbsp;</td>
          <td class="print-size-label-header-cell" *ngFor="let stockMovement of sizeSystem.stockMovements">
            <ng-container *ngIf="hasGtinOrSku(orderLines[0], stockMovement)">{{ stockMovement.sizeLabel }}</ng-container>
          </td>
        </tr>
        <tr>
          <td class="print-selector-header-cell">{{ 'App_LabelType' | translate }}</td>
          <td class="print-selector-cell" *ngFor="let stockMovement of sizeSystem.stockMovements">
            <dx-number-box id="sizeLabel" *ngIf="hasGtinOrSku(orderLines[0], stockMovement)"
                           class="print-selector"
                           [min]="0"
                           [max]="usingRfid ? getTotalItems(stockMovement, orderLines[0]) : undefined"
                           [disabled]="getTotalItems(stockMovement, orderLines[0]) === 0"
                           [showSpinButtons]="true"
                           [value]="getTotalLabelsToPrint(stockMovement, orderLines[0])"
                           (onValueChanged)="updateTotalLabelsToPrint(stockMovement, orderLines[0], $event)">
            </dx-number-box>
          </td>
        </tr>
        <tr>
          <td class="print-summary-header-cell">
            <ng-container *ngIf="getOrderType() === 'twostep'">{{ 'App_Planned' | translate }}</ng-container>
            <ng-container *ngIf="getOrderType() === 'onestep'">{{ 'App_Received' | translate }}</ng-container>
          </td>
          <td class="print-summary-cell" *ngFor="let stockMovement of sizeSystem.stockMovements">
            <span [ngClass]="{ 'delivery-details-received-qty': allItemsHaveBeenReceived(stockMovement),
                               'delivery-details-receiving-qty': !allItemsHaveBeenReceived(stockMovement) }">
              {{ getTotalItems(stockMovement, orderLines[0]) || '&nbsp;' }}
            </span>
          </td>
        </tr>
        <tr *ngIf="usingRfid">
          <td class="print-summary-header-cell">{{ 'App_Unprinted' | translate }}</td>
          <td class="print-summary-cell" *ngFor="let stockMovement of sizeSystem.stockMovements">
            {{ getTotalUnprintedLabels(stockMovement, orderLines[0]) || '&nbsp;' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Provider } from '@angular/core';
import { TokenService, TranslatePipe } from '@keystone-angular/core';
import { ModalService } from '../../../framework/modal/modal.service';
import { PrintAgentManager } from '../../../home/admin/print-agent/print-agent-manager.service';
import { UserManager } from '../../../home/admin/user/user-manager.service';
import { SettingsDataService } from '../../../shared/settings-old/services/settings-data.service';
import { AgentlessPrintingService } from '../agentless-printing.service';
import { InitializablePrintService } from '../initializable-print.service';
import { PrintService } from '../print.service';

function PrintServiceFactory(printAgentManager: PrintAgentManager,
                             settingsDataService: SettingsDataService,
                             userManager: UserManager,
                             agentlessPrintingService: AgentlessPrintingService,
                             httpClient: HttpClient,
                             modalService: ModalService,
                             tokenService: TokenService,
                             translatePipe: TranslatePipe): PrintService {
  return new InitializablePrintService(printAgentManager, settingsDataService, userManager, agentlessPrintingService,
    httpClient, modalService, tokenService, translatePipe);
}

export const PrintServiceProvider: Provider = {
  provide: PrintService,
  useFactory: PrintServiceFactory,
  deps: [PrintAgentManager, SettingsDataService, UserManager, AgentlessPrintingService, HttpClient,
    ModalService, TokenService, TranslatePipe]
}

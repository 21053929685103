import { Injectable } from '@angular/core';
import { DataService } from '@keystone-angular/core';
import { Observable } from 'rxjs';
import { Printer } from './models/printer';
import { PrinterTemplate } from './models/printer-template';

// TODO: Remove this by unifying backend models
export interface PrinterByStore {
    storeName: string;
    printerId: string;
    printerName: string;
    isRfid: boolean;
    isOnline: boolean;
    agentStatus: string;
    printerStatus: string;
}

@Injectable({
  providedIn: 'root'
})
export class PrinterDataService {
    private printerUrl = 'v2/device/printer';

    constructor(private dataService: DataService) { }

    addPrinter(printer: Printer): Observable<string> {
        const url = this.printerUrl;

        return this.dataService.post<string>(url, printer, { observe: 'body', responseType: 'text' });
    }

    deletePrinter(printer: Printer): Observable<boolean> {
        const url = `${this.printerUrl}/${printer.id}/${printer.printAgentId}`;

        return this.dataService.delete<boolean>(url);
    }

    getPrinters(): Observable<Printer[]> {
        const url = this.printerUrl;

        return this.dataService.get<Printer[]>(url);
    }

    getPrinter(printerId: string): Observable<Printer> {
        const url = `${this.printerUrl}/${printerId}`;

        return this.dataService.get<Printer>(url);
    }

    getPrintersByStore(): Observable<PrinterByStore[]> {
        const url = `${this.printerUrl}/by/store`;

        return this.dataService.get(url);
    }

    getPrintersTemplates(): Observable<PrinterTemplate[]> {
        const url = `${this.printerUrl}/templates`;

        return this.dataService.get<PrinterTemplate[]>(url);
    }

    getPrinterWithOnlinePath(printerId: string): Observable<Printer> {
        const url = `${this.printerUrl}/online/path/${printerId}`;

        return this.dataService.get<Printer>(url);
    }

    updatePrinter(printer: Printer): Observable<Printer> {
        const url = `${this.printerUrl}/${printer.id}`;

        return this.dataService.post<Printer>(url, printer);
    }
}

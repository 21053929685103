import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[appUnderDevelopment]'
})
export class UnderDevelopmentDirective {

  constructor(templateRef: TemplateRef<any>, viewContainer: ViewContainerRef) {
    if (environment.production) {
      viewContainer.clear();
    } else {
      viewContainer.createEmbeddedView(templateRef);
    }
  }
}

import { BrowserCacheLocation, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../../environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.b2c.clientId,
      authority: `https://${environment.b2c.baseAuthority}/${environment.b2c.tenantId}/${environment.b2c.policies.signIn}`,
      redirectUri: location.origin,
      postLogoutRedirectUri: location.origin + '/auth/logged-out',
      navigateToLoginRequestUrl: false,
      knownAuthorities: [`${environment.b2c.baseAuthority}/${environment.b2c.tenantId}`]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      iframeHashTimeout: 15000,
      loggerOptions: {
        loggerCallback,
        logLevel: environment.production ? LogLevel.Error : LogLevel.Verbose,
        piiLoggingEnabled: false
      }
    }
  });
}

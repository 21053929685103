<dx-file-uploader [accept]="accept?.join(', ')"
                  [disabled]="isDisabled"
                  labelText=""
                  name="file"
                  (onUploaded)="onFileUploaded($event)"
                  (onUploadError)="onFileUploadError()"
                  (onUploadStarted)="onFileUploadStarted()"
                  [selectButtonText]="selectButtonText"
                  [uploadHeaders]="{ Authorization: token }"
                  uploadMode="instantly"
                  [uploadUrl]="uploadUrl"
                  [uploadedMessage]="'App_Uploaded' | translate"></dx-file-uploader>

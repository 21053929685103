import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
    @Input() fullName: string;
    @Input() pictureBlobName: string;

    get initials(): string {
        const splittedFullName = this.fullName?.split(' ') ?? [''];

        return splittedFullName
            .filter(name => name.length > 0)
            .map(name => name[0].toLocaleUpperCase())
            .slice(0, 2)
            .join('');
    }
}

import { RegionVATBase } from './region-vat-base';

export class RegionVAT extends RegionVATBase {
  groupId: number;
  tag: string;

  constructor(data: any = null) {
    super(data);

    this.groupId = data && data.groupId;
    this.tag = data && data.tag;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { reject } from 'lodash-es';

@Pipe({
    name: 'reject'
})
export class RejectPipe implements PipeTransform {
    transform(value: any, rejectBy: any) {
        return reject(value, rejectBy);
    }
}

import { PrintLabel } from '../models/print-label';
import { GenerateLabel } from '../models/generate-label';
import { LabelSelectorStatus, LabelSelectorItem } from '../models/label-selector-status';

interface OrderSelectorInfo {
    orderLines: OrderSelectorInfoMap;
}

interface OrderSelectorInfoMap {
    [key: string]: OrderLineSelectorInfo;
}

interface OrderLineSelectorInfo {
    productId: number;
    hasUnprintedSerials: boolean;
    items: OrderLineSelectorInfoItemMap;
}

interface OrderLineSelectorInfoItemMap {
    [key: string]: OrderLineSelectorInfoItem;
}

interface OrderLineSelectorInfoItem {
    serials: number[];
    sizeRange: number;
    size: number;
    receivedQty: number;
    orderedQty: number;
}

export class PurchaseOrderLabelsSelector {

    private orderInfo: OrderSelectorInfo;
    private selectorStatus: LabelSelectorStatus;

    constructor() {
        this.orderInfo = { orderLines: {} };
        this.selectorStatus = null;
    }

    configure(isTwoStep: boolean, usingRfid: boolean, printReceivedItemsOnly: boolean): void {
        this.selectorStatus = this.buildSelectorStatus(this.orderInfo.orderLines, isTwoStep, usingRfid, printReceivedItemsOnly);
    }

    initialize(orderLines: any[], isTwoStep: boolean, usingRfid: boolean, printReceivedItemsOnly: boolean): boolean {
        if (orderLines != null && orderLines.length > 0) {
            orderLines.forEach((orderLine: any) => {
                const productId = orderLine.productId;

                this.orderInfo.orderLines[productId] = {
                    productId,
                    hasUnprintedSerials: this.hasUnprintedSerials(orderLine),
                    items: this.buildOrderLineSelectorInfoItems(orderLine)
                };
            });

            this.selectorStatus = this.buildSelectorStatus(this.orderInfo.orderLines, isTwoStep, usingRfid, printReceivedItemsOnly);

            return true;
        }

        return false;
    }

    getCurrentStatus(): LabelSelectorStatus {
        return this.selectorStatus;
    }

    getTotalItems(productId: number, sizeRange: number, size: number): number {
        const orderLine = this.orderInfo.orderLines[productId];
        const key = this.makeKey(productId, sizeRange, size);

        return Math.max(orderLine.items[key].orderedQty, orderLine.items[key].receivedQty);
    }

    getTotalLabelsToPrint(productId: number, sizeRange: number, size: number): number {
        const key = this.makeKey(productId, sizeRange, size);

        return this.selectorStatus.getSelectorItem(key).totalLabels;
    }

    getTotalUnprintedLabels(productId: number, sizeRange: number, size: number): number {
        const orderLine = this.orderInfo.orderLines[productId];
        const key = this.makeKey(productId, sizeRange, size);

        return orderLine.items[key].serials.length;
    }

    setTotalLabelsToPrint(productId: number, sizeRange: number, size: number, qty: number): void {
        const itemKey = this.makeKey(productId, sizeRange, size);
        const infoItem = this.orderInfo.orderLines[productId].items[itemKey];
        const usingRfid = this.selectorStatus.isUsingRfid;

        const selectorItem = new LabelSelectorItem();

        selectorItem.generateLabels = this.createItemGenerateLabel(infoItem, productId, usingRfid, qty);
        selectorItem.printLabels = this.createItemPrintLabel(infoItem, productId, usingRfid, qty);
        selectorItem.totalLabels = qty;

        this.selectorStatus.setSelectorItem(itemKey, selectorItem);
    }

    private buildOrderLineSelectorInfoItems(orderLine: any): OrderLineSelectorInfoItemMap {
        const orderLineSelectorInfoItems: OrderLineSelectorInfoItemMap = {};

        orderLine.sizeSystems.forEach((sizeSystem: any) =>
            sizeSystem.stockMovements.forEach((stockMovement: any) => {
                const serials = orderLine.unprintedSerialz.filter((unprintedLabel: any) =>
                    stockMovement.size === unprintedLabel.size && stockMovement.sizeLabelId === unprintedLabel.sizeLabelId
                );
                const sizeRange = stockMovement.sizeLabelId;
                const size = stockMovement.size;
                const key = this.makeKey(orderLine.productId, sizeRange, size);

                orderLineSelectorInfoItems[key] = {
                    serials: serials.map(serial => serial.productIndentifierId),
                    sizeRange,
                    size,
                    orderedQty: stockMovement.quantityOrdered > 0 ? stockMovement.quantityOrdered : 0,
                    receivedQty: stockMovement.quantityReceived > 0 ? stockMovement.quantityReceived : 0
                };
            })
        );

        return orderLineSelectorInfoItems;
    }

    private buildSelectorStatus(orderLines: OrderSelectorInfoMap, isTwoStep: boolean, usingRfid: boolean,
                                printReceivedItemsOnly: boolean): LabelSelectorStatus {
        const selectorStatus = new LabelSelectorStatus(usingRfid);

        Object.keys(orderLines).forEach((orderLineKey: string) => {
            const orderLine = orderLines[orderLineKey];
            const fromRfid = usingRfid && orderLine.hasUnprintedSerials;
            const itemKeys = Object.keys(orderLine.items);
            const productId = orderLine.productId;

            itemKeys.forEach((itemKey: string) => {
                const infoItem = orderLine.items[itemKey];
                const maxQty = isTwoStep && printReceivedItemsOnly ? infoItem.receivedQty : infoItem.orderedQty;
                const total = fromRfid ? Math.min(infoItem.serials.length, maxQty) : maxQty;
                const selectorItem = new LabelSelectorItem();

                selectorItem.generateLabels = this.createItemGenerateLabel(infoItem, productId, usingRfid, total);
                selectorItem.printLabels = this.createItemPrintLabel(infoItem, productId, usingRfid, total);
                selectorItem.totalLabels = total;

                selectorStatus.setSelectorItem(itemKey, selectorItem);
            });
        });

        return selectorStatus;
    }

    private createItemGenerateLabel(infoItem: OrderLineSelectorInfoItem, productId: number,
                                    isUsingRfid: boolean, qty: number): GenerateLabel {
        if (!isUsingRfid || qty <= infoItem.serials.length) {
            return null;
        }

        const generateQty = qty - infoItem.serials.length;

        return new GenerateLabel(productId, infoItem.sizeRange, infoItem.size, generateQty);
    }

    private createItemPrintLabel(infoItem: OrderLineSelectorInfoItem, productId: number,
                                 isUsingRfid: boolean, qty: number): PrintLabel {
        if (isUsingRfid) {
            const printQty = Math.min(qty, infoItem.serials.length);
            const serials = infoItem.serials.slice(0, printQty);

            return PrintLabel.createPrintLabelForRfidWithSerials(productId, infoItem.sizeRange, infoItem.size, serials);
        } else {
            return PrintLabel.createPrintLabelForBarcode(productId, infoItem.sizeRange, infoItem.size, qty);
        }
    }

    private hasUnprintedSerials(orderLine: any): boolean {
        return orderLine.unprintedSerialz != null && orderLine.unprintedSerialz.length > 0;
    }

    private makeKey(productId: number, sizeRange: number, size: number): string {
        return `${productId}-${sizeRange}-${size}`;
    }
}

import { StoreAccess } from './store-access';

export enum InvitationAction {
  Send = 'Send',
  Resend = 'Resend'
}

export enum InvitationStatus {
  Active = 'Active',
  Expired = 'Expired',
  New = 'New',
  Sent = 'Sent'
}

export enum InvitationStatusDanger {
  Bounce = 'bounce',
  Dropped = 'dropped',
  SpamReport = 'spamreport'
}


export enum UserType {
  User,
  Vendor,
  CompanyAdmin
}

export enum ScopeType {
  Store,
  Company
}

export class User {
  id: string;
  email: string;
  displayName: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  phone: string;
  userType: UserType;
  invitationStatus: InvitationStatus;
  invitationAction: InvitationAction;
  storesAccess: StoreAccess[];
  invitationDeliveryStatus: string;
  invitationDeliveryStatusReason: string;
  personId: string;
  pictureBlobName: string = null;
  pictureUrl: string = null;
  userName: string = null;
  isSystemPerson = false;
  companyRoleId: number | null;
  passwordInitialized = false;

  constructor() {
    this.invitationStatus = InvitationStatus.New;
    this.userType = UserType.User;
  }

  // TODO: Should we move this out of here?
  updateStoresAccessAfterPersonUpdated(addedStoreAccessIds: any[]): void {
    const user = this;
    let pos = user.storesAccess.length - 1;

    while (pos >= 0) {
      const storeAccess = user.storesAccess[pos];

      if (storeAccess.removed) {
        user.storesAccess.splice(pos, 1);
        pos--;
        continue;
      }

      storeAccess.changed = false;

      if (storeAccess.tempId > 0 && storeAccess.id === null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < addedStoreAccessIds.length; i++) {
          const storeAccessId = addedStoreAccessIds[i];

          if (storeAccess.tempId === storeAccessId.key) {
            storeAccess.id = storeAccessId.value;
            storeAccess.tempId = 0;
            break;
          }
        }
      }

      pos--;
    }
  }

  // TODO: Should we move this out of here?
  refresh(userData: User): void {
    let user = this;

    if (userData) {
      user = Object.assign(user, userData);
      user.userType = userData.userType;
      user.storesAccess = [];

      userData.storesAccess.forEach((storeAccess) => {
        user.storesAccess.push(storeAccess);
      });
    }
  }

  // TODO: Should we move this out of here?
  deleteStoreAccess(storeAccess: any) {
    const user = this;

    if (storeAccess.tempId > 0 && storeAccess.id === null) {
      user.storesAccess.splice(user.storesAccess.indexOf(storeAccess), 1);
    } else {
      storeAccess.removed = true;
    }
  }

  // TODO: Should we move this out of here?
  validateStoreAccess(): boolean {
    // tslint:disable-next-line: triple-equals
    return this.storesAccess && this.storesAccess.length > 0 || this.userType != UserType.User;
  }

  // TODO: Should we move this out of here?
  getData() {
    const storesAccess: StoreAccess[] = [];

    this.storesAccess.forEach((item) => {
      const storeAccess = Object.assign(new StoreAccess(), {
        id: item.id,
        tempId: item.tempId,
        storeId: item.storeId,
        roleId: item.roleId,
        changed: item.changed,
        removed: item.removed,
        hasAccessToPos: item.hasAccessToPos
      });

      storesAccess.push(storeAccess);
    });

    return Object.assign(new User(), {
      id: this.id,
      email: this.email,
      displayName: this.displayName,
      firstName: this.firstName,
      lastName: this.lastName,
      phone: this.phone,
      selectedStoreAccess: storesAccess,
      personId: this.personId,
      isSystemPerson: this.isSystemPerson,
      companyRoleId: this.companyRoleId
    });
  }

  // TODO: Should we move this out of here?
  getDataForAdd(): User {
    return Object.assign(new User(), {
      id: this.id,
      email: this.email,
      displayName: this.displayName,
      firstName: this.firstName,
      lastName: this.lastName,
      phone: this.phone,
      userType: this.userType,
      isSystemPerson: this.isSystemPerson
    });
  }
}

<div class="form-group" [ngClass]="{'invalid-field': hasErrors()}">
    <label *ngIf="hasLabel()" [for]="id">{{label}} <span *ngIf="mandatory">*</span></label>
    <div *ngIf="readOnly">
        <ng-container *ngIf="hasTags()">
            <span *ngFor="let tag of value" class="tag-label">{{tag}}</span>
        </ng-container>
    </div>
    <dx-tag-box [id]="id" [name]="id" class="form-devexpress" *ngIf="!readOnly"
                noDataText="{{ (canCreateTags ? 'App_AddTagWithEnter' : 'App_NoDataToDisplay') | translate}}"
                placeholder="{{'App_Select' | translate}}"
                [acceptCustomValue]="canCreateTags"
                [searchEnabled]="true"
                [dataSource]="options"
                [disabled]="isDisabled"
                [value]="value"
                (onCustomItemCreating)="handleAdd($event)"
                (onValueChanged)="handleChange($event)"
                (onClosed)="handleTouch()"
                (onFocusOut)="handleTouch()">
    </dx-tag-box>
    <div *ngIf="hasErrors()" class="validation-error-msg">{{error}}</div>
    <div class="form-footer">
        <ng-content select="field-footer"></ng-content>
    </div>
</div>

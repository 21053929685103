<div class="dropzone-container">
  <div class="dropzone-picture profile-picture"
       [ngClass]="{ 'no-picture': !imageUrl, 'disabled': isDisabled }">
    <div [ngClass]="{ 'dropzone-image': imageUrl,
                          'dropzone-no-image': !imageUrl }">
      <div *ngIf="imageUrl">
        <img [src]="imageUrl | image : 300" *ngIf="imageUrl && canEditPicture" alt="Profile Picture" />
        <img [src]="imageUrl" *ngIf="imageUrl && !canEditPicture" alt="Receipt logo" />
      </div>
      <div *ngIf="!imageUrl && !isDisabled">
        <div class="dropzone-add-image">
          <i class="fa fa-image dropzone-add-image-icon"></i>
          <span class="dropzone-add-image-title">{{'App_DropzoneTitle' | translate}}</span>
          <span class="dropzone-add-image-subtitle">{{'App_DropzoneSubtitle' | translate}}</span>
        </div>
      </div>
      <div class="dropzone" #dropzone></div>
    </div>
  </div>
  <div class="dropzone-actions" [ngClass]="{ 'disabled': isDisabled }">
    <button class="btn btn-default"
            (click)="uploadImage()"
            (disabled)="isDisabled"
            id="uploadImage"
            type="button">
      {{'Per_UploadPictureBtn' | translate}}
    </button>
    <button class="btn btn-link"
            (click)="removeImage()"
            (disabled)="isDisabled"
            id="removeImage"
            *ngIf="imageUrl && canEditPicture"
            type="button">
      {{'Per_RemovePictureBtn' | translate}}
    </button>
  </div>
</div>

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AgentlessPrintingService {
    print(config: any) {
        return new Promise(resolve => {
            const sameWindow = config.sameWindow;
            const url = config.url;
            const innerFrameId = config.innerFrameId || 'iframeRenderer';

            if (this.isIOS() || this.isAndroid()) {
                const link = document.createElement('a');
                link.href = url;
                link.download = '';
                link.target = '_blank';

                window.document.body.appendChild(link);
                link.dispatchEvent(new MouseEvent('click'));
                window.document.body.removeChild(link);
            } else if (sameWindow) {
                let iframeRenderer = window.document.getElementById(innerFrameId) as HTMLIFrameElement;
                if (iframeRenderer) {
                    iframeRenderer.src = url;
                } else {
                    iframeRenderer = window.document.createElement('iframe');
                    iframeRenderer.className = 'hidden';
                    iframeRenderer.id = innerFrameId;
                    iframeRenderer.src = url;

                    window.document.body.appendChild(iframeRenderer);
                }

                iframeRenderer.onload = () => {
                    iframeRenderer.contentWindow.print();
                    resolve(null);
                };
            } else {
                resolve(null);
                const newWindow = window.open(url);

                // tslint:disable-next-line: no-unused-expression
                newWindow && (newWindow.onload = () => {
                    newWindow.focus();
                    newWindow.print();
                });
            }
        });
    }

    private isIOS() {
        return /(iphone|ipad|ipod)/i.test(window.navigator.userAgent);
    }

    private isAndroid() {
        return /android/i.test(window.navigator.userAgent);
    }
}

import { Location } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    MsalBroadcastService, MsalGuard, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import { LocalisationService, ServerSettingsManagerService, TranslateModule } from '@keystone-angular/core';
import { NgxZendeskWebwidgetModule } from 'ngx-zendesk-webwidget';
import { Observable } from 'rxjs';
import { AppInitializationService } from './app-initialization.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorHandlerImplementation } from './error-handler-implementation';
import { FrameworkModule } from './framework/framework.module';
import { MSALGuardConfigFactory } from './msal/msal-guard-config-factory.function';
import { MSALInstanceFactory } from './msal/msal-instance-factory.function';
import { MSALInterceptorConfigFactory } from './msal/msal-interceptor-config-factory.function';
import { MSALInterceptorFactory } from './msal/msal-interceptor-factory.function';
import { PrintService } from './print/services/print.service';
import { PrintServiceProvider } from './print/services/providers/print-service.provider';
import { ZendeskConfig } from './zendesk.config';


let providers: Provider[] = [
  AppInitializationService,
  LocalisationService,
  ServerSettingsManagerService,
  PrintServiceProvider,
  {
    provide: APP_INITIALIZER,
    useFactory: (appInitializationService: AppInitializationService, localisationService: LocalisationService,
      serverSettingsManagerService: ServerSettingsManagerService, msalBroadCastService: MsalBroadcastService,
      msalService: MsalService, printService: PrintService) => (): Observable<any> => {
        return appInitializationService.initialize(localisationService, serverSettingsManagerService,
          msalBroadCastService, msalService, printService);
      },
    deps: [AppInitializationService, LocalisationService, ServerSettingsManagerService, MsalBroadcastService,
      MsalService, PrintService],
    multi: true
  },
  {
    provide: LOCALE_ID,
    deps: [LocalisationService],
    useFactory: (localisationService: LocalisationService) => localisationService.getActiveLocale().id
  },
  {
    provide: ErrorHandler,
    deps: [Injector],
    useClass: ErrorHandlerImplementation
  }
];

providers = providers.concat([
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
  },
  MsalService,
  MsalBroadcastService,
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory
  },
  MsalGuard,
  {
    provide: HTTP_INTERCEPTORS,
    useFactory: MSALInterceptorFactory,
    deps: [MSAL_INTERCEPTOR_CONFIG, MsalService, Location, ServerSettingsManagerService, AppInitializationService],
    multi: true
  }
]);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule,
    BrowserModule,
    FrameworkModule,
    MsalModule,
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig)
  ],
  providers,
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import { ProductDataService } from '../../../product-data.service';

@Injectable({
  providedIn: 'root'
})
export class ProductGroupsDataSourceBuilderService {
  constructor(private productDataService: ProductDataService) { }

  buildDataSource(): DataSource {
    const store = new CustomStore({
      load: (opts: LoadOptions) => {
        if (!opts.take) {
          return;
        }

        return this.productDataService.getProductGroups({
          search: opts.searchValue || '',
          skip: opts.skip || 0,
          take: opts.take || 0
        }, false).toPromise();
      },
      byKey: (key) => {
        return key;
      }
    });

    return new DataSource({ store, pageSize: 20 });
  }
}

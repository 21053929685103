import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-action-label',
  templateUrl: './user-action-label.component.html',
  styleUrls: ['./user-action-label.component.scss']
})
export class UserActionLabelComponent {
    @Input() date: Date;
    @Input() displayName: string;
    @Input() fullName: string;
    @Input() thumbnail: string;
}

import { Directive, ElementRef, OnInit, HostBinding, HostListener } from '@angular/core';
import { DraggableDirective } from './draggable.directive';

interface DragEventData {
    sourceIndex: number;
    targetIndex: number;
}

interface DragHandlers {
    dragGhost: Element;
    dragStart: () => boolean;
    dragEnd: () => DragEventData;
    dragEmit: (data: DragEventData) => void;
}

interface DragImageOffset {
    x: number;
    y: number;
}

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[keyDragHandle]'
})
export class DragHandleDirective implements OnInit {
    constructor(private parent: DraggableDirective, private handle: ElementRef) { }

    @HostBinding('attr.draggable')
    draggable: boolean;

    private dragHandlers: DragHandlers;

    ngOnInit() {
        this.dragHandlers = this.parent.registerHandle(this.handle.nativeElement, this.disableHandle.bind(this));
    }

    private disableHandle(disable: boolean) {
        this.draggable = !disable;
    }

    @HostListener('dragstart', ['$event'])
    onDragStart(event: any) {
        const dragStarted = this.dragHandlers.dragStart();

        if (!dragStarted) {
            return false;
        }

        const offset = this.calculateOffset(event, this.dragHandlers.dragGhost);
        event.dataTransfer.setDragImage(this.dragHandlers.dragGhost, offset.x, offset.y);

        event.dataTransfer.effectAllowed = 'move';

        event.stopPropagation();
    }

    @HostListener('dragend', ['$event'])
    onDragEnd(event: any) {
        const dragEventData = this.dragHandlers.dragEnd();

        if (dragEventData != null) {
            this.dragHandlers.dragEmit(dragEventData);
        }

        event.stopPropagation();
    }

    calculateOffset(event: any, ghost: Element): DragImageOffset {
        const ghostStyle = window.getComputedStyle(ghost);
        const paddingTop = parseFloat(ghostStyle.paddingTop) || 0;
        const paddingLeft = parseFloat(ghostStyle.paddingLeft) || 0;
        const borderTop = parseFloat(ghostStyle.borderTopWidth) || 0;
        const borderLeft = parseFloat(ghostStyle.borderLeftWidth) || 0;

        return {
            x: event.offsetX + paddingLeft + borderLeft,
            y: event.offsetY + paddingTop + borderTop
        };
    }
}

<div class="serials-generation-progress-container">
  <key-spinner-label class="serials-generation-progress-spinner"></key-spinner-label>
  <div class="serials-generation-progress-info">
    <div class="serials-generation-progress-message">{{ 'App_PrintingInProgressMessage' | translate }}</div>
    <div>
      <span class="serials-generation-progress-label">{{ 'App_Progress' | translate }}: </span> 
      <span>{{ percentage }}%</span>
    </div>
    <div>
      <span class="serials-generation-progress-label">{{ 'App_RemainingTime' | translate }}: </span> 
      <span *ngIf="estimatedTime === -1">{{ 'App_CalculatingMessage' | translate }}</span> 
      <span *ngIf="estimatedTime > -1">{{ getEstimatedTime() }}</span>
    </div>
  </div>
</div>

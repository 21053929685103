import { Injectable } from '@angular/core';
import {
  ApplicationInsights, IApplicationInsights, IEventTelemetry,
  IExceptionTelemetry, IPageViewTelemetry, ITelemetryItem, RemoteDependencyData
} from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';
import { ContextService } from '../auth/context.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService {
  private appInsights: IApplicationInsights;
  private trackedExceptions: string[] = [];

  constructor(contextService: ContextService) {
    // This is not a secure way to add the applicationinsights but it is the only available one.
    // Check https://github.com/Microsoft/ApplicationInsights-JS/issues/281 for further developments
    this.appInsights = new ApplicationInsights({
      config: environment.applicationInsights.baseConfig
    }).loadAppInsights();

    this.appInsights.context.user.id = !contextService.user
      ? 'Anonymous'
      : contextService.user?.email;

    this.appInsights.addTelemetryInitializer(envelope => {
      envelope.data = Object.assign(envelope.data, { context: contextService.user });
      envelope.data = this.flattenObj(envelope.data);

      if (this.isCrossOriginSafariBug(envelope)) {
        return false;
      }
      else if (envelope.baseType === 'ExceptionData' && envelope.baseData.exceptions && envelope.baseData.exceptions.length > 0)
      {
        if (this.isResizeObserverException(envelope) || this.alreadyTrackedException(envelope)) {
          return false;
        }
        else {
          this.trackedExceptions.push(envelope.baseData.exceptions[0].message);
          return true;
        }
      }

      if (environment.applicationInsights.filterOutSuccessfulDependencies
        && envelope.baseType === RemoteDependencyData.dataType
        && (envelope.baseData).success as RemoteDependencyData) {
        return false;
      }
    });
  }

  logException(exception: IExceptionTelemetry, customProperties?: { [key: string]: any }): void {
    if (this.shouldLogException(exception)) {
      this.appInsights.trackException(exception, customProperties);
    }
  }

  logEvent(event: IEventTelemetry, customProperties?: { [key: string]: any }): void {
    this.appInsights.trackEvent(event, customProperties);
  }

  logPageView(pageView: IPageViewTelemetry, customProperties?: { [key: string]: any }): void {
    this.appInsights.trackPageView(pageView, customProperties);
  }

  private shouldLogException(exception: IExceptionTelemetry): boolean {
    const error = exception?.exception?.message;

    return error?.indexOf('Script error') === -1 &&
      error?.indexOf('401 OK') === -1 &&
      error?.indexOf('ResizeObserver') === -1 &&
      error?.indexOf('ChunkLoadError: Loading chunk') === -1;
  }

  private flattenObj(obj: {}, separator: string = '_', parent: string = null, res: {} = {}): {} {
    // tslint:disable-next-line: forin
    for (const key in obj) {
      const propName = parent ? `${parent}${separator}${key}` : key;
      // tslint:disable-next-line: triple-equals
      if (typeof obj[key] == 'object') {
        this.flattenObj(obj[key], separator, propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  }

  private isResizeObserverException(envelope: ITelemetryItem) {
    return envelope.baseData.exceptions[0].message.indexOf('ResizeObserver') !== -1;
  }

  private alreadyTrackedException(envelope: ITelemetryItem): boolean {
    return this.trackedExceptions.some(x => x === envelope.baseData.exceptions[0].message);
  }

  // More info:
  // https://stackoverflow.com/questions/71436655/azure-app-insights-script-error-the-browsers-same-origin-policy-prevents-us
  // https://github.com/microsoft/ApplicationInsights-JS/issues/1383
  private isCrossOriginSafariBug(envelope: ITelemetryItem): boolean {
    return envelope.baseType === 'ExceptionData' &&
      typeof (envelope.baseData.exceptions) === 'undefined' &&
      envelope.baseData.message &&
      envelope.baseData.message.indexOf('same-origin policy prevents us from getting the details of this exception');
  }

}

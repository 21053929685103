<div class="table-responsive label-selector-table" *ngIf="inventory">
  <table class="qty-table">
    <tbody *ngFor="let sizeRange of inventory.sizeRanges">
      <tr>
        <td rowspan="3" class="print-size-range-label-cell">{{ sizeRange.name }}</td>
        <td>&nbsp;</td>
        <td class="print-size-label-header-cell" *ngFor="let size of sizeRange.sizes">{{ size.label }}</td>
      </tr>
      <tr>
        <td class="print-selector-header-cell">{{ 'App_LabelType' | translate }}</td>
        <td class="print-selector-cell" *ngFor="let size of sizeRange.sizes">
          <dx-number-box id="sizeLabel"
                         class="print-selector"
                         [min]="0"
                         [showSpinButtons]="true"
                         [value]="getTotalLabelsToPrint(sizeRange, size)"
                         (onValueChanged)="updateTotalLabelsToPrint(sizeRange, size, $event)">
          </dx-number-box>
        </td>
      </tr>
      <tr>
        <td class="print-summary-header-cell">{{ 'App_InStock' | translate }}</td>
        <td class="print-summary-cell" *ngFor="let size of sizeRange.sizes">{{ getTotalItems(sizeRange, size) || '&nbsp;' }}</td>
      </tr>
    </tbody>
  </table>
</div>

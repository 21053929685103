import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService, TranslatePipe } from '@keystone-angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { ContextService } from '../../../../../framework/auth/context.service';
import { ApplicationInsightsService } from '../../../../../framework/logging/application-insights.service';
import { DefaultRegionVAT } from '../../../../../shared/settings-old/models/default-region-vat';
import { Region } from '../../../../../shared/settings-old/models/region';
import { RegionVAT } from '../../../../../shared/settings-old/models/region-vat';
import { RegionVATBase } from '../../../../../shared/settings-old/models/region-vat-base';
import { SettingsDataService } from '../../../../../shared/settings-old/services/settings-data.service';
import { GroupManagerService } from '../group-manager.service';
import { Group } from '../models/group';

@Component({
  selector: 'app-group-modal',
  templateUrl: './group-modal.component.html',
  styleUrls: ['./group-modal.component.scss']
})
export class GroupModalComponent implements OnInit {
  @Input() group: Group;

  // tslint:disable-next-line: no-output-rename no-output-native no-output-on-prefix
  @Output('close') onClose = new EventEmitter<Group>();

  groupForm: FormGroup;
  groups: any[];
  region: Region;
  regionId: number;
  showValidation: boolean;
  vats: any[];

  constructor(private applicationInsightsService: ApplicationInsightsService,
              private bsModalRef: BsModalRef,
              private contextService: ContextService,
              private formBuilder: FormBuilder,
              private groupManager: GroupManagerService,
              private notificationService: NotificationService,
              private settingsDataService: SettingsDataService,
              private translatePipe: TranslatePipe) { }

  ngOnInit(): void {
    const initialRequests = [
      this.groupManager.loadGroupsOnly(),
      this.groupManager.loadVATList()
    ];

    this.regionId = this.contextService.user?.regionId;
    if (!!this.regionId) {
      initialRequests.push(this.settingsDataService.getRegion(this.regionId));
    }

    forkJoin(initialRequests).subscribe(data => {
      this.groups = data[0];
      if (!this.group || !this.group.id) {
        this.groups.unshift({
          id: null,
          name: this.translatePipe.transform('PGr_NoParent')
        });
      }

      this.vats = data[1];

      if (!this.regionId) {
        // TODO: Remove legacy support at some point
        this.groupForm = this.formBuilder.group({
          alternativeVAT: [this.group && this.group.alternativeVAT],
          name: [this.group && this.group.name, [Validators.required]],
          parentId: [this.group && this.group.parentId],
          vatHighId: [this.group && this.group.vatHighId],
          vatLowId: [this.group && this.group.vatLowId]
        });
      } else {
        this.region = new Region(data[2]);

        const vatHigh = this.group && this.group.regionVATs && this.group.regionVATs.find(rv => rv.vatAreaId === 1);
        const vatLow = this.group && this.group.regionVATs && this.group.regionVATs.find(rv => rv.vatAreaId === 2);

        this.groupForm = this.formBuilder.group({
          name: [this.group && this.group.name, [Validators.required]],
          parentId: [this.group && this.group.parentId],
          vatHigh: [
            vatHigh
            && Object.assign(new RegionVAT(), vatHigh)
            || Object.assign(new DefaultRegionVAT(), this.region.defaultRegionVATs.find(drv => drv.vatAreaId === 1))
          ],
          vatLow: [
            vatLow
            && Object.assign(new RegionVAT(), vatLow)
            || Object.assign(new DefaultRegionVAT(), this.region.defaultRegionVATs.find(drv => drv.vatAreaId === 2))
          ]
        });
      }
    });
  }

  close(): void {
    this.bsModalRef.hide();
    this.onClose.emit();
  }

  getRegionVATLockTitle(regionVAT: RegionVATBase): string {
    return this.translatePipe.transform(this.isRegionVATLocked(regionVAT)
      ? 'PGr_UnlockRegionVAT' : 'PGr_LockRegionVAT');
  }

  isRegionVATLocked(regionVAT: RegionVATBase): boolean {
    return regionVAT instanceof DefaultRegionVAT;
  }

  ok(): void {
    this.showValidation = false;

    if (this.groupForm.valid) {
      const group = Object.assign(this.group || new Group(), this.groupForm.value as Group);
      const hasId = group.id != null;

      group.name = this.groupForm.get('name').value.trim();

      if (!!this.regionId) {
        const vatHigh: RegionVATBase = this.groupForm.get('vatHigh').value;
        const vatLow: RegionVATBase = this.groupForm.get('vatLow').value;

        group.regionVATs = [];

        if (!(vatHigh instanceof DefaultRegionVAT)) {
          const defaultRegionVatHigh = this.region.defaultRegionVATs.find(drv => drv.vatAreaId === 1);
          if (!vatHigh.equalsBaseRule(defaultRegionVatHigh)) {
            group.regionVATs.push(vatHigh as RegionVAT);
          }
        }

        if (!(vatLow instanceof DefaultRegionVAT)) {
          const defaultRegionVatLow = this.region.defaultRegionVATs.find(drv => drv.vatAreaId === 2);
          if (!vatLow.equalsBaseRule(defaultRegionVatLow)) {
            group.regionVATs.push(vatLow as RegionVAT);
          }
        }
      }

      this.groupManager.save(group).subscribe(addedGroup => {
        this.notificationService.showSuccess(
          this.translatePipe.transform(hasId ?
            'PGr_ProductSubGroupWasSuccessfullyUpdated' : 'PGr_ProductSubGroupWasSuccessfullyAdded'
          )
        );

        if (addedGroup) {
          group.parentId = addedGroup.parentId;
          group.id = addedGroup.id;
          group.regionVATs = addedGroup.regionVATs;
          group.vat = addedGroup.vat;
        }
        this.bsModalRef.hide();
        this.onClose.emit(group);
      }, errorResponse => {
        if (errorResponse && errorResponse.error && errorResponse.error.reason) {
          switch (errorResponse.error.reason) {
            case 'InvalidModel':
              this.notificationService.showError(this.translatePipe.transform('PGr_InvalidModel'));
              break;
            case 'AlreadyAdded':
              this.notificationService.showError(this.translatePipe.transform('PGr_AlreadyAdded'));
              break;
          }
        }
      });
    } else {
      this.showValidation = true;
    }
  }

  onToggleLock(vatAreaId: number, isLocked: boolean): void {
    const controlName = vatAreaId === 1 ? 'vatHigh' : 'vatLow';
    const regionVAT = this.groupForm.get(controlName).value as RegionVATBase;

    if (isLocked) {
      this.groupForm.get(controlName).setValue(Object.assign(
        new DefaultRegionVAT(),
        this.region.defaultRegionVATs.find(drv => drv.vatAreaId === vatAreaId)
      ));
    } else {
      this.groupForm.get(controlName).setValue(Object.assign(
        new RegionVAT(),
        Object.assign({ groupId: this.group && this.group.id }, regionVAT)
      ));
    }
  }
}

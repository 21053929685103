<div class="modal-header">
  <h4 class="modal-title">{{ title | translate }}</h4>
  <button *ngIf="canClose" type="button" class="btn btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <h4 *ngIf="subtitle">{{ subtitle | translate }}</h4>
  <p>{{ message | translate }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn" [ngClass]="okClass" (click)="ok()">{{ okText | translate }}</button>
  <button *ngIf="canCancel" type="button" class="btn" [ngClass]="cancelClass" (click)="close()">{{ cancelText | translate }}</button>
</div>

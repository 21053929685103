export class DragContainer {
    get element(): Element {
        return this.dcElement;
    }

    private dcElement: Element;
    private draggables: Element[];

    constructor(container: Element) {
        this.draggables = [];
        this.dcElement = container;
    }

    addDraggable(element: Element) {
        const index = this.calculateInsertIndex(this.draggables, element);

        const left = this.draggables.slice(0, index);
        const right = this.draggables.slice(index);

        this.draggables = [].concat(left, [element], right);
    }

    deleteDraggable(draggable: Element) {
        this.draggables = this.draggables.filter((item: Element) => item !== draggable);
    }

    findDraggable(element: Element) {
        return this.draggables.find((draggable: Element) => draggable === element) || null;
    }

    findDraggableIndex(element: Element) {
        return this.draggables.findIndex((draggable: Element) => draggable === element);
    }

    insertDraggable(element: Element, index: number) {
        const leftPart = this.draggables.slice(0, index);
        const rightPart = this.draggables.slice(index);

        this.draggables = [].concat(leftPart, [element], rightPart);
    }

    isEmpty() {
        return this.draggables.length === 0;
    }

    moveDraggable(fromIndex: number, toIndex: number) {
        const draggable = this.draggables[fromIndex];

        if (fromIndex < toIndex) {
            for (let index = fromIndex + 1; index <= toIndex; index++) {
                this.draggables[index - 1] = this.draggables[index];
            }
        } else if (fromIndex > toIndex) {

            for (let index = fromIndex - 1; index >= toIndex; index--) {
                this.draggables[index + 1] = this.draggables[index];
            }
        }

        this.draggables[toIndex] = draggable;
    }

    private calculateInsertIndex(draggables: Element[], newDraggable: Element) {
        let pointer = newDraggable.previousElementSibling;

        while (pointer != null) {
            if (pointer.hasAttribute('keydraggable')) {
                const index = draggables.indexOf(pointer);

                if (index >= 0) {
                    return index + 1;
                }
            }

            pointer = pointer.previousElementSibling;
        }

        return 0;
    }
}

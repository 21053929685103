import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription, timer } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'key-session-timeout-modal',
  templateUrl: './session-timeout-modal.component.html',
  styleUrls: ['./session-timeout-modal.component.scss']
})
export class SessionTimeoutModalComponent implements OnDestroy, OnInit {
    secondsLeft: number;
    timerSubscription: Subscription;

    @Output() hide = new EventEmitter<boolean>();

    constructor(private bsModalRef: BsModalRef) { }

    ngOnDestroy(): void {
        this.timerSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.secondsLeft = 10;
        this.timerSubscription = timer(0, 1000).subscribe(_ => {
            this.secondsLeft--;

            if (this.secondsLeft <= 0) {
                this.bsModalRef.hide();
                this.hide.emit(false);
            }
        });
    }

    cancel(): void {
        this.bsModalRef.hide();
        this.hide.emit(true);
    }
}

<svg width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="spinner">
  <rect x="0" y="0" width="100" height="100" fill="none" class="bk"></rect>
  <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" fill="#0bacd3" transform="rotate(0 50 50) translate(0 -30)">
    <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0s" repeatCount="indefinite" />
  </rect>
  <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" fill="#0bacd3" transform="rotate(36 50 50) translate(0 -30)">
    <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.1s" repeatCount="indefinite" />
  </rect>
  <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" fill="#0bacd3" transform="rotate(72 50 50) translate(0 -30)">
    <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.2s" repeatCount="indefinite" />
  </rect>
  <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" fill="#0bacd3" transform="rotate(108 50 50) translate(0 -30)">
    <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.3s" repeatCount="indefinite" />
  </rect>
  <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" fill="#0bacd3" transform="rotate(144 50 50) translate(0 -30)">
    <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.4s" repeatCount="indefinite" />
  </rect>
  <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" fill="#0bacd3" transform="rotate(180 50 50) translate(0 -30)">
    <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.5s" repeatCount="indefinite" />
  </rect>
  <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" fill="#0bacd3" transform="rotate(216 50 50) translate(0 -30)">
    <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.6s" repeatCount="indefinite" />
  </rect>
  <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" fill="#0bacd3" transform="rotate(252 50 50) translate(0 -30)">
    <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.7s" repeatCount="indefinite" />
  </rect>
  <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" fill="#0bacd3" transform="rotate(288 50 50) translate(0 -30)">
    <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.8s" repeatCount="indefinite" />
  </rect>
  <rect x="46.5" y="40" width="7" height="20" rx="5" ry="5" fill="#0bacd3" transform="rotate(324 50 50) translate(0 -30)">
    <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.9s" repeatCount="indefinite" />
  </rect>
</svg>

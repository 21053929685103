import { Component } from '@angular/core';
import { FilterComponent } from '../filter.component';
import { FilterDefinition } from '../../../filtered-search/models/filter-definition';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent extends FilterComponent<Date> {
  initFromData(filterDefinition: FilterDefinition, filterValue: Date) {
    super.initFromData(filterDefinition, filterValue);

    this.value = filterValue;
  }

  onDateChanged(date: Date) {
    if (this.value !== date) {
      this.value = date || null;
    }
  }
}

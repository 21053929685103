import { registerLocaleData } from '@angular/common';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventType } from '@azure/msal-browser';
import { LocalisationService, ServerSettings, ServerSettingsManagerService } from '@keystone-angular/core';
import { locale } from 'devextreme/localization';
import { concatMap, filter, forkJoin, Observable, of } from 'rxjs';
import { InitializablePrintService } from './print/services/initializable-print.service';
import { PrintService } from './print/services/print.service';

export class AppInitializationService {

  initialize(localisationService: LocalisationService,
             serverSettingsManagerService: ServerSettingsManagerService,
             msalBroadcastService: MsalBroadcastService,
             msalService: MsalService,
             printService: PrintService): Observable<any> {
    return forkJoin({
      serverSettings: serverSettingsManagerService.loadServerSettings().pipe(
        concatMap((serverSettings: ServerSettings) => {

          msalBroadcastService.msalSubject$
            .pipe(filter(message => message.eventType === EventType.LOGIN_FAILURE))
            .subscribe(message => {
              // This AADB2C90091 error code is returned by B2C when user aborts Reset Password flow
              // and maybe in other cases. So we redirect the user to login screen again.
              if (message.error.message.indexOf('AADB2C90091') > -1) {
                msalService.loginRedirect();
              }
          });

          return (printService as InitializablePrintService).initialize();
        }))
      ,
      localisation: localisationService.loadLanguages().pipe(concatMap(() => {
        // TODO: We may do this dinamically
        // Check: https://blog.angularindepth.com/dynamic-import-of-locales-in-angular-b994d3c07197
        const activeLocale = localisationService.getActiveLocale();

        locale(activeLocale.id);

        import(`@angular/common/locales/${activeLocale.name}.js`).then(localeData => {
          registerLocaleData(localeData.default, activeLocale.id);
        });

        return localisationService.loadLocalisation();
      }))
    });
  }
}

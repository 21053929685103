import { Injectable } from '@angular/core';
import { DataService } from '@keystone-angular/core';
import { Observable } from 'rxjs';
import { PrintAgent } from './models/print-agent';

@Injectable({
  providedIn: 'root'
})
export class PrintAgentDataService {
    private printAgentUrl = 'v2/device/printagent';

    constructor(private dataService: DataService) { }

    getPrintAgents(): Observable<PrintAgent[]> {
        const url = this.printAgentUrl;

        return this.dataService.get<PrintAgent[]>(url);
    }

    sendRefreshCommand(printAgentId: string): Observable<any> {
        const url = `${this.printAgentUrl}/request/refresh/${printAgentId}`;

        return this.dataService.get<any>(url);
    }

    getPrintAgentMac(): Observable<string> {
        const url = `${this.printAgentUrl}/mac/`;

        return this.dataService.get<any>(url);
    }

    addPrintAgent(mac: string): Observable<boolean> {
        const url = `${this.printAgentUrl}/add/`;

        return this.dataService.post(url, mac);
    }
}

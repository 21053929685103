import {
  AfterContentChecked, AfterContentInit, AfterViewInit, ChangeDetectorRef, Component,
  ContentChild, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, Output, SimpleChanges, TemplateRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldControlDirective, FieldErrorComponent, FieldFooterComponent, FieldLabelComponent } from '@keystone-angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from '../../../modal/modal.service';
import { EditableFieldAction } from '../../../model/editable-field-action';

@Component({
  selector: 'app-editable-field',
  templateUrl: './editable-field.component.html',
  styleUrls: ['./editable-field.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditableFieldComponent),
    multi: true
  }]
})
export class EditableFieldComponent implements ControlValueAccessor, AfterContentInit, AfterViewInit,
                                               AfterContentChecked, OnChanges, OnDestroy {
  @Input() actions: EditableFieldAction[];
  @Input() editable: boolean;
  @Input() editor: Component;
  @Input() editorContextBuilder: (action: EditableFieldAction) => any;
  @Input() id: string;
  @Input() inlineLabel: boolean;
  @Input() mandatory: boolean;
  @Input() noValueMessage: string;
  @Input() readOnly: boolean;

  @Output() itemCreated: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemDeleted: EventEmitter<any> = new EventEmitter<any>();

  @ContentChild(FieldControlDirective, { static: true }) private ccFieldControl: FieldControlDirective;
  @ContentChild(FieldErrorComponent, { static: false }) private ccFieldError: FieldErrorComponent;
  @ContentChild(FieldFooterComponent, { static: false }) private ccFieldFooter: FieldFooterComponent;
  @ContentChild(FieldLabelComponent, { static: false }) private ccFieldLabel: FieldLabelComponent;

  value: any;
  isDisabled: boolean;

  private touchedSubscription: Subscription;
  private valueChangedSubscription: Subscription;

  private onChange: (_: any) => void;
  private onTouched: () => void;

  private static EMPTY_ONCHANGE_FN: (_: any) => void = (_: any) => { };
  private static EMPTY_ONTOUCHED_FN: () => void = () => { };

  constructor(private cdRef: ChangeDetectorRef,
              private modalService: ModalService) {
    this.value = null;
    this.isDisabled = false;

    this.onChange = EditableFieldComponent.EMPTY_ONCHANGE_FN;
    this.onTouched = EditableFieldComponent.EMPTY_ONTOUCHED_FN;
  }

  ngAfterContentInit(): void {
    this.touchedSubscription = this.ccFieldControl.touched.subscribe(() => this.handleTouch());
    this.valueChangedSubscription = this.ccFieldControl.valueChanged.subscribe((newValue: any) => this.handleChange(newValue));
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.ccFieldControl.invalidValue = this.ccFieldError != null;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.readOnly != null) {
      this.ccFieldControl.readOnly = simpleChanges.readOnly.currentValue;
    }

    if (simpleChanges.id != null) {
      this.ccFieldControl.id = simpleChanges.id.currentValue;
    }

    if (simpleChanges.noValueMessage != null) {
      this.ccFieldControl.noValueMessage = simpleChanges.noValueMessage.currentValue || '-';
    }
  }

  ngOnDestroy() {
    this.touchedSubscription.unsubscribe();
    this.valueChangedSubscription.unsubscribe();
  }

  getError(): TemplateRef<any> {
    return this.ccFieldError.content;
  }

  getFooter(): TemplateRef<any> {
    return this.ccFieldFooter.content;
  }

  getLabel(): TemplateRef<any> {
    return this.ccFieldLabel.content;
  }

  handleChange(newValue: any): void {
    this.value = newValue;

    this.onChange(this.value);
  }

  handleTouch(): void {
    this.onTouched();
  }

  hasAction(action: EditableFieldAction): boolean {
    return this.actions?.includes(action) ?? false;
  }

  hasActions(): boolean {
    return this.editable !== false && !this.readOnly &&
      (this.hasAction(EditableFieldAction.add) || this.hasAction(EditableFieldAction.delete));
  }

  hasLabel(): boolean {
    return this.ccFieldLabel != null;
  }

  showEditor(action: EditableFieldAction): void {
    if (this.editor == null) {
      throw new Error('Editor component is required');
    }

    const modal = this.modalService.open(this.editor, {
      backdrop: 'static',
      initialState: this.editorContextBuilder != null ? this.editorContextBuilder(action) : null,
      keyboard: false
    });

    return (modal.content as any).onClose.subscribe((newValue: any) => {
      if (newValue != null) {
        if (action === EditableFieldAction.add) {
          this.itemCreated.emit(newValue);

          const finalValue = this.value instanceof Array ? this.value.concat([newValue]) : newValue;

          this.ccFieldControl.value = finalValue;
          this.handleChange(finalValue);
        } else if (action === EditableFieldAction.delete) {
          this.itemDeleted.emit(newValue);

          const finalValue = this.value instanceof Array ? this.value.filter(item => item !== newValue) : null;

          this.ccFieldControl.value = finalValue;
          this.handleChange(finalValue);
        }
      }
    });
  }

  showFieldError(): boolean {
    return this.ccFieldError != null;
  }

  showFieldFooter(): boolean {
    return this.ccFieldFooter != null;
  }

  showFieldLabel(): boolean {
    return this.hasLabel() || this.mandatory;
  }

  writeValue(value: any): void {
    this.value = value || null;

    this.ccFieldControl.value = this.value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn ?? EditableFieldComponent.EMPTY_ONCHANGE_FN;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn ?? EditableFieldComponent.EMPTY_ONTOUCHED_FN;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;

    this.ccFieldControl.disabled = this.isDisabled;
  }
}

import { AfterViewInit, Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-spinner-modal',
  templateUrl: './spinner-modal.component.html',
  styleUrls: ['./spinner-modal.component.scss']
})
export class SpinnerModalComponent implements AfterViewInit {
  @Input() message: string;
  @Input() title: string;
  @Input() waitUntil: Observable<any> | Promise<any>;
  @Input() successFn: (result: any) => void;
  @Input() errorFn: (error: any) => void;

  constructor(private bsModalRef: BsModalRef) { }

  ngAfterViewInit(): void {
    if (this.waitUntil instanceof Observable) {
      this.waitUntil.subscribe(
        result => {
          if (this.successFn != null) {
            this.successFn(result);
          }

          this.bsModalRef.hide();
        },
        error => {
          if (this.errorFn != null) {
            this.errorFn(error);
          }

          this.bsModalRef.hide();
        }
      );
    } else {
      this.waitUntil.then(
        result => {
          if (this.successFn != null) {
            this.successFn(result);
          }

          this.bsModalRef.hide();
        }
      ).catch(
        error => {
          if (this.errorFn != null) {
            this.errorFn(error);
          }

          this.bsModalRef.hide();
        }
      );
    }
  }
}

<div class="modal-header">
  <h4 class="modal-title">{{ 'Pro_AddTag' | translate }}</h4>
  <button type="button" class="btn btn-close" aria-label="Close" (click)="close()" id="cancelButton"></button>
</div>
<div class="modal-body">
  <form [formGroup]="tagForm" class="form" name="tagForm" autocomplete="off">
    <div class="mb-3">
      <label for="tag" class="form-label">{{ 'Pro_Tag' | translate }}<span class="form-mandatory-notice">*</span></label>
      <input type="text" class="form-control" name="tag"
             formControlName="tag" id="tag"
             [ngClass]="{'input-validation' : showValidation}"
             autofocus />
      <div *ngIf="tagForm.get('tag').invalid && showValidation" class="validation-error-msg">
        <span *ngIf="tagForm.get('tag').hasError('required')">{{ 'Pro_TagRequired' | translate }}</span>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" id="tagAddButton" (click)="ok()"
          *permission="{ domain: 'product|product', rule: 'catalog|productEnrichment', action: 'canEdit|canEdit' }">
    {{ 'App_Add' | translate }}
  </button>
  <button class="btn btn-default" type="button" (click)="close()">{{ 'App_Cancel' | translate }}</button>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  @Input() cancelClass: string;
  @Input() cancelText: string;
  @Input() message: string;
  @Input() okClass: string;
  @Input() okText: string;
  @Input() subtitle: string;
  @Input() title: string;
  @Input() canCancel = true;
  @Input() canClose = true;
  // tslint:disable-next-line: no-output-native
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private bsModalRef: BsModalRef) { }


  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy(): void {
    this.bsModalRef = null;
  }

  isOpen(): boolean {
    return this.bsModalRef != null;
  }

  ok(): void {
    this.bsModalRef.hide();
    this.closed.emit(true);
  }

  close(): void {
    this.bsModalRef.hide();
    this.closed.emit(false);
  }
}

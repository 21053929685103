import { HttpClient } from '@angular/common/http';
import { TokenService, TranslatePipe } from '@keystone-angular/core';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { ModalService } from '../../framework/modal/modal.service';
import { PrintAgentManager } from '../../home/admin/print-agent/print-agent-manager.service';
import { UserManager } from '../../home/admin/user/user-manager.service';
import { SettingsDataService } from '../../shared/settings-old/services/settings-data.service';
import { AgentlessPrintingService } from './agentless-printing.service';
import { PrintService } from './print.service';

export class InitializablePrintService extends PrintService {
  constructor(private printAgentManager: PrintAgentManager,
              private settingsDataService: SettingsDataService,
              private userManager: UserManager,
              agentlessPrintingService: AgentlessPrintingService,
              httpClient: HttpClient,
              modalService: ModalService,
              tokenService: TokenService,
              translatePipe: TranslatePipe) {
    super(agentlessPrintingService, httpClient, modalService, tokenService, translatePipe);
  }

  initialize(): Observable<void> {
    return forkJoin({
      isRfidAvailable: this.settingsDataService.checkIfRfidIsEnabled(),
      printAgents: this.printAgentManager.getPrintAgents(),
      selectedBarcodePrinterId: this.userManager.getUserSetting('defaultPrinterId'),
      selectedRfidPrinterId: this.userManager.getUserSetting('defaultRfidPrinterId')
    }).pipe(
      catchError(() => {
        return of({ isRfidAvailable: false, printAgents: [], selectedBarcodePrinterId: null, selectedRfidPrinterId: null })
      }),
      map(data => {
        const isRfidAvailable = data.isRfidAvailable;
        const hasAgents = data.printAgents != null && data.printAgents.length > 0;

        this.pHasAgents = hasAgents;
        this.pCanPrintBarcodeLabels = !hasAgents || data.selectedBarcodePrinterId != null;
        this.pCanPrintRfidLabels = isRfidAvailable && data.selectedRfidPrinterId != null;
      })
    );
  }
}

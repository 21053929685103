import { Directive, EventEmitter, Input, OnInit, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { ContextService } from './context.service';
import { PermissionService } from './permission.service';
import { RuleSet } from './rule-set';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[permission]'
})
export class PermissionDirective implements OnInit {
    constructor(private contextService: ContextService,
                private permissionService: PermissionService,
                private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef) { }

    @Input() permission: RuleSet;

    @Input() permissionUnlessSuperAdmin: boolean;

    @Output() permissionChange = new EventEmitter<boolean>();

    ngOnInit(): void {
        const hasPermission = !this.permission || (this.permissionUnlessSuperAdmin && this.contextService.user?.isSuperAdmin)
            ? true
            : this.permissionService.hasPermission(this.permission.domain, this.permission.rule, this.permission.action);

        if (hasPermission || this.permission.showOnUnauthorized) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }

        this.permissionChange.emit(hasPermission);
    }
}

export abstract class RegionVATBase {
  id: number;
  minimumAmount: number;
  regionId: number;
  taxationOnlyOnExceedingAmount: boolean;
  vatAreaId: number;
  vatId: number;

  constructor(data: any = null) {
    this.id = data && data.id;
    this.minimumAmount = (data && data.minimumAmount) || 0;
    this.regionId = data && data.regionId;
    this.taxationOnlyOnExceedingAmount = (data && data.taxationOnlyOnExceedingAmount) || false;
    this.vatAreaId = data && data.vatAreaId;
    this.vatId = data && data.vatId;
  }

  equalsBaseRule(other: RegionVATBase): boolean {
    if (!other) {
      return false;
    }

    return this.minimumAmount === other.minimumAmount
      && this.taxationOnlyOnExceedingAmount === other.taxationOnlyOnExceedingAmount
      && this.vatAreaId === other.vatAreaId
      && this.vatId === other.vatId;
  }
}

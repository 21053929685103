<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="cancel()"><span aria-hidden="true">&times;</span></button>
  <h4 class="modal-title">{{'App_SessionTimeout' | translate}}</h4>
</div>
<div class="modal-body">
  <p>{{'App_SessionTimeoutCountdown' | translate:[secondsLeft]}}</p>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="cancel()">{{'App_Continue' | translate}}</button>
</div>

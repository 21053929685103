import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'key-text-field',
    templateUrl: './text-field.component.html',
    styleUrls: ['./text-field.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TextFieldComponent),
        multi: true
    }]
})
export class TextFieldComponent implements ControlValueAccessor {
    @Input() id = '';
    @Input() label: string;
    @Input() mandatory: boolean;
    @Input() readOnly: boolean;
    @Input() maxLength: number;
    @Input() error: string;
    @Input() noValueMessage: string;
    @Input() placeholder: string;
    @Input() valueTooltip: string;

    value: string;
    isDisabled: boolean;

    onChange: (_: any) => void = (_: any) => { };
    onTouched: () => void = () => { };

    constructor() {
        this.value = '';
        this.isDisabled = false;
    }

    handleChange(event: any) {
        this.value = event.target.value || '';

        this.handleTouch();
        this.onChange(this.value);
    }

    handleTouch() {
        this.onTouched();
    }

    hasErrors() {
        return this.error != null && this.error !== '';
    }

    hasLabel() {
        return this.label != null && this.label !== '';
    }

    hasValue() {
        return this.value != null && this.value !== '';
    }

    writeValue(value: string): void {
        this.value = value || '';
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}

import { AfterViewInit, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Dropzone from 'dropzone';
import { TokenWrapperService } from '../../../../authentication/token-wrapper.service';

@Component({
    providers: [{
        multi: true,
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ImageDropzoneComponent)
    }],
    // tslint:disable-next-line: component-selector
    selector: 'key-image-dropzone',
    styleUrls: ['./image-dropzone.component.scss'],
    templateUrl: './image-dropzone.component.html'
})
export class ImageDropzoneComponent implements AfterViewInit, ControlValueAccessor {
    @Input() imageUrl: string;
    @Input() apiUrl: string;
    @Input() blobName: string;
    @Input() canEditPicture: boolean;
    dropzone: any;
    @ViewChild('dropzone', { static: false }) dropzoneRef: ElementRef;
    isDisabled: boolean;
    onChange: (blobName: string) => void;
    onTouched: () => void;

    private token: string;

    constructor(private tokenService: TokenWrapperService) { }

  ngAfterViewInit(): void {
    this.tokenService.getToken().subscribe(result => {
      this.token = result;
      this.initDropZone();
    });
  }

  initDropZone() {

    this.dropzone = new Dropzone(this.dropzoneRef.nativeElement, {
      url: this.apiUrl,
      maxFilesize: 10000,
      uploadMultiple: false,
      previewsContainer: null,
      acceptedFiles: 'image/*',
      dictDefaultMessage: null,
      headers: this.token != null ? { Authorization: this.token } : null
    });

    this.dropzone.on('addedfile', function () {
      if (this.files[1] != null) {
        this.removeFile(this.files[0]);
      }
    });
    this.dropzone.on('error', () => { });
    this.dropzone.on('success', (_: any, response: any) => {

      this.blobName = response[0].blobName;
      this.imageUrl = response[0].imagePath || this.blobName;
      this.onChange(this.blobName);
    });
    }

    registerOnChange(onChange: (blobName: string) => void): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    removeImage(): void {
        this.imageUrl = null;
        this.onChange(null);
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    uploadImage(): void {
        this.dropzone.hiddenFileInput.click();
    }

    writeValue(blobName: string): void {
        this.imageUrl = blobName;
    }
}

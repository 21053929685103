import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { ContextService } from './context.service';

@Directive({
  selector: '[appIfCompanyOrSuperAdmin]'
})
export class IfCompanyOrSuperAdminDirective {

  constructor(contextService: ContextService, templateRef: TemplateRef<any>, viewContainer: ViewContainerRef) {
    if (contextService.user?.isCompanyAdmin || contextService.user?.isSuperAdmin) {
      viewContainer.createEmbeddedView(templateRef);
    } else {
      viewContainer.clear();
    }
  }
}

<div class="form-group">
  <label for="{{ id + 'MinimumTaxableAmount' }}">
    {{ 'PGr_MinimumTaxableAmount' | translate }}
  </label>
  <span id="mta-{{id}}-info-icon" class="fa fa-info-circle" style="margin-left: 5px">
  </span>
  <dx-popover hideEvent="dxhoverend"
              showEvent="dxhoverstart"
              target="#mta-{{id}}-info-icon">
    <div *dxTemplate="let data of 'content'">
      <div class="mta-info-text">
        <div class="mta-info-text-1">{{ 'App_MinimumTaxableAmountInfo1' | translate }}</div>
        <div class="mta-info-text-2">{{ 'App_MinimumTaxableAmountInfo2' | translate }}</div>
      </div>
    </div>
  </dx-popover>
  <div class="flex-container">
    <div class="col-lg-6">
      <select class="form-select" [formControl]="taxableAmountFormControl"
              id="{{ id + 'TaxableAmount' }}">
        <option [value]="false">{{'PGr_WholeAmount' | translate}}</option>
        <option [value]="true">{{'PGr_ExceedingAmount' | translate}}</option>
      </select>
    </div>
    <div class="flex-field">
      <input class="form-control"
             id="{{ id + 'MinimumTaxableAmount' }}"
             name="{{ id + 'MinimumTaxableAmount' }}"
             [formControl]="minimumTaxableAmountFormControl"
             type="text" />
    </div>
  </div>
</div>

import { PrintLabel } from './print-label';
import { GenerateLabel } from './generate-label';

export class LabelSelectorStatus {

    private pIsUsingRfid: boolean;
    private pRequiresSerialsGeneration: number;
    private pSelectorItem: LabelSelectorItemMap;
    private pTotalLabelsToPrint: number;

    constructor(isUsingRfid: boolean) {
        this.pIsUsingRfid = isUsingRfid;
        this.pRequiresSerialsGeneration = 0;
        this.pSelectorItem = {};
        this.pTotalLabelsToPrint = 0;
    }

    get isUsingRfid(): boolean {
        return this.pIsUsingRfid;
    }

    get requiresSerialsGeneration(): number {
        return this.pRequiresSerialsGeneration;
    }

    get totalLabelsToPrint(): number {
        return this.pTotalLabelsToPrint;
    }

    getSelectorItem(key: string): LabelSelectorItem {
        return this.pSelectorItem[key];
    }

    getSelectorItemKeys(): string[] {
        return Object.keys(this.pSelectorItem);
    }

    setSelectorItem(key: string, selectorItem: LabelSelectorItem) {
        let currentTotalQty = 0;
        let currentGenerateQty = 0;
        let selectorTotalQty = 0;
        let selectorGenerateQty = 0;

        const currentItem = this.pSelectorItem[key];

        if (currentItem != null) {
            currentTotalQty = currentItem.totalLabels || 0;
            currentGenerateQty = currentItem.generateLabels ?
                currentItem.generateLabels.count || 0 : 0;
        }

        if (selectorItem != null) {
            selectorTotalQty = selectorItem.totalLabels || 0;
            selectorGenerateQty = selectorItem.generateLabels ?
                selectorItem.generateLabels.count || 0 : 0;
        }

        this.pSelectorItem[key] = selectorItem;
        this.pTotalLabelsToPrint += (selectorTotalQty - currentTotalQty);
        this.pRequiresSerialsGeneration += (selectorGenerateQty - currentGenerateQty);
    }
}

export class LabelSelectorItem {
    generateLabels: GenerateLabel;
    printLabels: PrintLabel;
    totalLabels: number;

    constructor() {
        this.generateLabels = null;
        this.printLabels = null;
        this.totalLabels = 0;
    }
}

class LabelSelectorItemMap {
    [key: string]: LabelSelectorItem
}

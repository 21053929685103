import { LabelInfo } from './label-info';

export class PrintLabel extends LabelInfo {
    count: number;
    serials: number[];

    constructor(productId: number, sizeRange: number, size: number) {
        super(productId, sizeRange, size);
    }

    static createPrintLabelForRfidWithSerials(productId: number, sizeRange: number, size: number, serials: number[]): PrintLabel {
        const label = new PrintLabel(productId, sizeRange, size);

        label.serials = serials || [];
        label.count = label.serials.length;

        return label;
    }

    static createPrintLabelForRfidWithQuantity(productId: number, sizeRange: number, size: number, qty: number): PrintLabel {
        const label = new PrintLabel(productId, sizeRange, size);

        label.serials = null;
        label.count = qty;

        return label;
    }

    static createPrintLabelForBarcode(productId: number, sizeRange: number, size: number, qty: number): PrintLabel {
        const label = new PrintLabel(productId, sizeRange, size);

        label.serials = null;
        label.count = qty;

        return label;
    }
}

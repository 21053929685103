import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appHideWhenNoSubmenu]'
})
export class HideWhenNoSubMenuDirective implements AfterViewInit {
    element: ElementRef;

    constructor(element: ElementRef) {
        this.element = element;
    }

    ngAfterViewInit() {
        const nativeEl = this.element.nativeElement;

        for (let i = 0; i < nativeEl.childElementCount; i++) {
            const child = nativeEl.children[i];

            if (child.nodeName === 'UL' && !child.childElementCount) {
                nativeEl.remove();
            }
        }
    }
}

import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { SearchEvent } from '../search-box/models/search-event';
import { FilterDefinition } from './models/filter-definition';
import { FilteredSearchValue } from './models/filtered-search-value';
import { TranslatePipe } from '@keystone-angular/core';

@Component({
    selector: 'app-filtered-search',
    templateUrl: './filtered-search.component.html',
    styleUrls: ['./filtered-search.component.scss']
})
export class FilteredSearchComponent implements OnInit {
    @Input()
    set filters(filters: FilterDefinition[]) {
        this._filters = filters;
        this.appliedFilters = this.buildFromInputFilters(filters);
        this.formattedFilter = this.formatSearchFilter();
    }

    get filters(): FilterDefinition[] {
        return this._filters;
    }

    @Input() placeholder: string;

    @Input() set value(value: FilteredSearchValue) {
        this._value = value;
        this.appliedFilters = this.buildFromInputFilters(this.filters);
        this.formattedFilter = this.formatSearchFilter();
        this.searchText = value && value.searchText;
    }

    get value(): FilteredSearchValue {
        return this._value;
    }

    searchText: string;

    @Output() search: EventEmitter<FilteredSearchValue> = new EventEmitter();

    formattedFilter = '';

    // tslint:disable-next-line: variable-name
    private _filters: FilterDefinition[];
    // tslint:disable-next-line: variable-name
    private _value: FilteredSearchValue;
    private appliedFilters: { [filterName: string]: any } = {};

    constructor(private translatePipe: TranslatePipe) { }

    ngOnInit(): void {
        this.placeholder = this.placeholder || this.translatePipe.transform('App_Search');
    }

    onSearch(searchResult: SearchEvent): void {
        this.searchText = searchResult.text;

        this.search.emit(new FilteredSearchValue(
            searchResult.text || '',
            Object.assign({}, this.appliedFilters || {})
        ));
    }

    onFilterChanged(filterValues: { [filterName: string]: string }): void {
        this.appliedFilters = filterValues;

        this.formattedFilter = this.formatSearchFilter();

        this.search.emit(new FilteredSearchValue(
            this.searchText || '',
            Object.assign({}, this.appliedFilters || {})
        ));
    }

    private buildFromInputFilters(filterDefinitions: FilterDefinition[]): { [filterName: string]: string } {
        if (!filterDefinitions) {
            return {};
        }

        return filterDefinitions.reduce((accumulatedFilters, currentFilterDefinition) => {
            accumulatedFilters[currentFilterDefinition.name]
                = this.value && this.value.filters && this.value.filters[currentFilterDefinition.name];

            return accumulatedFilters || {};
        }, {});
    }

    private formatSearchFilter() {
        let formattedText = '';
        let includeAnd = false;

        Object.entries(this.appliedFilters).forEach(([name, value]) => {
            const inputFilter: FilterDefinition = this._filters.find((filter) => name === filter.name);

            if (!inputFilter) {
                return;
            }

            const inputFilterTag: string = inputFilter.toFilterTag != null ?
                inputFilter.toFilterTag(value) :
                (value != null ? value.toString() : '');

            if (inputFilterTag && inputFilterTag.length) {
                formattedText += `${includeAnd ? ' AND ' : ''}${inputFilterTag}`;
                includeAnd = true;
            }
        });

        return formattedText;
    }
}

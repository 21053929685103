import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { ServerSettingsManagerService, TokenService } from '@keystone-angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationDataService, LogInResponse } from './authentication-data.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationManager {
  redirectUrl: string;

  constructor(private authenticationDataService: AuthenticationDataService,
    private tokenService: TokenService,
    private msalService: MsalService,
    private serverSettingsManagerService: ServerSettingsManagerService) { }


  settingsLoaded(): boolean {
    return this.serverSettingsManagerService.loaded;
  }

  addUser(user: any): Observable<any> {
    return this.authenticationDataService.addUser(user);
  }

  confirmUserNameChange(token: any): Observable<any> {
    return this.authenticationDataService.confirmUserNameChange(token);
  }

  changePassword(resetToken: string, password: string): Observable<any> {
    return this.authenticationDataService.changePassword({
      token: resetToken,
      newPassword: password,
      passwordConfirmation: password
    });
  }

  getPersonInfo(token: any): Observable<any> {
    return this.authenticationDataService.getPersonInfo(token);
  }

  getStores(): Observable<any> {
    return this.authenticationDataService.getStores();
  }

  logIn(credentials: any): Observable<LogInResponse> {
    return this.authenticationDataService.logIn(credentials).pipe(map(response => {
      this.tokenService.setToken(response.body.token);

      return response.body;
    }));
  }

  logOut(): Observable<void> {
    if (this.isUsingB2C()) {
      return this.msalService.logoutRedirect();
    } else {
      return this.authenticationDataService.logOut().pipe(map(() => {
        this.tokenService.removeToken();
      }));
    }
  }

  refreshToken(): Observable<any> {
    return this.authenticationDataService.refreshToken().pipe(map(response => {
      this.tokenService.setToken(response.body.token);

      return response.body;
    }));
  }

  resetPassword(emailAddress: string): Observable<any> {
    return this.authenticationDataService.resetPassword(emailAddress).pipe(map(response => response.data));
  }

  isUsingB2C(): boolean {
    return this.serverSettingsManagerService.getEnableB2C();
  }

  doSsoSilent(): Observable<AuthenticationResult> {
    return this.msalService.ssoSilent({
      account: this.msalService.instance.getAllAccounts()[0],
      scopes: this.getScopes(),
    });
  }

  acquireTokenRedirect(): Observable<void> {
    return this.msalService.acquireTokenRedirect({
      account: this.msalService.instance.getAllAccounts()[0],
      scopes: this.getScopes()
    });
  }

  getScopes(): string[] {
    let scopes = [];

    for (const resource of environment.b2c.protectedResources) {
      if (resource.scopes != null) {
        scopes = scopes.concat(resource.scopes);
      }
    }

    return scopes;
  }
}

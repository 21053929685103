import { Directive, HostListener, ElementRef, OnInit, OnDestroy, Input, Renderer2 } from '@angular/core';
import { DragAndDropService } from './drag-and-drop.service';
import { DropItem } from './core/drop-item';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[keyDroppable]'
})
export class DroppableDirective implements OnInit, OnDestroy {
  constructor(private dndService: DragAndDropService,
    private dropZone: ElementRef,
    private renderer: Renderer2) { }

  @Input() dragGroup: string;

  private dropItem: DropItem;

  ngOnInit() {
    this.dropItem = this.dndService.addDroppable(this.dragGroup, this.dropZone.nativeElement);
  }

  ngOnDestroy() {
    this.dndService.deleteDroppable(this.dropItem);
  }

  getElement(): Element {
    return this.dropZone.nativeElement;
  }

  @HostListener('dragenter', ['$event'])
  onDragEnter(event: any): void {
    if (event.defaultPrevented) {
      return;
    }

    const drag = this.dndService.getActiveDrag(this.dropItem.group);

    if (drag != null) {
      event.dataTransfer.dropEffect = drag.type;
      event.preventDefault();
    }
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: any): void {
    if (event.defaultPrevented) {
      return;
    }

    event.dataTransfer.dropEffect = 'none';

    event.preventDefault();
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: any): void {
    if (event.defaultPrevented) {
      return;
    }

    const currentDrag = this.dndService.getActiveDrag(this.dropItem.group);

    if (currentDrag?.draggedElement == null) {
      return;
    }

    const path = event.path || (event.composedPath && event.composedPath());
    const child = this.getDirectChild(this.dropZone.nativeElement, path);

    if (child === null || child === currentDrag.draggedElement ||
      !child.hasAttribute('keydraggable')) {
      return;
    }

    const shouldBePlacedBefore = this.shouldBePlacedBeforeElement(child, event.clientX);

    if (shouldBePlacedBefore) {
      if (child.previousElementSibling !== currentDrag.draggedElement) {
        if (this.dndService.dragTo(this.dropItem, child, true)) {
          this.renderer.removeChild(this.dropZone.nativeElement, currentDrag.draggedElement);
          this.renderer.insertBefore(this.dropZone.nativeElement, currentDrag.draggedElement, child);
        }
      }
    } else {
      if (child.nextElementSibling !== currentDrag.draggedElement) {
        if (this.dndService.dragTo(this.dropItem, child, false)) {
          this.renderer.removeChild(this.dropZone.nativeElement, currentDrag.draggedElement);
          this.renderer.insertBefore(this.dropZone.nativeElement, currentDrag.draggedElement, child.nextElementSibling);
        }
      }
    }

    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  onDrop(event: any): void {
    event.preventDefault();
  }

  private getDirectChild(dropZone: Element, path: Element[]): Element {
    const indexOfDropZone = path.indexOf(dropZone);

    if (indexOfDropZone >= 1) {
      return path[indexOfDropZone - 1];
    } else {
      return null;
    }
  }

  private shouldBePlacedBeforeElement(element: Element, eventX: number): boolean {
    const bounds = element.getBoundingClientRect();

    return (eventX < bounds.left + bounds.width / 2);
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'key-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
    @Input() id = '';
    @Input() images: string[];

    private activeImage: number;
    private emptyImage: string;

    ngOnInit(): void {
        this.activeImage = 0;
        this.emptyImage = '/images/shop-placeholder.png';
    }

    nextImage(): void {
        if (this.images && this.images.length) {
            const imageCount = this.images.length;

            if (imageCount) {
                this.activeImage = (this.activeImage + 1) % imageCount;
            }
        }
    }

    prevImage(): void {
        if (this.images && this.images.length) {
            const imageCount = this.images.length;

            if (imageCount) {
                this.activeImage = (this.activeImage - 1 + imageCount) % imageCount;
            }
        }
    }

    getActiveImage(): any {
        if (this.images && this.images.length) {
            return this.images[this.activeImage];
        } else {
            return this.emptyImage;
        }
    }

    getImageCount(): number {
        return (this.images && this.images.length) ? this.images.length : 0;
    }
}

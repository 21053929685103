import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TokenWrapperService } from '../../../authentication/token-wrapper.service';

export interface UploadedFileData<T> {
    file: File;
    response: T;
}

@Component({
    providers: [{
        multi: true,
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FileUploadComponent)
    }],
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements ControlValueAccessor, OnInit {
    @Input() accept: string[];
    @Input() file: File;
    @Input() isDisabled: boolean;
    @Input() selectButtonText: string;
    @Input() uploadUrl: string;

    @Output() fileUploaded = new EventEmitter<UploadedFileData<any>>();
    @Output() fileUploadError = new EventEmitter();
    @Output() fileUploadStarted = new EventEmitter();
    @Output() isFileUploadInProgress: boolean;

    onChange: (file: File) => void;
    onTouched: () => void;
    token: string;

    constructor(private tokenService: TokenWrapperService) { }

    ngOnInit(): void {
      this.tokenService.getToken().subscribe(result => {
        this.token = result;
      });
    }

    onFileUploaded(eventData: any): void {
        const response: any = JSON.parse(eventData.request.responseText);

        this.file = eventData.file;
        this.isFileUploadInProgress = false;

        this.onChange(this.file);
        this.fileUploaded.emit({
            file: this.file,
            response
        });
    }

    onFileUploadError() {
        this.file = null;
        this.isFileUploadInProgress = false;

        this.onChange(this.file);
        this.fileUploadError.emit();
    }

    onFileUploadStarted() {
        this.isFileUploadInProgress = true;

        this.fileUploadStarted.emit();
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    registerOnChange(onChange: (file: File) => void): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    writeValue(file: File): void {
        this.file = file;
    }
}

import { LabelSelectorStatus, LabelSelectorItem } from '../models/label-selector-status';
import { InventorySizeRange } from '../../home/product/models/inventory-size-range';
import { InventorySize } from '../../home/product/models/inventory-size';
import { PrintLabel } from '../models/print-label';

interface ProductSelectorInfo {
    productId: number;
    items: ProductSelectorInfoItemMap;
}

interface ProductSelectorInfoItemMap {
    [key: string]: ProductSelectorInfoItem;
}

interface ProductSelectorInfoItem {
    sizeRange: number;
    size: number;
    maxQty: number;
}

export class ProductLabelsSelector {

    private productInfo: ProductSelectorInfo;
    private selectorStatus: LabelSelectorStatus;

    constructor() {
        this.productInfo = { productId: null, items: {} };
        this.selectorStatus = null;
    }

    initialize(product: any, usingRfid: boolean): boolean {
        if (product != null) {
            this.productInfo.productId = product.productId;
            this.productInfo.items = this.buildProductSelectorInfoItems(product);

            this.selectorStatus = this.buildSelectorStatus(this.productInfo, usingRfid);

            return true;
        }

        return false;
    }

    getCurrentStatus(): LabelSelectorStatus {
        return this.selectorStatus;
    }

    getTotalItems(sizeRange: number, size: number): number {
        const key = this.makeKey(this.productInfo.productId, sizeRange, size);

        return this.productInfo.items[key].maxQty;
    }

    getTotalLabelsToPrint(sizeRange: number, size: number): number {
        const key = this.makeKey(this.productInfo.productId, sizeRange, size);

        return this.selectorStatus.getSelectorItem(key).totalLabels;
    }

    setTotalLabelsToPrint(sizeRange: number, size: number, qty: number): void {
        const itemKey = this.makeKey(this.productInfo.productId, sizeRange, size);
        const infoItem = this.productInfo.items[itemKey];
        const usingRfid = this.selectorStatus.isUsingRfid;

        const selectorItem = new LabelSelectorItem();

        selectorItem.generateLabels = null;
        selectorItem.printLabels = this.createItemPrintLabel(infoItem, this.productInfo.productId, usingRfid, qty);
        selectorItem.totalLabels = qty;

        this.selectorStatus.setSelectorItem(itemKey, selectorItem);
    }

    setUsingRfid(usingRfid: boolean): void {
        this.selectorStatus = this.buildSelectorStatus(this.productInfo, usingRfid);
    }

    private buildProductSelectorInfoItems(product: any): ProductSelectorInfoItemMap {
        const productSelectorInfoItems: ProductSelectorInfoItemMap = {};

        product.sizeRanges.forEach((sizeRange: InventorySizeRange) =>
            sizeRange.sizes.forEach((size: InventorySize) => {
                const key = this.makeKey(product.productId, sizeRange.id, size.index);

                productSelectorInfoItems[key] = {
                    sizeRange: sizeRange.id,
                    size: size.index,
                    maxQty: size.quantity > 0 ? size.quantity : 0
                };
            })
        );

        return productSelectorInfoItems;
    }

    private buildSelectorStatus(product: ProductSelectorInfo, usingRfid: boolean): LabelSelectorStatus {
        const selectorStatus = new LabelSelectorStatus(usingRfid);

        Object.keys(product.items).forEach((itemKey: string) => {
            const infoItem = product.items[itemKey];
            const total = infoItem.maxQty;
            const selectorItem = new LabelSelectorItem();

            selectorItem.generateLabels = null;
            selectorItem.printLabels = this.createItemPrintLabel(infoItem, this.productInfo.productId, usingRfid, total);
            selectorItem.totalLabels = total;

            selectorStatus.setSelectorItem(itemKey, selectorItem);
        });

        return selectorStatus;
    }

    private createItemPrintLabel(infoItem: ProductSelectorInfoItem, productId: number,
                                 isUsingRfid: boolean, qty: number): PrintLabel {
        if (isUsingRfid) {
            return PrintLabel.createPrintLabelForRfidWithQuantity(productId, infoItem.sizeRange, infoItem.size, qty);
        } else {
            return PrintLabel.createPrintLabelForBarcode(productId, infoItem.sizeRange, infoItem.size, qty);
        }
    }

    private makeKey(productId: number, sizeRange: number, size: number): string {
        return `${productId}-${sizeRange}-${size}`;
    }
}

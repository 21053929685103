import { Injectable } from '@angular/core';
import { TokenService } from '@keystone-angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ContextService } from '../../../framework/auth/context.service';
import { ApplicationInsightsService } from '../../../framework/logging/application-insights.service';
import { UserManager } from '../../admin/user/user-manager.service';
import { CompanyPickerDataService } from './company-picker-data.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyPickerManagerService {
    constructor(private applicationInsightsService: ApplicationInsightsService,
                private companyPickerDataService: CompanyPickerDataService,
                private contextService: ContextService,
                private tokenService: TokenService,
                private userManager: UserManager) { }

    changeCompanyStore(newCompanyId: number, newStoreId: number): Observable<void> {
        const user = this.contextService.user;

      return this.companyPickerDataService.setCurrentCompanyStore(newCompanyId, newStoreId).pipe(map(response => {
          if (newCompanyId === user.companyId) {
            // clear stocks filter
            const gridSettingsSellThrough = JSON.parse(localStorage.getItem(environment.sellThroughReportStorage));

            if (gridSettingsSellThrough) {
              gridSettingsSellThrough.fields[5].filterValues = [];
              localStorage.setItem(environment.sellThroughReportStorage, JSON.stringify(gridSettingsSellThrough));
            }

            // clear stores filter
            const gridSettingsSales = JSON.parse(localStorage.getItem(environment.salesReportStorage));

            if (gridSettingsSales) {
              gridSettingsSales.fields[4].filterValues = [];
              localStorage.setItem(environment.salesReportStorage, JSON.stringify(gridSettingsSales));
            }
          } else {
            localStorage.removeItem(environment.sellThroughReportStorage);
            localStorage.removeItem(environment.sellThroughReportDatesStorage);
            localStorage.removeItem(environment.salesReportStorage);
            localStorage.removeItem(environment.salesReportDatesStorage);
            localStorage.removeItem(environment.topProductStorage);
            localStorage.removeItem(environment.topProductDatesStorage);
          }

          if (response && response.token && response.permissions) {
            this.tokenService.setToken(response.token);
          }
      }));
    }

    resetFilters(): Observable<void> {
      return this.userManager.setUserSettings({
        dasboardFilter_Stores: null,
        dasboardFilter_Stocks: null
      });
    }

    changeStock(newStockId: number): Observable<void> {
        return this.companyPickerDataService.setCurrentStock(newStockId).pipe(map(response => {
            if (response && response.token) {
                this.tokenService.setToken(response.token);
            }
        }));
    }
}

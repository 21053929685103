import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { ContextService } from '../auth/context.service';
import { LocalisationService } from '@keystone-angular/core';

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe extends DatePipe implements PipeTransform {
    static API_IANA_ZONE_NAME = 'Europe/Oslo';

    constructor(@Inject(LOCALE_ID) locale: string,
                private contextService: ContextService,
                private localisationService: LocalisationService) {
        super(locale);
    }

    transform(value: any, format: string = 'dd.MM.yyyy HH.mm'): any {
        if (!value) return;

        if (this.isMilliseconds(value)) {
            value = parseInt(value, 10);
        }

        const convertedDate = this.localisationService.toLocalDate(this.contextService.user?.regionTimeZone, new Date(value));

        return super.transform(convertedDate, format);
    }

    private isMilliseconds(value: any): boolean {
        return !isNaN(value) && !isNaN(parseInt(value, 10));
    }
}

import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localTime'
})
export class LocalTimePipe extends DatePipe implements PipeTransform {
    transform(value: any, format: string): any {

        if (!value) {
            return '';
        }

        format = 'HH.mm';

        return super.transform(value, format);
    }
}

import { PipeTransform, Pipe } from '@angular/core';
import { filter } from 'lodash-es';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(value: any, filterBy: any) {
        return filter(value, filterBy);
    }
}

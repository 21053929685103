import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContextService } from '../../../framework/auth/context.service';
import { ApplicationInsightsService } from '../../../framework/logging/application-insights.service';
import { ScopeType, User, UserType } from './models/user';
import { GetUserResponse, UserDataService, UpdateStoreAccessResponse } from './user-data.service';
import { ChangeUserType } from './models/change-user-type';
import { ChangeIntegrationUserScope } from './models/change-integration-user-scope';
import { SystemRoleIds } from '../system-role-ids';

@Injectable({
  providedIn: 'root'
})
export class UserManager {
  // tslint:disable-next-line: variable-name
  _userStore: User[] = [];
  // TODO: Properly type the following properties
  // tslint:disable-next-line: variable-name
  _storeStore: any[] = [];
  // tslint:disable-next-line: variable-name
  _brandStore: any[] = [];
  // tslint:disable-next-line: variable-name
  _userByStore: any[] = [];

  private readonly companyAdminRoleId = SystemRoleIds.DefaultCompanyApikeyRole;

  constructor(private applicationInsightsService: ApplicationInsightsService,
    private contextService: ContextService,
    private userDataService: UserDataService) { }

  _removeUser(userId: string): void {
    for (let x = 0; x < this._userStore.length; x++) {
      if (this._userStore[x].id === userId) {
        this._userStore.splice(x, 1);
        break;
      }
    }
  }

  _loadAllStores() {
    return this.userDataService.getStores()
      .subscribe((storesData) => {
        storesData.forEach((storeData) => {
          this._storeStore.push({
            storeId: storeData.storeId,
            name: storeData.name
          });
        });

        return this._storeStore;
      });
  }

  _loadAllBrands() {
    return this.userDataService.getBrands()
      .subscribe((brandsData) => {
        brandsData.forEach((brandData) => {
          this._brandStore.push({
            brandId: brandData.brandId,
            name: brandData.name
          });
        });

        return this._brandStore;
      });
  }

  add(user: User): Observable<string> {
    if (!user) {
      return null;
    }
    const model = user.getDataForAdd();
    return this.userDataService.addUser(model)
      .pipe(map((userId) => {
        if (userId) {
          this._userStore.push(Object.assign(user, { id: userId }));

          return userId;
        } else {
          return null;
        }
      }));
  }

  addUserToUserStore(user: User) {
    this._userStore.push(user);
  }

  changeUserType(newUserType: ChangeUserType): Observable<User> {
    return this.userDataService.changeUserType(newUserType)
      .pipe(map(() => {
        const user = this.getUserFromStore(newUserType.id);
        const newStoresAccess = [];
        user.userType = newUserType.userType;

        user.storesAccess.forEach((storeAccess) => {
          if (storeAccess.hasAccessToPos) {
            storeAccess.roleId = null;
            storeAccess.roleName = null;
            newStoresAccess.push(storeAccess);
          }
        });

        user.storesAccess = newStoresAccess;

        this.updateUserInUserStore(user);

        return user;
      }));
  }

  changeIntegrationUserScope(newIntegrationUserScope: ChangeIntegrationUserScope): Observable<User> {
    return this.userDataService.changeIntegrationUserScope(newIntegrationUserScope)
      .pipe(map(() => {
        const user = this.getUserFromStore(newIntegrationUserScope.id);
        const newStoresAccess = [];
        user.companyRoleId = newIntegrationUserScope.scope === ScopeType.Company ? this.companyAdminRoleId : null;

        user.storesAccess.forEach((storeAccess) => {
          if (storeAccess.hasAccessToPos) {
            storeAccess.roleId = null;
            storeAccess.roleName = null;
            newStoresAccess.push(storeAccess);
          }
        });

        user.storesAccess = newStoresAccess;

        this.updateUserInUserStore(user);

        return user;
      }));
  }

  checkIfExists(email: string): Observable<boolean> {
    return this.userDataService.checkIfExists(email);
  }

  checkIfExistsInUserStore(userId: string): boolean {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this._userStore.length; i++) {
      if (this._userStore[i].id === userId) {
        return true;
      }
    }

    return false;
  }

  createDefaultPassword(userId: string): Observable<string> {
    return this.userDataService.createDefaultPassword(userId)
      .pipe(map((newPassword) => {
        return newPassword;
      }));
  }

  delete(userId: string): Observable<boolean> {
    return this.userDataService.deleteUser(userId)
      .pipe(map((deleted) => {
        if (deleted) {
          this.applicationInsightsService.logEvent({
            name: 'UserDeleted'
          }, { user: userId });
          this._removeUser(userId);
        }

        return deleted;
      }));
  }

  get(userId: string): Observable<GetUserResponse> {
    return this.userDataService.getUser(userId)
      .pipe(map((data) => {
        if (!data) {
          return null;
        }
        const model: GetUserResponse = {
          roles: data.roles,
          stores: data.stores,
          user: Object.assign(new User(), data.user)
        };

        if (this.checkIfExistsInUserStore(model.user.id)) {
          this.updateUserInUserStore(model.user);
        } else {
          this.addUserToUserStore(model.user);
        }

        return model;
      }));
  }

  getAllBrands() {
    if (this._brandStore && this._brandStore.length > 0) {
      return this._brandStore;
    } else {
      return this._loadAllBrands();
    }
  }

  getAllStores() {
    if (this._storeStore && this._storeStore.length > 0) {
      return this._storeStore;
    } else {
      return this._loadAllStores();
    }
  }

  getByEmail(email: string): Observable<User> {
    return this.userDataService.getUserByEmail(email);
  }

  getUsersByStore(): Observable<User[]> {
    return this.userDataService.getUsersByStore()
      .pipe(map((usersData) => {
        this._userByStore = [];

        usersData.forEach((userData) => {
          this._userByStore.push(userData);
        });

        return this._userByStore;
      }));
  }

  getUserFromStore(userId: string) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this._userStore.length; i++) {
      if (this._userStore[i].id === userId) {
        return this._userStore[i];
      }
    }

    return null;
  }

  getUserSetting(key: string): Observable<any> {
    return this.contextService.user?.settings && this.contextService.user?.settings?.length > 0
      ? of(this.getFromUserSettings(key))
      : this.loadUserSettings().pipe(map(() => this.getFromUserSettings(key)));
  }

  loadAll(): Observable<User[]> {
    return this.userDataService.getUsers()
      .pipe(map((usersData) => {
        this._userStore = [];

        usersData.forEach((user) => {
          this._userStore.push(user);
        });

        return this._userStore;
      }));
  }

  loadIntegrationUsers(): Observable<User[]> {
    return this.userDataService.getIntegrationUsers()
      .pipe(map((usersData) => {
        this._userStore = [];

        usersData.forEach((user) => {
          this._userStore.push(user);
        });

        return usersData;
      }));
  }

  refresh() {
    this._userStore = [];
    this._storeStore = [];
    this._brandStore = [];

    return this.loadAll();
  }

  search(searchFilter: any = '', roleFilter: string = '', currentStoreOnly: boolean = false) {
    let filteredUsers : User[] = [];

    const keywords = searchFilter.toLowerCase().trim();

    const phoneNumber = searchFilter.split(' ').join('');

    filteredUsers = this._userStore.filter(u => u.userName && u.userName.toLowerCase().indexOf(keywords) !== -1 ||
      u.firstName && u.firstName.toLowerCase().indexOf(keywords) !== -1 ||
      u.lastName && u.lastName.toLowerCase().indexOf(keywords) !== -1 ||
      u.firstName && u.lastName &&
      (u.firstName.toLowerCase().trim() + ' ' + u.lastName.toLowerCase().trim())
        .indexOf(keywords) !== -1 ||
      u.displayName && u.displayName.toLowerCase().indexOf(keywords) !== -1 ||
      u.phone && u.phone.split(' ').join('').indexOf(phoneNumber) > -1 ||
      u.email && u.email.indexOf(keywords) > -1);

    if (currentStoreOnly) {
      filteredUsers = filteredUsers.filter((u: User) => {
        return u.storesAccess.find(s => s.storeId === this.contextService.user?.storeId) !== undefined;
      });
    }

    if (!this.contextService.user?.isCompanyAdmin && !this.contextService.user?.isSuperAdmin) {
      filteredUsers = filteredUsers.filter(u => u.userType !== UserType.CompanyAdmin);
    }


    if (roleFilter != null && roleFilter.length > 0) {
      filteredUsers = filteredUsers.filter(u => u.userType === UserType.User && this.hasRole(u, roleFilter));
    }

    return filteredUsers;
  }

  hasRole(user: User, roles: string) {
    return user.storesAccess.find(sa => roles.includes(sa.roleName));
  }

  sendInvitation(id: string) {
    return this.userDataService.sendInvitation(id).pipe(map(response => {
      return response;
    }));
  }

  setUserSettings(settings: { [key: string]: any }): Observable<any> {
    if (!this.contextService.user?.settings) {
      this.loadUserSettings().pipe(map(() => {
        this.contextService.setUserSettings(Object.assign(this.contextService.user?.settings, settings));
        const userSettings = Object.keys(settings).map(key => ({ key, value: settings[key] }));

        return this.userDataService.updateUserSettings(userSettings);
      }));
    } else {
      this.contextService.setUserSettings(Object.assign(this.contextService.user?.settings, settings));
      const userSettings = Object.keys(settings).map(key => ({ key, value: settings[key] }));

      return this.userDataService.updateUserSettings(userSettings);
    }
  }

  update(user: User): Observable<UpdateStoreAccessResponse> {
    const model = user.getData();

    return this.userDataService.updateUser(model)
      .pipe(map((result) => {
        user.updateStoresAccessAfterPersonUpdated(result.addedStoreAccessIds);

        const existsInUserStore = this.checkIfExistsInUserStore(user.id);

        if (existsInUserStore) {
          this.updateUserInUserStore(user);
        } else {
          this.addUserToUserStore(user);
        }

        return result;
      }));
  }

  updateUserInUserStore(user: User): User {
    for (let i = 0; i < this._userStore.length; i++) {
      if (this._userStore[i].id === user.id) {
        this._userStore[i] = user;
        break;
      }
    }
    return user;
  }

  getApiKey(userGuid: string, storeId: number) {
    return this.userDataService.getApiKey(userGuid, storeId);
  }

  createApiKey(userGuid: string, storeId: number) {
    return this.userDataService.createApiKey(userGuid, storeId);
  }

  revokeApiKey(userGuid: string, storeId: number) {
    return this.userDataService.revokeApiKey(userGuid, storeId);
  }

  private getFromUserSettings(key: string): any {
    if (this.contextService.user?.settings.hasOwnProperty(key)) {
      return this.contextService.user?.settings[key];
    }

    if (this.contextService.user?.settings.defaults && this.contextService.user?.settings.defaults.hasOwnProperty(key)) {
      if (this.contextService.user?.settings.defaults[key] === 'True') {
        return true;
      }

      if (this.contextService.user?.settings.defaults[key] === 'False') {
        return false;
      }

      return this.contextService.user?.settings.defaults[key];
    }

    return null;
  }

  private loadUserSettings(): Observable<any> {
    return this.userDataService.getUserSettings().pipe(map(data => {
      this.contextService.setUserSettings(Object.assign(this.contextService.user?.settings, data));
    }));
  }
}

export class DropItem {
    get group(): string {
        return this.diGroup;
    }

    get element(): Element {
        return this.diElement;
    }

    private diElement: Element;
    private diGroup: string;

    constructor(groupName: string, element: Element) {
        this.diElement = element;
        this.diGroup = groupName;
    }
}

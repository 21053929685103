import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
    @Input() id = '';
    @Input() disabled: boolean;

    @Input()
    get checked(): boolean {
        return this._checked;
    }

    set checked(newValue: boolean) {
        this._checked = newValue || false;
    }

    // tslint:disable-next-line: no-output-native
    @Output() blur: EventEmitter<void> = new EventEmitter();
    @Output() checkedChange: EventEmitter<boolean> = new EventEmitter();

    focused: boolean;

    // tslint:disable-next-line: variable-name
    private _checked: boolean;

    handleBlur() {
        this.setFocused(false);
        this.blur.emit();
    }

    handleChange(event: any) {
        this.checkedChange.emit(event.target.checked || false);
    }

    setFocused(focused: boolean) {
        this.focused = focused;
    }
}

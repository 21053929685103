import { LabelInfo } from './label-info';

export class GenerateLabel extends LabelInfo {
    count: number;

    constructor(productId: number, sizeRange: number, size: number, count: number) {
        super(productId, sizeRange, size);

        this.count = count;
    }
}

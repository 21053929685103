import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { TranslatePipe } from '@keystone-angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, of, BehaviorSubject } from 'rxjs';
import { concatAll, map } from 'rxjs/operators';
import { ContextService } from '../../framework/auth/context.service';
import { ApplicationInsightsService } from '../../framework/logging/application-insights.service';
import { PrinterManager } from '../../home/admin/printer/printer-manager.service';
import { PrintLabelsRequest } from '../models/print-labels-request';
import { PrintLabelsDialogCloseEvent } from '../models/print-labels-dialog-close-event';
import { PrintSerialsRequest } from '../models/print-serials-request';
import { PrintDataService } from '../services/print-data.service';
import { UserManager } from '../../home/admin/user/user-manager.service';
import { LabelSelectorStatus } from '../models/label-selector-status';
import { GenerateLabel } from '../models/generate-label';
import { PrintModalType } from '../models/print-modal-type';
import { SettingsDataService } from '../../shared/settings-old/services/settings-data.service';

interface SerialsGenerationData {
  labelSelectorStatus: LabelSelectorStatus;
  orderId: number;
  productId?: number;
  startTimestamp: number;
}

interface PrinterSummary {
  printerName: string;
  printAgentStatus: string;
  printerStatus: string;
}

@Component({
  selector: 'app-print-labels-dialog',
  templateUrl: './print-labels-dialog.component.html',
  styleUrls: ['./print-labels-dialog.component.scss']
})
export class PrintLabelsDialogComponent implements OnInit, OnDestroy {
  @Input() orderId: number;
  @Input() title: string | ((printer: string, labels: number) => string);
  @Input() type: PrintModalType;
  @Input() toPrint: any;
  @Input() subType?: string;
  @Input() allowRfidPrint?: boolean;
  @Input() printReceivedItemsOnly?: boolean;
  @Input() hideSizesWithoutGtinOrSku: boolean;

  // tslint:disable-next-line: no-output-native
  @Output() close: EventEmitter<PrintLabelsDialogCloseEvent> = new EventEmitter();

  displayNotification$: BehaviorSubject<boolean>;
  printerSummary$: BehaviorSubject<PrinterSummary>;
  totalLabelsToPrint$: BehaviorSubject<number>;

  barcodePrinter: any;
  canPrintRfid: boolean;
  generatingSerials: boolean;
  processingPrint: boolean;
  rfidPrinter: any;
  selectedPrinter: string;
  serialsGenerationData: SerialsGenerationData;

  private labelsToPrint: LabelSelectorStatus;
  private settingsLoaded: boolean;

  constructor(private applicationInsightsService: ApplicationInsightsService,
    private bsModalRef: BsModalRef, private contextService: ContextService,
    private settingsConfigurationDataService: SettingsDataService,
    private printerManager: PrinterManager, private printDataService: PrintDataService,
    private translatePipe: TranslatePipe, private userManager: UserManager) {
    this.canPrintRfid = false;
    this.generatingSerials = false;
    this.labelsToPrint = null;
    this.processingPrint = false;
    this.selectedPrinter = '';
    this.settingsLoaded = false;
    this.serialsGenerationData = null;

    this.displayNotification$ = new BehaviorSubject(this.mustShowSerialsGenerationNotification());
    this.printerSummary$ = new BehaviorSubject(this.getPrinterSummary(null));
    this.totalLabelsToPrint$ = new BehaviorSubject(this.getTotalLabelsToPrint());
  }

  ngOnInit() {
    forkJoin([
      this.userManager.getUserSetting('defaultPrinterId')
        .pipe(map(printerId => printerId
          ? this.printerManager.getPrinterWithOnlinePath(printerId)
          : of(null)), concatAll()),
      this.userManager.getUserSetting('defaultRfidPrinterId')
        .pipe(map(rfidPrinterId => rfidPrinterId
          ? this.printerManager.getPrinterWithOnlinePath(rfidPrinterId)
          : of(null)), concatAll()),
      this.userManager.getUserSetting(`rfidPrinterIsSelected_${this.contextService.user?.stockId}`),
      this.settingsConfigurationDataService.checkIfRfidIsEnabled()
    ]).subscribe(data => {
      this.barcodePrinter = data[0];
      this.rfidPrinter = data[1];
      this.selectedPrinter = (!!data[2]) ? 'rfid' : 'barcode';
      this.canPrintRfid = data[3];

      if ((this.canPrintRfid && this.allowRfidPrint && this.rfidPrinter && this.isRfidPrinterSelected()) || !this.barcodePrinter) {
        this.selectedPrinter = 'rfid';
      } else if (!this.canPrintRfid || !this.allowRfidPrint || !this.rfidPrinter || this.isRfidPrinterSelected()) {
        this.selectedPrinter = 'barcode';
      }

      this.settingsLoaded = true;

      this.printerSummary$.next(this.getPrinterSummary(this.isRfidPrinterSelected() ? this.rfidPrinter : this.barcodePrinter));
      this.displayNotification$.next(this.mustShowSerialsGenerationNotification());
    });
  }

  ngOnDestroy() {
    this.displayNotification$.complete();
    this.printerSummary$.complete();
    this.totalLabelsToPrint$.complete();
  }

  canPrint(): boolean {
    return this.canPrintWithBarcodePrinter() || this.canPrintWithRfidPrinter();
  }

  canPrintWithBarcodePrinter(): boolean {
    return this.barcodePrinter != null;
  }

  canPrintWithRfidPrinter(): boolean {
    return this.canPrintRfid && this.allowRfidPrint && this.rfidPrinter;
  }

  changePrinter(selectedPrinter: string) {
    this.selectedPrinter = selectedPrinter;

    this.userManager.setUserSettings({
      [`rfidPrinterIsSelected_${this.contextService.user?.stockId}`]: this.isRfidPrinterSelected()
    }).subscribe(() => { });

    this.printerSummary$.next(this.getPrinterSummary(this.isRfidPrinterSelected() ? this.rfidPrinter : this.barcodePrinter));
    this.displayNotification$.next(this.mustShowSerialsGenerationNotification());
  }

  closeModal(requestPerformed: boolean, requestSucceeded?: boolean, errorMessage?: string) {
    this.bsModalRef.hide();
    this.close.emit(new PrintLabelsDialogCloseEvent(requestPerformed,
      requestSucceeded, errorMessage));
  }

  getTitle(): string {
    if (this.title instanceof Function) {
      return this.title(this.selectedPrinter, this.getTotalLabelsToPrint());
    } else {
      return this.title || '';
    }
  }

  getTotalLabelsToPrint(): number {
    return this.labelsToPrint != null ? this.labelsToPrint.totalLabelsToPrint || 0 : 0;
  }

  hasSingleSelectedOrderLine(): boolean {
    return this.toPrint != null && this.toPrint.length === 1;
  }

  isBarcodePrinterSetUp(): boolean {
    return !this.settingsLoaded || this.barcodePrinter != null;
  }

  isProductModal(): boolean {
    return this.type === PrintModalType.Product;
  }

  isPurchaseOrderModal(): boolean {
    return this.type === PrintModalType.PurchaseOrder;
  }

  isRfidPrinterSelected(): boolean {
    return this.selectedPrinter === 'rfid';
  }

  isRfidPrinterSetUp(): boolean {
    return !this.settingsLoaded || !(this.canPrintRfid && this.rfidPrinter == null);
  }

  print() {
    if (this.type === PrintModalType.Product || this.type === PrintModalType.PurchaseOrder) {
      this.processingPrint = true;

      if (this.labelsToPrint.requiresSerialsGeneration) {
        this.startGenerating(this.labelsToPrint, this.orderId);
      } else {
        this.startPrinting(this.labelsToPrint, this.isRfidPrinterSelected());
      }
    } else {
      this.closeModal(false);
    }

    this.displayNotification$.next(this.mustShowSerialsGenerationNotification());
  }

  onGenerationCompleted(generatedSerials: number[]) {
    if (generatedSerials.length > 0) {
      this.startPrintingGeneratedSerials(generatedSerials);
    } else {
      this.processingPrint = false;
      this.closeModal(false);
    }
  }

  updateLabelsToPrint(labelsToPrint: LabelSelectorStatus) {
    this.labelsToPrint = labelsToPrint;

    this.displayNotification$.next(this.mustShowSerialsGenerationNotification());
    this.totalLabelsToPrint$.next(this.getTotalLabelsToPrint());
  }

  private getPrinterSummary(printer: any): PrinterSummary {
    const printerSummary: PrinterSummary = {
      printerName: this.translatePipe.transform('App_Unknown'),
      printAgentStatus: 'D',
      printerStatus: 'D'
    };

    if (printer == null) {
      return printerSummary;
    }

    if (printer.name != null && printer.name.length > 0) {
      printerSummary.printerName = printer.name;
    }

    if (printer.printAgentStatus != null && printer.printAgentStatus.length > 0) {
      printerSummary.printAgentStatus = printer.printAgentStatus;
    }

    if (printer.status != null && printer.status.length > 0) {
      printerSummary.printerStatus = printer.status;
    }

    return printerSummary;
  }

  private mapToGenerateSerialsRequestData(labelSelectorStatus: LabelSelectorStatus): GenerateLabel[] | null {
    const labelsToGenerate = [];
    const itemKeys = labelSelectorStatus.getSelectorItemKeys();

    itemKeys.forEach((itemKey: string) => {
      const labelData = labelSelectorStatus.getSelectorItem(itemKey).generateLabels;

      if (labelData != null) {
        labelsToGenerate.push(labelData);
      }
    });

    return labelsToGenerate.length > 0 ? labelsToGenerate : null;
  }

  private mapToPrintLabelsRequestData(labelSelectorStatus: LabelSelectorStatus, printerId: string): PrintLabelsRequest[] | null {
    const printData: PrintLabelsRequest[] = [];
    const itemKeys = labelSelectorStatus.getSelectorItemKeys();

    itemKeys.forEach((itemKey: string) => {
      const printLabels = labelSelectorStatus.getSelectorItem(itemKey).printLabels;

      if (printLabels != null && printLabels.count > 0) {
        printData.push(new PrintLabelsRequest(printerId, printLabels));
      }
    });

    return printData.length > 0 ? printData : null;
  }

  private mapToPrintSerialsRequestData(labelSelectorStatus: LabelSelectorStatus, printerId: string): PrintSerialsRequest | null {
    let serials: number[] = [];
    const itemKeys = labelSelectorStatus.getSelectorItemKeys();

    itemKeys.forEach((itemKey: string) => {
      const printLabels = labelSelectorStatus.getSelectorItem(itemKey).printLabels;

      if (printLabels != null && printLabels.count > 0) {
        serials = serials.concat(printLabels.serials);
      }
    });

    return serials.length > 0 ? new PrintSerialsRequest(printerId, serials) : null;
  }

  private mustShowSerialsGenerationNotification(): boolean {
    return this.type === PrintModalType.PurchaseOrder && this.isRfidPrinterSelected()
      && this.labelsToPrint != null && this.labelsToPrint.requiresSerialsGeneration > 0
      && !this.generatingSerials;
  }

  private startGenerating(labelsToPrint: LabelSelectorStatus, orderId: number) {
    const serialsToGenerate = this.mapToGenerateSerialsRequestData(labelsToPrint);

    this.printDataService.generateSerials(serialsToGenerate, orderId).subscribe(response => {
      this.generatingSerials = true;
      this.serialsGenerationData = {
        orderId: this.orderId,
        labelSelectorStatus: labelsToPrint,
        productId: this.toPrint.length === 1 ? this.toPrint[0].productId : null,
        startTimestamp: Date.now()
      };
    });
  }

  private startPrinting(labelsToPrint: LabelSelectorStatus, isUsingRfid: boolean): void {
    const printerId = isUsingRfid ? this.rfidPrinter.id : this.barcodePrinter.id;

    const productsRequest = isUsingRfid ?
      this.printDataService.printRfidProductLabels.bind(this.printDataService) :
      this.printDataService.printProductLabels.bind(this.printDataService);
    const poRequest = isUsingRfid ?
      this.printDataService.printRFIDLabels.bind(this.printDataService) :
      this.printDataService.printProductLabels.bind(this.printDataService);
    const printRequest = this.type === PrintModalType.Product ? productsRequest : poRequest;

    const printData = isUsingRfid && this.type === PrintModalType.PurchaseOrder ?
      this.mapToPrintSerialsRequestData(labelsToPrint, printerId) :
      this.mapToPrintLabelsRequestData(labelsToPrint, printerId);

    if (printData != null) {
      this.performRequest(printRequest, printData);
    } else {
      this.processingPrint = false;
      this.closeModal(false);
    }
  }

  private startPrintingGeneratedSerials(generatedSerials: number[]) {
    const printerId = this.rfidPrinter.id;
    const printRequest = this.printDataService.printRFIDLabels.bind(this.printDataService);
    const printData = new PrintSerialsRequest(printerId, generatedSerials);

    this.performRequest(printRequest, printData);
  }

  private performRequest(request: (data: any) => Observable<void>, data: any) {
    request(data).subscribe(() => {
      this.processingPrint = false;
      this.closeModal(true, true);
    }, (errorResult: any) => {
      let errorMessage = '';

      if (!errorResult || !errorResult.data || !errorResult.data.reason) {
        errorMessage = this.translatePipe.transform('App_PrintUnknownError');
      } else {
        switch (errorResult.data.reason) {
          case 'RfidNotSupported':
            errorMessage = this.translatePipe.transform('App_RfidPrintingNotSupported');
            break;
          case 'PrinterNotFound':
            errorMessage = this.translatePipe.transform('App_PrinterNotFound');
            break;
          case 'MissingProductInformation':
            errorMessage = this.translatePipe.transform('App_MissingProductInformation');
            break;
          default:
            errorMessage = this.translatePipe.transform('App_PrintUnknownError');
        }
      }

      this.processingPrint = false;
      this.closeModal(true, false, errorMessage);
    });
  }
}

<div class="search-box-container"
     [ngClass]="{'focused': focused}">
  <input type="text" class="search-box" #searchbox
         placeholder="{{placeholder}}"
         [(ngModel)]="value"
         (change)="handleChange($event)"
         (focus)="setFocused(true)"
         (blur)="setFocused(false)" />
  <button type="button" class="clear-search-btn"
          *ngIf="clearable && value"
          title="{{'App_ClearSearch' | translate}}"
          (click)="handleClearClick()"
          (keyup)="handleClearKeyup($event)"
          (focus)="setFocused(true)"
          (blur)="setFocused(false)">
    <i class="fa fa-times-circle" aria-hidden="true"></i>
  </button>
  <button type="button" class="search-btn"
          title="{{'App_Search' | translate}}"
          (click)="handleSearchClick()"
          (keyup)="handleSearchKeyup($event)"
          (focus)="setFocused(true)"
          (blur)="setFocused(false)">
    <i class="fa fa-search" aria-hidden="true"></i>
  </button>
</div>

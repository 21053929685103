import { DragContainer } from './drag-container';

export class Drag {
    get draggedElement(): Element {
        return this.dElement;
    }

    get group(): string {
        return this.dGroup;
    }

    get sourceContainer(): DragContainer {
        return this.dSourceContainer;
    }

    get sourceIndex(): number {
        return this.dSourceDraggableIndex;
    }

    get type(): string {
        return this.dType;
    }

    get targetContainer(): DragContainer {
        return this.dTargetContainer;
    }

    get targetIndex(): number {
        return this.dTargetIndex;
    }

    private dGroup: string;
    private dElement: Element;
    private dSourceContainer: DragContainer;
    private dSourceDraggableIndex: number;
    private dTargetContainer: DragContainer;
    private dTargetIndex: number;
    private dType: string;

    constructor(groupName: string, sourceContainer: DragContainer, draggedElement: Element, dragType: string) {
        const draggableIndex = sourceContainer.findDraggableIndex(draggedElement);

        this.dGroup = groupName;
        this.dElement = draggedElement;
        this.dSourceContainer = sourceContainer;
        this.dSourceDraggableIndex = draggableIndex;
        this.dTargetContainer = sourceContainer;
        this.dTargetIndex = draggableIndex;
        this.dType = dragType;
    }

    setTarget(targetContainer: DragContainer, targetElement: Element, insertBefore: boolean) {
        const targetElementIndex = targetContainer.findDraggableIndex(targetElement);

        this.dTargetContainer = targetContainer;
        this.dTargetIndex = this.calculateNewIndex(this.dSourceContainer, this.dSourceDraggableIndex,
            targetContainer, targetElementIndex, insertBefore);
    }

    private calculateNewIndex(oldContainer: DragContainer, oldIndex: number, newContainer: DragContainer,
                              newRefIndex: number, insertBefore: boolean): number {
        if (oldContainer !== newContainer || newRefIndex < oldIndex) {
            return insertBefore ? newRefIndex : newRefIndex + 1;
        } else {
            return insertBefore ? newRefIndex - 1 : newRefIndex;
        }
    }
}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LayoutEventNotificationService } from '@keystone-angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable()
export class UnauthorizedHttpInterceptorService implements HttpInterceptor {
    constructor(private layoutEventNotificationService: LayoutEventNotificationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.layoutEventNotificationService.notifyHttpRequestStart(request.url);

        return next.handle(request)
            .pipe(tap(_ => { }, error => {
                this.handleErrorEvent(error);
            }), finalize(() => {
                this.handleRequestFinished(request);
            }));
    }

    private handleErrorEvent(event: HttpErrorResponse): void {
        if (event.status === 401 || event.status === 403) {
            this.layoutEventNotificationService.notifyUnauthorisedAction();
        }
    }

    private handleRequestFinished(request: HttpRequest<any>): void {
        this.layoutEventNotificationService.notifyHttpRequestEnd(request.url);
    }
}

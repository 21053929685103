import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslatePipe } from '@keystone-angular/core';
import { DatePipe } from '@angular/common';

export enum DateRangeType {
    Custom,
    LastMonth,
    LastWeek,
    LastYear,
    ThisMonth,
    ThisWeek,
    ThisYear,
    Today,
    Yesterday
}

@Component({
    selector: 'app-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit{
    @Input() allowCustomRange: boolean;
    @Input() allowToday = true;
    @Input() compareWithFrom: Date;
    @Input() compareWithTo: Date;
    @Input() set dateFrom(dateFrom: Date) {
        if (this._dateFrom?.getTime() !== dateFrom?.getTime()) {
            this._dateFrom = dateFrom;

            if (this._dateTo && this._dateFrom > this._dateTo) {
                this._dateTo = this._dateFrom;
            }

            this.dateFromChange.emit(dateFrom);
        }
    }

    @Input() set dateTo(dateTo: Date) {
        if (this._dateTo?.getTime() !== dateTo?.getTime()) {
            this._dateTo = dateTo;

            if (this._dateFrom && this._dateFrom > this._dateTo) {
                this._dateFrom = this._dateTo;
            }

            this.dateToChange.emit(dateTo);
        }
    }

    @Input() initType: DateRangeType;

    @Output() dateFromChange = new EventEmitter<Date>();
    @Output() dateToChange = new EventEmitter<Date>();
    @Output() typeChange = new EventEmitter<DateRangeType>();

    datepickerOptions: any;
    DateRangeType = DateRangeType;
    type: DateRangeType;
    displayFormat = 'dd/MM/yyyy';

    // tslint:disable-next-line: variable-name
    private _bodyClickFn: (this: GlobalEventHandlers, ev: MouseEvent) => any;
    // tslint:disable-next-line: variable-name
    private _dateFrom: Date;
    // tslint:disable-next-line: variable-name
    private _dateTo: Date;

    // tslint:disable-next-line: adjacent-overload-signatures
    get dateFrom(): Date {
        return this._dateFrom;
    }

    // tslint:disable-next-line: adjacent-overload-signatures
    get dateTo(): Date {
        return this._dateTo;
    }

    constructor(private translatePipe: TranslatePipe,
                private datePipe: DatePipe) {}

    ngOnInit() {
        this.type = this.initType;
    }

    blockBody(): void {
        this._bodyClickFn = document.body.onclick;

        document.body.onclick = this.preventDefaultAndStopPropagation;
    }

    getLabel(): string {
        if (!this.dateFrom || !this.dateTo) {
            return this.translatePipe.transform('Rep_DateRangePleaseSelectDateMessage');
        }

        let label = this.datesConcatenation(this.dateFrom, this.dateTo);

        if (this.compareWithFrom && this.compareWithTo) {
            label += '  /  ';
            label += this.datesConcatenation(this.compareWithFrom, this.compareWithTo);
        }

        return label;
    }

    releaseBody(): void {
        document.body.onclick = this._bodyClickFn;
    }

    setType(type: DateRangeType): void {
        this.type = type;
        this.typeChange.emit(type);
        this.updateDates();
    }

    private datesConcatenation(dateFrom: Date, dateTo: Date): string {
        const dateFormat = 'mediumDate';

        // tslint:disable-next-line: triple-equals
        if (dateFrom.getDate() == dateTo.getDate() &&
            // tslint:disable-next-line: triple-equals
            dateFrom.getMonth() == dateTo.getMonth() &&
            // tslint:disable-next-line: triple-equals
            dateFrom.getFullYear() == dateTo.getFullYear()) {
          return this.datePipe.transform(dateFrom, dateFormat);
        }

      return `${this.datePipe.transform(dateFrom, dateFormat)} - ${this.datePipe.transform(dateTo, dateFormat)}`;
    }

    private preventDefaultAndStopPropagation(event: any) {
        event.preventDefault();
        event.stopPropagation();
    }

    private updateDates(): void {
        let today = new Date();
        let from: Date;
        let to: Date;
        switch (this.type) {
            case DateRangeType.LastMonth:
                from = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                to = new Date(new Date(today.getFullYear(), today.getMonth(), 0).setHours(23, 59, 59, 59));

                break;
            case DateRangeType.LastWeek:
                from = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6);
                to = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
                to.setHours(23, 59, 59, 59);

                break;
            case DateRangeType.LastYear:
                from = new Date(today.getFullYear() - 1, 0, 1);
                to = new Date(new Date(today.getFullYear(), 0, 0).setHours(23, 59, 59, 59));

                break;
            case DateRangeType.ThisMonth:
                from = new Date(today.getFullYear(), today.getMonth(), 1);
                to = new Date(today.setHours(23, 59, 59, 59));

                break;
            case DateRangeType.ThisWeek:
                today = new Date((new Date()).setHours(0, 0, 0, 0));
                const thisWeekDate = today.getDate() - today.getDay();

                if (today.getDay() === 0) {
                    from = new Date(new Date(today.setDate(thisWeekDate - 6)).setHours(0, 0, 0, 0));
                    to = new Date(new Date().setHours(23, 59, 59, 59));
                } else {
                    from = new Date(new Date(today.setDate(thisWeekDate + 1)).setHours(0, 0, 0, 0));
                    to = new Date(new Date().setHours(23, 59, 59, 59));
                }

                break;
            case DateRangeType.ThisYear:
                from = new Date(today.getFullYear(), 0, 1);
                to = new Date(today.setHours(23, 59, 59, 59));

                break;
            case DateRangeType.Today:
                from = new Date(today.setHours(0, 0, 0, 0));
                to = new Date(today.setHours(23, 59, 59, 59));

                break;
            case DateRangeType.Yesterday:
                const yesterday = new Date();
                yesterday.setDate(today.getDate() - 1);

                from = new Date(yesterday.setHours(0, 0, 0, 0));
                to = new Date(yesterday.setHours(23, 59, 59, 59));

                break;
            case DateRangeType.Custom:
                from = this.dateFrom;
                to = this.dateTo;
                break;
            default:
                break;
        }

        this.dateFrom = from;
        this.dateTo = to;
    }
}

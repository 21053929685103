import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route,
  RouterStateSnapshot, UrlSegment, UrlTree
} from '@angular/router';
import { TokenService } from '@keystone-angular/core';
import { environment } from '../../environments/environment';
import { ContextService } from '../framework/auth/context.service';
import { WebSocketService } from '../framework/http/web-socket.service';
import { AuthenticationManager } from './authentication-manager.service';
import { CompanyPickerManagerService } from '../home/navigation/company-picker/company-picker-manager.service';
import { AuthenticationDataService } from './authentication-data.service';
import { PermissionService } from '../framework/auth/permission.service';
import { MsalGuard } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { map, concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authenticationManager: AuthenticationManager,
    private contextService: ContextService,
    private tokenService: TokenService,
    private webSocketService: WebSocketService,
    private companyPickerManager: CompanyPickerManagerService,
    private authenticationDataService: AuthenticationDataService,
    private permissionService: PermissionService,
    private msalGuard: MsalGuard) { }

  private permissionsRetrieved = false;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    if (this.authenticationManager.isUsingB2C()) {
      return this.canActivateB2C(next, state);
    }
    else {
      return this.canActivateLegacy(next, state);
    }
  }

  canActivateB2C(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.msalGuard.canActivate(next, state).pipe(
      concatMap(canActivateResult => {
        if (canActivateResult) {
          this.connectWebsocket();
        }

        if (canActivateResult && !this.permissionsRetrieved) {
          return this.authenticationDataService.getPermissions().pipe(
            map((permissions) => {
              this.permissionService.setPermissions(permissions);
              this.permissionsRetrieved = true;

              return canActivateResult;
            })
          )
        }

        return of(canActivateResult);
      })
    );
  }

  canActivateLegacy(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const authorized = this.checkLogin(state.url);

    if (authorized) {
      this.handleRedirectStore(state);
      this.connectWebsocket();

      if (!this.permissionsRetrieved) {
        return this.authenticationDataService.getPermissions().pipe(
          map((permissions) => {
            this.permissionService.setPermissions(permissions);
            this.permissionsRetrieved = true;
            return authorized;
          })
        );
      } else {
        return of(authorized);
      };
    }
    else {
      this.tokenService.removeToken();
      return of(authorized);
    }
  }

  connectWebsocket(): void {
    if (!this.webSocketService.connected && this.contextService.user && this.contextService.user.userId) {
      this.webSocketService.connect(this.contextService.user.userId.toFixed(0));
    }
  }

  handleRedirectStore(state: RouterStateSnapshot) {
    const redirectStore = state.url.match(/;redirectStore=(\d+)-(\d+)/);
    if (redirectStore && redirectStore.length === 3) {
      const companyId = Number(redirectStore[1]);
      const storeId = Number(redirectStore[2]);
      if (!isNaN(companyId) && !isNaN(storeId) && storeId !== this.contextService.user?.storeId) {
        this.companyPickerManager
          .changeCompanyStore(companyId, storeId)
          .subscribe(() => location.assign(state.url.replace(redirectStore[0], '')));
        return of(true);
      }
    }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    this.connectWebsocket();

    if (this.authenticationManager.isUsingB2C()) {
      return this.msalGuard.canActivateChild(route, state);
    }
    else {
      return of(this.checkLogin(state.url));
    }
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    if (this.authenticationManager.isUsingB2C()) {
      return this.msalGuard.canLoad();
    }
    else {
      return of(this.checkLogin(`/${route.path}`));
    }
  }

  checkLogin(url: string): boolean {
    if (this.tokenService.hasToken()) {
      return true;
    }

    this.authenticationManager.redirectUrl = url;

    location.assign(environment.loginApp);
    return false;
  }
}

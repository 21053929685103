import { Injectable } from '@angular/core';
import { NotificationService, ServerSettingsManagerService, TranslatePipe } from '@keystone-angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  get connected(): boolean {
    return !!this.socket;
  }

  private reconnect: boolean;
  private socket: WebSocket;

  constructor(private notificationService: NotificationService,
    private serverSettingsManager: ServerSettingsManagerService,
    private translatePipe: TranslatePipe) { }

  async connect(email: string): Promise<void> {
    const address = `${this.serverSettingsManager.getWebSocketsApiPath()}/notifier`;

    if (this.socket) {
      this.reconnect = true;
      this.disconnect();
    } else {
      this.socket = new WebSocket(address);
    }

    this.socket.onmessage = (event) => {
      const segments = event.data.split('|');

      const message = segments[3] || this.translatePipe.transform('Aut_RegisteredUserSavedError', []);
      switch (segments[1]) {
        case '0':
        default:
          this.notificationService.showInfo(message);
          break;
        case '1':
          this.notificationService.showSuccess(message);
          break;
        case '2':
          this.notificationService.showWarning(message);
          break;
        case '3':
          this.notificationService.showError(message);
          break;
      }
    };

    this.socket.onopen = () => {
      this.socket.send(email);
    };

    this.socket.onclose = () => {
      this.socket = null;
      if (this.reconnect) {
        this.reconnect = false;
        this.connect(email);
      }
    };
  }

  disconnect(): void {
    switch (this.socket.readyState) {
      case this.socket.CONNECTING:
      case this.socket.OPEN:
        this.socket.close();
    }
  }
}

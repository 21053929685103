import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationManager } from './authentication-manager.service';
import { Observable, of } from 'rxjs';
import { TokenService } from '@keystone-angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenWrapperService {

  constructor(private msalService: MsalService,
              private authenticationManager: AuthenticationManager,
              private tokenService: TokenService) { }

  getToken(): Observable<string> {
    if (this.authenticationManager.isUsingB2C()) {
      return this.msalService.acquireTokenSilent({
        scopes: this.authenticationManager.getScopes(),
        account: this.msalService.instance.getAllAccounts()[0]
      }).pipe(map((result => {
        return `Bearer ${result.accessToken}`;
      })));
    }

    if (this.tokenService.hasToken()) {
      return of(`Token ${this.tokenService.getToken()}`);
    }

    return of(null);
  }
}

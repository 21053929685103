<div class="key-carousel-container">
    <div class="key-carousel">
        <div>
            <button type="button" class="key-carousel-btn key-carousel-btn-left" *ngIf="getImageCount() > 1" (click)="prevImage()">
                <i class="fa fa-angle-left"></i>
            </button>
            <img [src]="getActiveImage()" />
            <button type="button" class="key-carousel-btn key-carousel-btn-right" *ngIf="getImageCount() > 1" (click)="nextImage()">
                <i class="fa fa-angle-right"></i>
            </button>
        </div>
    </div>
</div>

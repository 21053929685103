<div class="dropzone-container">
  <div class="dropzone-picture main-picture"
       [ngClass]="{ 'no-picture': !hasImages(), 'disabled': isDisabled }">
    <div [ngClass]="{ 'dropzone-image': hasImages(),
                          'dropzone-no-image': !hasImages() }">
      <div *ngIf="hasImages()">
        <img [src]="getActiveImage()" />
      </div>
      <div *ngIf="!hasImages() && !isDisabled">
        <div class="dropzone-add-image">
          <i class="fa fa-image dropzone-add-image-icon"></i>
          <span class="dropzone-add-image-title">{{'App_DropzoneTitle' | translate}}</span>
          <span class="dropzone-add-image-subtitle">{{'App_DropzoneSubtitle' | translate}}</span>
        </div>
      </div>
      <div class="dropzone" #dropzone>
        <div *ngIf="!isDisabled && hasImages()"
             class="dropzone-actions-bar main-picture">
          <div class="dropzone-action-btn main-action">
            <button type="button" id="dropzone_move_left" *ngIf="images.length > 1" (click)="selectPreviousImage()">
              <i class="fa fa-caret-left"></i>
            </button>
            <span  *ngIf="images.length > 1">{{'App_MainPicture' | translate}}</span>
            <button type="button" id="dropzone_move_right" *ngIf="images.length > 1" (click)="selectNextImage()">
              <i class="fa fa-caret-right"></i>
            </button>
          </div>
          <div class="dropzone-action-btn">
            <button type="button" id="dropzone_delete_0" (click)="removeImage(0, $event)">
              <i class="fa fa-trash-alt"></i><span>{{'App_Delete' | translate}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dropzone-thumbnail-container" keyDroppable dragGroup="image_dropzone">
    <ng-container *ngFor="let image of images | slice:1; let i = index">
      <div class="dropzone-thumbnail" keyDraggable [id]="'dropzone_thumbnail_' + i"
           dragGroup="image_dropzone"
           [dragDisabled]="!thumbnailsCanBeSorted()"
           (dragFinished)="sortThumbnail($event)">
        <div class="dropzone-thumbnail-drag" keyDragHandle [id]="'dropzone_thumbnail_handle_' + i"
             [ngClass]="{ 'no-drag': !thumbnailsCanBeSorted() }">
          <i class="fa fa-ellipsis-v"></i>
        </div>
        <div class="dropzone-picture thumbnail-picture" 
             [ngClass]="{ 'disabled': isDisabled }">
          <div class="dropzone-image">
            <div>
              <div *ngIf="!isDisabled" class="dropzone-actions-bar thumbnail-picture">
                <div class="dropzone-action-btn">
                  <button type="button" [id]="'dropzone_delete_thumbnail_' + i" title="{{'App_Delete' | translate}}"
                     (click)="removeImage(i + 1, $event)">
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </div>
              <img [src]="getThumbnailImage(i + 1)" />
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div *ngIf="canAddImages()" class="dropzone-thumbnail">
      <button type="button" class="dropzone-picture thumbnail-picture no-picture"
           id="dropzone_add" (click)="dropzoneAdd()">
        <div class="dropzone-no-image">
          <div class="dropzone-add-thumbnail">
            <i class="fa fa-image dropzone-add-thumbnail-icon"></i>
          </div>
        </div>
      </button>
    </div>
  </div>
</div>

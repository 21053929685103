const prodUrl = 'https://ksportal.frontsystems.';
const devUrl = 'https://ksportalstage.frontsystems.';
const recoveryUrl = 'https://ksportal2.frontsystems.';
const prodEnv = 'prod';
const devEnv = 'dev';
const norwegianDomain = 'no';
const comDomain = 'com';
const authController = '/authentication/';
const registerEndpoint = `${authController}user/register/`;
const confirmEmailEndpoint = `${authController}email/confirm`;
const gdprEndpoint = '/v2/customer/gdpr';
const apiLabelsPath = '/api/labels';

const functionsDevUrl = 'https://keystone-functions-dev.frontsystems.';
const functionsProdUrl = 'https://keystone-functions-prod.frontsystems.';
const negotiateEndpoint = '/api/negotiate';
const registerNotificationGroupsEndpoint = '/api/RegisterNotificationGroups';
const registerDashboardEndpoint = '/api/RegisterDashboard';

const buildResources = (url, env): any[] => {
  return [
    { url: `${url}${norwegianDomain}${gdprEndpoint}`, scopes: null },
    { url: `${url}${comDomain}${gdprEndpoint}`, scopes: null },
    { url: `${url}${norwegianDomain}${registerEndpoint}`, scopes: null },
    { url: `${url}${comDomain}${registerEndpoint}`, scopes: null },
    { url: `${url}${norwegianDomain}${registerEndpoint}`, scopes: null },
    { url: `${url}${comDomain}${registerEndpoint}`, scopes: null },
    { url: `${url}${norwegianDomain}${confirmEmailEndpoint}`, scopes: null },
    { url: `${url}${comDomain}${confirmEmailEndpoint}`, scopes: null },
    { url: `${url}${norwegianDomain}`, scopes: [`https://b2c.frontsystems.com/${env}/keystone/cluster/all`] },
    { url: `${url}${comDomain}`, scopes: [`https://b2c.frontsystems.com/${env}/keystone/cluster/all`] }
  ];
}

const buildLocalResources = (env): any[] => {
  return [
    { url: apiLabelsPath, scopes: [`https://b2c.frontsystems.com/${env}/keystone/cluster/all`] }
  ];
}

const buildFunctionsResources = (url, env) => {
  return [
    { url: `${url}${norwegianDomain}${negotiateEndpoint}`, scopes: [`https://b2c.frontsystems.com/${env}/keystone/cluster/all`] },
    { url: `${url}${comDomain}${negotiateEndpoint}`, scopes: [`https://b2c.frontsystems.com/${env}/keystone/cluster/all`] },
    {
      url: `${url}${norwegianDomain}${registerNotificationGroupsEndpoint}`,
      scopes: [`https://b2c.frontsystems.com/${env}/keystone/cluster/all`]
    },
    {
      url: `${url}${comDomain}${registerNotificationGroupsEndpoint}`,
      scopes: [`https://b2c.frontsystems.com/${env}/keystone/cluster/all`]
    },
    { url: `${url}${norwegianDomain}${registerDashboardEndpoint}`, scopes: [`https://b2c.frontsystems.com/${env}/keystone/cluster/all`] },
    { url: `${url}${comDomain}${registerDashboardEndpoint}`, scopes: [`https://b2c.frontsystems.com/${env}/keystone/cluster/all`] }
  ];
}

export const resources = {
  dev: buildResources(devUrl, devEnv).concat(buildFunctionsResources(functionsDevUrl, devEnv)).concat(buildLocalResources(devEnv)),
  prod: buildResources(prodUrl, prodEnv).concat(buildFunctionsResources(functionsProdUrl, prodEnv))
                                        .concat(buildResources(recoveryUrl, prodEnv))
}

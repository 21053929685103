import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'key-select-field',
    templateUrl: './select-field.component.html',
    styleUrls: ['./select-field.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SelectFieldComponent),
        multi: true
    }]
})
export class SelectFieldComponent implements ControlValueAccessor {
    @Input() compare: string;
    @Input() hasFooter = true;
    @Input() id = '';
    @Input() label: string;
    @Input() mandatory: boolean;
    @Input() readOnly: boolean;
    @Input() display: string;
    @Input() select: string;
    @Input() options: DataSource;
    @Input() error: string;
    @Input() noValueMessage: string;
    @Input() canAddValues: boolean;
    @Input() addValueMessage: string;

    // tslint:disable-next-line: no-output-on-prefix
    @Output() onItemCreate: EventEmitter<string> = new EventEmitter();

    value: any;
    isDisabled: boolean;

    onChange: (_: any) => void = (_: any) => { };
    onTouched: () => void = () => { };

    constructor() {
        this.value = null;
        this.isDisabled = false;
    }

    getDisplayExpr(): string {
        return this.display != null ? this.display : '';
    }

    getSearchExpr(): string {
        return this.compare != null ? this.compare : '';
    }

    getValueExpr(): string {
        return this.select != null && this.select !== '' ? this.select : 'this';
    }

    getValue(): string {
        if (this.value != null) {
            return (this.value instanceof String || typeof (this.value) === 'string') ||
                (this.display == null || this.display === '') ?
                this.value : this.value[this.display];
        }

        return '';
    }

    handleAdd(event: any) {
        this.onItemCreate.emit(event.text);
    }

    handleChange(event: any) {
        this.value = event.value || null;

        this.onChange(this.value);
    }

    handleTouch() {
        this.onTouched();
    }

    hasErrors() {
        return this.error != null && this.error !== '';
    }

    hasLabel() {
        return this.label != null && this.label !== '';
    }

    hasValue() {
        if (this.value == null) {
            return false;
        }

        const value =
            (this.value instanceof String || typeof (this.value) === 'string') ||
                (this.display == null || this.display === '') ?
                this.value : this.value[this.display];

        return value && value.length;
    }

    writeValue(value: any): void {
        this.value = value || null;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}

import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Observable, timer, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { groupBy } from 'lodash-es';
import { PrintDataService } from '../services/print-data.service';
import { LabelSelectorStatus } from '../models/label-selector-status';
import { Serial } from '../models/serial';

interface SerialMap {
    [key: string]: Serial[];
}

@Component({
  selector: 'app-serials-generation-progress',
  templateUrl: './serials-generation-progress.component.html',
  styleUrls: ['./serials-generation-progress.component.scss']
})
export class SerialsGenerationProgressComponent implements OnInit, OnDestroy {

    @Input() orderId: number;
    @Input() productId: number;
    @Input() labelSelectorStatus: LabelSelectorStatus;
    @Input() startTimestamp: number;

    @Output() generationCompleted: EventEmitter<number[]> = new EventEmitter();

    percentage: number;
    estimatedTime: number;

    private polling$: Observable<SerialMap>;
    private pollingSubscription: Subscription;
    private completeEmitted: boolean;

    constructor(private printDataService: PrintDataService) { }

    ngOnInit() {
        this.completeEmitted = false;
        this.percentage = 0;
        this.estimatedTime = -1;

        if (this.productId != null) {
            this.polling$ = this.startPolling(this.orderId, this.productId);
        } else {
            this.polling$ = this.startPolling(this.orderId);
        }

        this.pollingSubscription = this.polling$.subscribe((serialMap: SerialMap) => this.setPrintProgress(serialMap));
    }

    ngOnDestroy() {
        if (!this.pollingSubscription.closed) {
            this.pollingSubscription.unsubscribe();
        }
    }

    getEstimatedTime() {
        const date = new Date(this.estimatedTime);
        const minutes = date.getMinutes().toString();
        const seconds = date.getSeconds().toString();

        return `${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
    }

    private calculatePercentage(labelSelectorStatus: LabelSelectorStatus, serialMap: SerialMap): number {
        let totalEpcs = 0;
        let validEpcs = 0;

        const keys = labelSelectorStatus.getSelectorItemKeys();

        keys.forEach((key) => {
            const currentLabels = serialMap[key] ? serialMap[key].length : 0;
            const totalLabels = labelSelectorStatus.getSelectorItem(key).totalLabels;

            totalEpcs += totalLabels;
            validEpcs += Math.min(totalLabels, currentLabels);
        });

        return Math.min((validEpcs / totalEpcs) * 100, 100);
    }

    private getSerialsToPrint(labelSelectorStatus: LabelSelectorStatus, serialMap: SerialMap): number[] {
        const keys = labelSelectorStatus.getSelectorItemKeys();

        let serials = [];

        keys.forEach((key) => {
            const resultLabels: number[] = serialMap[key] ? serialMap[key].map((item: Serial) => item.serialId) : [];
            const totalLabels = Math.min(resultLabels.length, labelSelectorStatus.getSelectorItem(key).totalLabels);

            if (totalLabels > 0) {
                serials = serials.concat(resultLabels.slice(0, totalLabels));
            }
        });

        return serials;
    }

    private mapToDict(result: Serial[]): SerialMap {
        return groupBy(result, (value: Serial) => `${value.productId}-${value.sizeRange}-${value.size}`);
    }

    private requestSerials(orderId: number, productId?: number): Observable<SerialMap> {
        return this.printDataService.getPurchaseOrderSerials(orderId, productId, false)
            .pipe(switchMap((unprintedLabels: Serial[]) => of(this.mapToDict(unprintedLabels))));
    }

    private setPrintProgress(serialMap: SerialMap) {
        const currentMilli = Date.now() - this.startTimestamp;
        const currentPercentage = this.calculatePercentage(this.labelSelectorStatus, serialMap);

        this.percentage = currentPercentage;
        this.estimatedTime = currentPercentage > 0 ? ((currentMilli * 100) / currentPercentage) - currentMilli : -1;

        if (currentPercentage === 100 && !this.completeEmitted) {
            this.completeEmitted = true;
            this.pollingSubscription.unsubscribe();

            const serials = this.getSerialsToPrint(this.labelSelectorStatus, serialMap);

            this.generationCompleted.emit(serials);
        }
    }

    private startPolling(orderId: number, productId?: number): Observable<SerialMap> {
        return timer(0, 2000).pipe(switchMap(_ => this.requestSerials(orderId, productId)));
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { ServerSettingsManagerService } from '@keystone-angular/core';

@Pipe({
    name: 'image'
})
export class ImagePipe implements PipeTransform {
    constructor(private serverSettingsManager: ServerSettingsManagerService) { }

    transform(blobName: string, size: number, fallbackUrl: string = null): string {
        size = size || 50;

        return blobName
            ? `${this.serverSettingsManager.getCdnImageServiceUrl()}/${size}/${size}/${blobName}`
            : fallbackUrl;
    }
}

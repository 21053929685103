export class PrintLabelsDialogCloseEvent {
    requestPerformed: boolean;
    requestSucceeded: boolean;
    errorMessage: string;

    constructor(requestPerformed: boolean, requestSucceeded?: boolean, errorMessage?: string) {
        this.requestPerformed = requestPerformed;

        if (requestSucceeded != null) {
            this.requestSucceeded = requestSucceeded;
        }

        if (errorMessage != null) {
            this.errorMessage = errorMessage;
        }
    }
}

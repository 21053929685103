export class LabelInfo {
    productId: number;
    sizeRange: number;
    size: number;

    constructor(productId: number, sizeRange: number, size: number) {
        this.productId = productId;
        this.sizeRange = sizeRange;
        this.size = size;
    }
}

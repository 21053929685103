import { DragContainer } from './drag-container';

export class DragGroup {
    get name(): string {
        return this.dgName;
    }

    private containers: DragContainer[];
    private dgName: string;

    constructor(name: string) {
        this.containers = [];
        this.dgName = name;
    }

    addContainer(element: Element): DragContainer {
        const newContainer = new DragContainer(element);

        this.containers.push(newContainer);

        return newContainer;
    }

    deleteContainer(container: DragContainer) {
        this.containers = this.containers.filter((item: DragContainer) => item !== container);
    }

    findContainer(element: Element): DragContainer {
        return this.containers.find((container: DragContainer) => container.element === element) || null;
    }

    isEmpty() {
        return this.containers.length === 0;
    }
}

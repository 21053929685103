import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {
    @Input() set date(date: Date) {
        this._date = date;
        this.dateChange.emit(date);
    }
    @Input() disabled: boolean;

    @Output() dateChange = new EventEmitter<Date>();

    // tslint:disable-next-line: adjacent-overload-signatures
    get date(): Date {
        return this._date;
    }

    // tslint:disable-next-line: variable-name
    private _date: Date;
}

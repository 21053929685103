import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { ContextService } from './context.service';

@Directive({
  selector: '[appIfEnterprisePreviewCompanyAdminDirective]'
})
export class IfEnterprisePreviewCompanyAdminDirective {
    constructor(contextService: ContextService, templateRef: TemplateRef<any>, viewContainer: ViewContainerRef) {
        if (contextService.user?.isCompanyAdmin && contextService.user?.store?.showFeaturesInPreview &&
          contextService.user?.store?.currentSubscription === 'E') {
            viewContainer.createEmbeddedView(templateRef);
        } else {
            viewContainer.clear();
        }
    }
}

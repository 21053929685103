import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-ribbon',
    templateUrl: './ribbon.component.html',
    styleUrls: ['./ribbon.component.scss']
})
export class RibbonComponent {
    @Input() text: string;
}

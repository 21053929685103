export class DragItem {
    get group(): string {
        return this.diGroup;
    }

    get container(): Element {
        return this.diContainer;
    }

    get element(): Element {
        return this.diElement;
    }

    private diContainer: Element;
    private diElement: Element;
    private diGroup: string;

    constructor(groupName: string, container: Element, element: Element) {
        this.diContainer = container;
        this.diElement = element;
        this.diGroup = groupName;
    }
}

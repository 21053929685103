<div class="form-group" [ngClass]="{'invalid-field': hasErrors()}">
    <label *ngIf="hasLabel() && labelOnTop" [for]="id">{{label}} <span *ngIf="mandatory">*</span></label>
    <div>
      <div class="toggle-custom" [ngClass]="{ 'label-on-top': labelOnTop }" [title]="title">
        <label *ngIf="hasLabel() && !labelOnTop" [for]="id">{{label}} <span *ngIf="mandatory">*</span></label>
        <label class="toggle pull-right" attr.data-on="{{captionOn}}" attr.data-off="{{captionOff}}">
          <input [id]="id" [name]="id"
                 [disabled]="isDisabled || readOnly"
                 type="checkbox" tabindex="-1"
                 [checked]="value" (change)="handleChange($event)"
                 (blur)="handleTouch()" />
          <span class="button-checkbox"></span>
        </label>
      </div>
    </div>
    <div *ngIf="hasErrors()" class="validation-error-msg">{{error}}</div>
    <div class="form-footer">
        <ng-content select="field-footer"></ng-content>
    </div>
</div>

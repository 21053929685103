import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SearchEvent } from './models/search-event';

@Component({
    selector: 'app-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent {
    @Input() clearable: boolean;
    @Input() placeholder: string;
    @Input() set value(value: string) {
        this._value = value;
        this.valueChange.emit(value);
    }

    // tslint:disable-next-line: no-output-on-prefix
    @Output() onSearch: EventEmitter<SearchEvent> = new EventEmitter();
    @Output() valueChange = new EventEmitter<string>();

    @ViewChild('searchbox', { static: false }) inputBoxRef: ElementRef;

    focused = false;
    // tslint:disable-next-line: adjacent-overload-signatures
    get value(): string {
        return this._value;
    }

    // tslint:disable-next-line: variable-name
    private _value: string;

    handleChange(event: any): void {
        this.search(event.target.value);
    }

    handleClearClick(): void {
        this.clearSearch();
    }

    handleClearKeyup(event: any): void {
        if (event.key === ' ' || event.key === 'Enter') {
            event.stopPropagation();
            event.preventDefault();
            this.clearSearch();
        }
    }

    handleSearchClick(): void {
        this.search(this.inputBoxRef.nativeElement.value);
    }

    handleSearchKeyup(event: any): void {
        if (event.key === ' ' || event.key === 'Enter') {
            event.stopPropagation();
            event.preventDefault();
            this.search(this.inputBoxRef.nativeElement.value);
        }
    }

    setFocused(focused: boolean) {
        this.focused = focused;
    }

    private clearSearch() {
        this.value = '';
        this.onSearch.emit(new SearchEvent('', []));
        this.inputBoxRef.nativeElement.focus();
    }

    private search(searchText: string) {
        // tslint:disable-next-line: triple-equals
        if (searchText == '') {
            this.onSearch.emit(new SearchEvent(searchText, []));
        } else {
            this.onSearch.emit(new SearchEvent(searchText, searchText.split(' ')));
        }
    }
}

<div class="form-group">
  <label for="name">{{label}}</label>
  <dx-tag-box id="name"
              placeholder="{{'App_Select' | translate}}"
              noDataText="{{'App_NoDataToDisplay' | translate}}"
              [searchEnabled]="true"
              [dataSource]="options"
              [value]="value"
              (onValueChanged)="handleSelection($event)"
              (onOpened)="blockBody()"
              (onClosed)="releaseBody()"
              (onItemClick)="releaseBody()">
  </dx-tag-box>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { FilterPipe } from './filter.pipe';

@Pipe({
  name: 'inArray'
})
export class InArrayPipe implements PipeTransform {
    constructor(private filterPipe: FilterPipe) { }

    transform(list: any[], arrayFilter: any, element: any) {
        if (arrayFilter) {
            return this.filterPipe.transform(list, (listItem: any) => {
                if (element) {
                    return arrayFilter.indexOf(listItem[element]) !== -1;
                } else {
                    return arrayFilter.indexOf(listItem) !== -1;
                }
            });
        }
    }
}

import { Injectable } from '@angular/core';
import { DataService } from '@keystone-angular/core';
import { map, Observable } from 'rxjs';
import { PosSettings } from '../models/pos-settings';
import { Region } from '../models/region';

@Injectable({
  providedIn: 'root'
})
export class SettingsDataService {
  private baseUrl = 'settings/';

  constructor(private dataService: DataService) { }

  checkIfRfidIsEnabled(): Observable<boolean> {
    return this.dataService.get<boolean>(`${this.baseUrl}stock/checkIfRfidEnabled`);
  }

  hasRecommendedPrice(): Observable<boolean> {
    return this.getPosList().pipe(map((posList: PosSettings[]) => {
      if (posList != null && posList.length > 0) {
        return posList.some((pos: PosSettings) => pos.priceListId > 0);
      }

      return false;
    }));
  }

  getPosList(): Observable<PosSettings[]> {
    const url = this.baseUrl + 'pos/get';
    return this.dataService.get<PosSettings[]>(url);
  }

  getRegion(regionId: number): Observable<Region> {
    return this.dataService.get<Region>(`${this.baseUrl}regions/${regionId}`);
  }

  getVAT(): Observable<number> {
    return this.dataService.get<number>(`${this.baseUrl}company/vat`);
  }
}

import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { DataService } from '@keystone-angular/core';
import { Observable } from 'rxjs';
import { pickBy } from 'lodash-es';
import { Serial } from '../models/serial';

@Injectable({
    providedIn: 'root'
})
export class PrintDataService {
    private baseUrl = 'print';

    constructor(private dataService: DataService) { }

    getPurchaseOrderSerials(orderId: number, productId?: number, printed?: boolean): Observable<Serial[]> {
        return this.dataService.get(`${this.baseUrl}/getSerials`, {
            params: new HttpParams({
                fromObject: pickBy({
                    orderId,
                    productId,
                    printed
                }, (item: any) => item != null)
            })
        });
    }

    generateSerials(labels: any, orderId: number): Observable<any> {
        return this.dataService.post(`${this.baseUrl}/generateSerials/${orderId}`, labels);
    }

    printLabelForSpecificSize(label: any): Observable<any> {
        return this.dataService.post(`${this.baseUrl}/printlabel`, label);
    }

    printProductLabels(inventory: any): Observable<any> {
        return this.dataService.post(`${this.baseUrl}/printlabels`, inventory);
    }

    printRFIDLabelForSpecificSize(label: any): Observable<any> {
        return this.dataService.post(`${this.baseUrl}/printRFIDLabel`, label);
    }

    printRFIDLabels(labels: any): Observable<any> {
        return this.dataService.post(`${this.baseUrl}/printRFIDLabels`, labels);
    }

    printRfidProductLabels(labels: any): Observable<any> {
        return this.dataService.post(`${this.baseUrl}/printRfidProductLabels`, labels);
    }

    reprintRFIDLabels(labels: any): Observable<any> {
        return this.dataService.post(`${this.baseUrl}/reprintRFIDLabels`, labels);
    }
}

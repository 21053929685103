<div class="form-group" [ngClass]="{'invalid-field': hasErrors()}">
    <label *ngIf="hasLabel()" [for]="id">{{label}} <span *ngIf="mandatory">*</span></label>
    <ng-container *ngIf="readOnly">
        <div>
            <em *ngIf="!hasValue()" class="text-muted">{{noValueMessage || '-'}}</em>
            <span *ngIf="hasValue()">{{getValue()}}</span>
        </div>
    </ng-container>
    <ng-container *ngIf="!readOnly">
        <div [ngClass]="{ 'input-group': canCreateGroups }">
            <dx-select-box [id]="id" [name]="id" class="form-devexpress"
                           placeholder="{{'App_Select' | translate}}"
                           displayExpr="name"
                           [searchEnabled]="true"
                           [dataSource]="productGroups"
                           [disabled]="isDisabled"
                           [value]="value"
                           (onValueChanged)="handleChange($event)"
                           (onClosed)="handleTouch()"
                           (onFocusOut)="handleTouch()"
                           [dropDownOptions]="{ width: '100%'}">>
            </dx-select-box>
            <span class="input-group-btn" *ngIf="canCreateGroups">
                <button type="button" [id]="id + '_add'" class="btn btn-dx"
                        title="{{'Pro_AddNewProductGroup' | translate}}"
                        [disabled]="isDisabled" (click)="handleAdd()">
                    <i class="fa fa-plus"></i>
                </button>
            </span>
        </div>
    </ng-container>
    <div *ngIf="hasErrors()" class="validation-error-msg">{{error}}</div>
    <div class="form-footer">
        <ng-content select="field-footer"></ng-content>
    </div>
</div>

import { Injectable } from '@angular/core';
import { DragGroup } from './core/drag-group';
import { DragItem } from './core/drag-item';
import { DropItem } from './core/drop-item';
import { Drag } from './core/drag';
import { DragContainer } from './core/drag-container';

interface GroupMap {
    [groupName: string]: DragGroup;
}

interface DragMap {
    [groupName: string]: Drag;
}

@Injectable({
  providedIn: 'root'
})
export class DragAndDropService {
    private groups: GroupMap = {};
    private drags: DragMap = {};

    addDraggable(groupName: string, container: Element, draggable: Element): DragItem {
        let group: DragGroup;
        let dragContainer: DragContainer;
        let dragItem: Element;

        group = this.getGroup(groupName);

        if (group == null) {
            return null;
        }

        dragContainer = group.findContainer(container);

        if (dragContainer == null) {
            return null;
        }

        dragItem = dragContainer.findDraggable(draggable);

        if (dragItem == null) {
            dragContainer.addDraggable(draggable);
        }

        return new DragItem(groupName, container, draggable);
    }

    addDroppable(groupName: string, droppable: Element): DropItem {
        let group: DragGroup;
        let dragContainer: DragContainer;

        group = this.getGroup(groupName);

        if (group == null) {
            group = this.createGroup(groupName);
        }

        dragContainer = group.findContainer(droppable);

        if (dragContainer == null) {
            dragContainer = group.addContainer(droppable);
        }

        return new DropItem(groupName, droppable);
    }

    deleteDraggable(dragItem: DragItem): void {
        const group = this.getGroup(dragItem.group);

        if (group != null) {
            const container = group.findContainer(dragItem.container);

            if (container != null) {
                container.deleteDraggable(dragItem.element);
            }
        }
    }

    deleteDroppable(dropItem: DropItem): void {
        const group = this.getGroup(dropItem.group);

        if (group != null) {
            const container = group.findContainer(dropItem.element);

            group.deleteContainer(container);

            if (group.isEmpty()) {
                this.deleteGroup(dropItem.group);
            }
        }
    }

    getActiveDrag(groupName: string): Drag {
        return this.drags[groupName] || null;
    }

    startDrag(dragItem: DragItem): boolean {
        const groupName = dragItem.group;
        const group = this.getGroup(groupName);

        if (group != null) {
            const container = group.findContainer(dragItem.container);

            if (container != null) {
                const draggable = container.findDraggable(dragItem.element);

                if (draggable != null) {
                    this.setDrag(groupName, container, draggable);

                    return true;
                }
            }
        }

        return false;
    }

    dragTo(dropItem: DropItem, targetChild: Element, before: boolean): boolean {
        const groupName = dropItem.group;
        const group = this.getGroup(groupName);
        const drag = this.getActiveDrag(groupName);

        if (group != null && drag != null) {
            const container = group.findContainer(dropItem.element);

            if (container != null) {
                const targetDraggable = container.findDraggable(targetChild);

                if (targetDraggable != null) {
                    drag.setTarget(container, targetDraggable, before);

                    return true;
                }
            }
        }

        return false;
    }

    finishDrag(dragItem: DragItem): boolean {
        const groupName = dragItem.group;
        const group = this.getGroup(groupName);
        const drag = this.getActiveDrag(groupName);

        if (group != null && drag != null) {
            const sourceContainer = drag.sourceContainer;
            const targetContainer = drag.targetContainer;

            if (sourceContainer !== null && targetContainer !== null) {
                if (sourceContainer !== targetContainer) {
                    sourceContainer.deleteDraggable(drag.draggedElement);
                    targetContainer.insertDraggable(drag.draggedElement, drag.targetIndex);
                } else {
                    sourceContainer.moveDraggable(drag.sourceIndex, drag.targetIndex);
                }

                return true;
            }
        }

        this.clearDrag(groupName);

        return false;
    }

    private createGroup(groupName: string): DragGroup {
        const newGroup = new DragGroup(groupName);

        this.groups[groupName] = newGroup;

        return newGroup;
    }

    private deleteGroup(groupName: string): void {
        delete this.groups[groupName];
    }

    private getGroup(groupName: string): DragGroup {
        return this.groups[groupName] || null;
    }

    private setDrag(groupName: string, sourceContainer: DragContainer, draggedElement: Element) {
        this.drags[groupName] = new Drag(groupName, sourceContainer, draggedElement, 'move');
    }

    private clearDrag(groupName: string) {
        delete this.drags[groupName];
    }
}

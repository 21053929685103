import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ProductLabelsSelector } from './product-labels-selector';
import { LabelSelectorStatus } from '../models/label-selector-status';
import { Size } from '../../home/product/models/size';
import { SizeRange } from '../../home/product/models/size-range';

@Component({
    selector: 'app-product-labels-selector',
    templateUrl: './product-labels-selector.component.html',
    styleUrls: ['./product-labels-selector.component.scss']
})
export class ProductLabelsSelectorComponent implements OnInit, OnChanges {
    @Input() inventory: any;
    @Input() usingRfid: boolean;
    @Input() hideSizesWithoutGtinOrSku: boolean;

    // tslint:disable-next-line: no-output-on-prefix
    @Output() onChange: EventEmitter<LabelSelectorStatus> = new EventEmitter();
    // tslint:disable-next-line: no-output-on-prefix
    @Output() onLoad: EventEmitter<LabelSelectorStatus> = new EventEmitter();
    // tslint:disable-next-line: no-output-on-prefix
    @Output() onSet: EventEmitter<LabelSelectorStatus> = new EventEmitter(true);

    private initialized: boolean;

    private selector: ProductLabelsSelector;

    constructor() {
        this.selector = new ProductLabelsSelector();
        this.initialized = false;
    }

    ngOnInit() {
        this.initialized = this.selector.initialize(this.inventory, this.usingRfid);

        if (this.initialized) {
            this.onLoad.emit(this.selector.getCurrentStatus());
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.initialized) {
            if (changes.usingRfid != null) {
                this.selector.setUsingRfid(changes.usingRfid.currentValue);

                this.onSet.emit(this.selector.getCurrentStatus());
            }
        }
    }

    getTotalLabelsToPrint(sizeRange: SizeRange, size: Size): number {
        return this.selector.getTotalLabelsToPrint(sizeRange.id, size.index);
    }

    getTotalItems(sizeRange: SizeRange, size: Size): number {
        return this.selector.getTotalItems(sizeRange.id, size.index);
    }

    updateTotalLabelsToPrint(sizeRange: SizeRange, size: Size, event: any) {
        this.selector.setTotalLabelsToPrint(sizeRange.id, size.index, event.value);

        this.onChange.emit(this.selector.getCurrentStatus());
    }
}

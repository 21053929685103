import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LocalisationService } from '@keystone-angular/core';

@Pipe({
  name: 'localNumber'
})
export class LocalNumberPipe implements PipeTransform {
    constructor(private localisationService: LocalisationService) { }

    transform(value: any, locale?: string): string {
        locale = locale || this.localisationService.getActiveLocale().id;
        const localeDecimalSymbol = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);

        if (!value && value !== 0) {
            return '';
        }

        if (typeof (value) !== 'string' && !(value instanceof String)) {
            value = value.toString();
        }

        if (value === '-') {
            return value;
        }

        value = value.substring(0, 16);

        const parsedNumber = this.localisationService.parseLocaleNumber(value, locale);
        const localNumberString = this.localisationService.toLocalNumberString(parsedNumber, locale);

        value = value.replace(',', localeDecimalSymbol).replace('.', localeDecimalSymbol);

        if (!Number.isInteger(parsedNumber)) {
            const trailingZeros = '0'.repeat(value.length - localNumberString.length);
            return localNumberString.concat(trailingZeros);
        }

        const firstDecimalIndex = value.indexOf(localeDecimalSymbol);
        return firstDecimalIndex > -1
            ? localNumberString.concat(value.substring(firstDecimalIndex))
            : localNumberString;
    }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrintAgent } from './models/print-agent';
import { PrintAgentDataService } from './print-agent-data.service';

@Injectable({
  providedIn: 'root'
})
export class PrintAgentManager {
    printAgentStore: PrintAgent[] = [];

    constructor(private printAgentDataService: PrintAgentDataService) { }

    getPrintAgents(): Observable<PrintAgent[]> {
        this.printAgentStore = [];

        return this.printAgentDataService
            .getPrintAgents()
            .pipe(map(printAgents => this.printAgentStore = printAgents));
    }

    incrementPrintAgentPrinterCount(printAgentId: string): void {
        this.printAgentStore.find(x => x.id === printAgentId).printerCount++;
    }

    decrementPrintAgentPrinterCount(printAgentId: string): void {
        this.printAgentStore.find(x => x.id === printAgentId).printerCount--;
    }

    sendRefreshCommand(printAgentId: string): Observable<any> {
        return this.printAgentDataService.sendRefreshCommand(printAgentId);
    }

      getPrintAgentMac(): Observable<string> {
        return this.printAgentDataService.getPrintAgentMac();
    }

    addPrintAgent(mac: string): Observable<boolean> {
        return this.printAgentDataService.addPrintAgent('"' + mac + '"');
    }
}

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad,
    Route, Router, RouterStateSnapshot, UrlSegment, UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UnsupportedBrowserGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(private router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.isBrowserSupported();
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.isBrowserSupported();
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this.isBrowserSupported();
    }

    private isBrowserSupported(): boolean {
        const userAgent = window.navigator.userAgent;
        const trident = userAgent.indexOf('Trident/');

        if (trident > 0) {
            const rv = userAgent.indexOf('rv:');
            const versionNumber = parseInt(userAgent.substring(rv + 3, userAgent.indexOf('.', rv)), 10);
            if (versionNumber === 11) {
                this.router.navigate(['/unsupported-browser'], { skipLocationChange: true });
                return false;
            }
        }

        return true;
    }
}

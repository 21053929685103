import { MsalInterceptorConfiguration } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { environment } from '../../environments/environment';

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  for (const { url, scopes } of environment.b2c.protectedResources) {
    protectedResourceMap.set(url, scopes);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationInsightsService } from '../../../framework/logging/application-insights.service';
import { PrintAgentManager } from '../print-agent/print-agent-manager.service';
import { CommunicationType, Model, Printer } from './models/printer';
import { PrinterTemplate } from './models/printer-template';
import { PrinterByStore, PrinterDataService } from './printer-data.service';

@Injectable({
  providedIn: 'root'
})
export class PrinterManager {
    printerStore: Printer[] = [];

    constructor(private applicationInsightsService: ApplicationInsightsService,
                private printerDataService: PrinterDataService,
                private printAgentManager: PrintAgentManager) { }

    getPrinters(printAgentId?: string): Observable<Printer[]> {
        this.printerStore = [];

        return this.printerDataService
            .getPrinters()
            .pipe(map(printers => {
                this.printerStore = printers;

                return this.filterPrinters(printAgentId);
            }));
    }

    async getPrinter(printerId: string): Promise<Printer> {
        return this.getPrinterFromStore(printerId) || await this.getPrinterById(printerId).toPromise();
    }

    getPrinterWithOnlinePath(printerId: string): Observable<Printer> {
        return this.printerDataService
            .getPrinterWithOnlinePath(printerId);
    }

    getPrinterById(printerId: string): Observable<Printer> {
        return this.printerDataService.getPrinter(printerId);
    }

    getPrintersByStore(): Observable<PrinterByStore[]> {
        return this.printerDataService.getPrintersByStore();
    }

    // TODO: Remove this method by the time migration is over
    newPrinter() {
        return new Printer({
            communicationType: CommunicationType.COM,
            comPort: 1,
            comPortSpeed: 9600,
            isRfid: false,
            model: Model.TSC,
            portNumber: 9100
        });
    }

    addComPortPrinter(printer: Printer): Observable<Printer> {
        return this.printerDataService
            .addPrinter(printer)
            .pipe(map(printerId => {
                if (printerId) {
                    printer.id = printerId;
                    printer.status = 'A';

                    this.printerStore.push(printer);
                    this.printAgentManager.incrementPrintAgentPrinterCount(printer.printAgentId);

                    return printer;
                }

                return null;
            }));
    }

    updatePrinter(printer: Printer): Observable<Printer> {
        return this.printerDataService
            .updatePrinter(printer)
            .pipe(map(() => {
                let printerToUpdate = this.getPrinterFromStore(printer.id);

                printerToUpdate = printer;

                return printerToUpdate;
            }));
    }

    deletePrinter(printer: Printer): Observable<boolean> {
        return this.printerDataService
            .deletePrinter(printer)
            .pipe(map(deleted => {
                if (deleted) {
                    for (let x = 0; x < this.printerStore.length; x++) {
                        if (this.printerStore[x].id === printer.id) {
                            this.printerStore.splice(x, 1);
                            this.printAgentManager.decrementPrintAgentPrinterCount(printer.printAgentId);
                            break;
                        }
                    }
                }

                return deleted;
            }));
    }

    filterPrinters(printAgentId: string): Printer[] {
        if (printAgentId) {
            const filteredPrinters = [];

            // tslint:disable-next-line: prefer-for-of
            for (let x = 0; x < this.printerStore.length; x++) {
                const printer = this.printerStore[x];

                if (printer.printAgentId === printAgentId) {
                    filteredPrinters.push(printer);
                }
            }

            return filteredPrinters;
        } else {
            return this.printerStore;
        }
    }

    getPrinterFromStore(printerId: string): Printer {
        return this.printerStore.find(printer => printer.id === printerId);
    }

    getPrintersTemplates(): Observable<PrinterTemplate[]> {
        return this.printerDataService.getPrintersTemplates();
    }

    // TODO: Move this somewhere else (probably the controller)
    getPrinterModels(): { key: string, value: string }[] {
        return Object.keys(Model).map(model => ({
            key: model,
            value: Model[model]
        }));
    }

    // TODO: Move this somewhere else (probably the controller)
    getCommunicationTypes(): { key: string, value: string }[] {
        return Object.keys(CommunicationType).map(communicationType => ({
            key: communicationType,
            value: communicationType
        }));
    }
}

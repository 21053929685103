import { Component, Input } from '@angular/core';
import { FilterDefinition } from '../../../filtered-search/models/filter-definition';
import { FilterComponent } from '../filter.component';

@Component({
    selector: 'app-select-filter',
    templateUrl: './select-filter.component.html',
    styleUrls: ['./select-filter.component.scss']
})
export class SelectFilterComponent extends FilterComponent<string> {
    @Input() options: any[];

    // tslint:disable-next-line: variable-name
    private _bodyClickFn: any;

    initFromData(filterDefinition: FilterDefinition, filterValue: any) {
        super.initFromData(filterDefinition, filterValue);

        this.value = filterValue;
        this.options = filterDefinition.source != null ? filterDefinition.source('') : [];
    }

    handleSelection(selection: any) {
        this.value = selection.value || null;
    }

    blockBody() {
        this._bodyClickFn = document.body.onclick;

        document.body.onclick = this.preventDefaultAndStopPropagation;
    }

    releaseBody() {
        document.body.onclick = this._bodyClickFn;
    }

    private preventDefaultAndStopPropagation(event: any) {
        event.preventDefault();
        event.stopPropagation();
    }
}

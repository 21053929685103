import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'key-switch-field',
    templateUrl: './switch-field.component.html',
    styleUrls: ['./switch-field.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SwitchFieldComponent),
        multi: true
    }]
})
export class SwitchFieldComponent implements ControlValueAccessor {
    @Input() captionOn: string;
    @Input() captionOff: string;
    @Input() error: string;
    @Input() id = '';
    @Input() label: string;
    @Input() labelOnTop: boolean;
    @Input() mandatory: boolean;
    @Input() readOnly: boolean;
    @Input() title: string;

    value: boolean;
    isDisabled: boolean;

    onChange: (_: any) => void = (_: any) => { };
    onTouched: () => void = () => { };

    constructor() {
        this.value = false;
        this.isDisabled = false;
    }

    handleChange(event: any) {
        this.value = event.target.checked || false;

        this.handleTouch();
        this.onChange(this.value);
    }

    handleTouch() {
        this.onTouched();
    }

    hasErrors() {
        return this.error != null && this.error !== '';
    }

    hasLabel() {
        return this.label != null && this.label !== '';
    }

    writeValue(value: boolean): void {
        this.value = value || false;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}

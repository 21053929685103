import { RegionVAT } from '../../../../../shared/settings-old/models/region-vat';

export class Group {
  accountIn: any = null;
  accountOut: any = null;
  alternativeVAT = false;
  deleted = false;
  // Id will always refers SUBGROUP table in DB
  id: any = null;
  name = '';
  open: boolean;
  // ParentId will always refers GROUP table in DB
  parentId: any = null;
  parentName: string;
  regionVATs: RegionVAT[];
  subgroups: Group[] = [];
  subgroupId: any;
  vatLowId = 1;
  vatHighId = 1;
  vat = 0.25;
}

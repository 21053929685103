import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterDefinition } from '../../filtered-search/models/filter-definition';

@Component({
    template: ''
})
export class FilterComponent<T> {
    @Input() name: string;
    @Input() label: string;
    @Input() set value(value: T) {
        this._value = value;
        this.onChange.emit(value);
    }

    get value(): T {
        return this._value;
    }

    // tslint:disable-next-line: no-output-on-prefix
    @Output() onChange: EventEmitter<T> = new EventEmitter();

    // tslint:disable-next-line: variable-name
    protected _value: T;

    initFromData(filterData: FilterDefinition, value: T) {
        this.name = filterData.name;
        this.label = filterData.label;
        this.value = value;
    }
}

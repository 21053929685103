<div class="form-group" [ngClass]="{'invalid-field': hasErrors()}">
    <app-checkbox [id]="id" [disabled]="isDisabled || readOnly"
              [checked]="value" (checkedChange)="handleChange($event)" (blur)="handleTouch()">
        <span *ngIf="hasLabel()">{{label}} <span *ngIf="mandatory">*</span></span>
    </app-checkbox>
    <div *ngIf="hasErrors()" class="validation-error-msg">{{error}}</div>
    <div class="form-footer">
        <ng-content select="field-footer"></ng-content>
    </div>
</div>

import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import {
  DataService, LayoutEventNotificationService, NotificationService,
  ServerSettingsManagerService, TranslatePipe
} from '@keystone-angular/core';
import Dropzone from 'dropzone';
import { TokenWrapperService } from '../../../../authentication/token-wrapper.service';
import { ApplicationInsightsService } from '../../../logging/application-insights.service';
import { ImagePipe } from '../../pipes/image.pipe';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'key-dropzone',
    templateUrl: './dropzone.component.html',
    styleUrls: ['./dropzone.component.scss']
})
export class DropzoneComponent implements AfterViewInit, OnDestroy {
    @Input() guid: string;
    @Input() isDisabled: boolean;

    @Input() set images(images: any[]) {
        if (this.dzImages !== images) {
            this.dzImages = images;
        }
    }

    get images(): any[] {
        return this.dzImages;
    }

    @ViewChild('dropzone', { static: false }) dropzoneRef: ElementRef;

    @Output() imagesChange: EventEmitter<any[]> = new EventEmitter();

    private imagesEndpoint = 'file/images';
    private imagesDeleteEndpoint = 'file/delete';
    private token: string;

    private maxFiles = 10;
    private dz: any;
    private dzImages: any[];

    constructor(private applicationInsightsService: ApplicationInsightsService,
                private tokenService: TokenWrapperService,
                private serverSettingsManager: ServerSettingsManagerService,
                private layoutEventNotificationService: LayoutEventNotificationService,
                private notificationService: NotificationService,
                private dataService: DataService,
                private imagePipe: ImagePipe,
                private translatePipe: TranslatePipe) { }
  ngAfterViewInit(): void {
    this.tokenService.getToken().subscribe(result => {
      this.token = result;
      this.initDropZone();
    });
  }

  initDropZone() {
    const me = this;

    me.dz = new Dropzone(this.dropzoneRef.nativeElement, {
      url: [this.serverSettingsManager.getServerApiPath(), this.imagesEndpoint].join('/'),
      maxFilesize: 10000,
      parallelUploads: 5,
      uploadMultiple: true,
      previewsContainer: null,
      acceptedFiles: 'image/*',
      headers: me.token != null ? { Authorization: me.token } : null,
      autoProcessQueue: true,
      accept(file: any, done: () => void) {
        if (me.images.length + this.files.length > me.maxFiles) {
          this.removeFile(file);
          return;
        }
        done();
      }
    });

    this.isDisabled ? me.dz.disable() : me.dz.enable();

    // tslint:disable-next-line: only-arrow-functions
    this.dz.on('processingmultiple', function () {
      me.layoutEventNotificationService.notifyHttpRequestStart('dropzone');
    });

    // tslint:disable-next-line: only-arrow-functions
    this.dz.on('completemultiple', function (files: any[]) {

      if (!files[0].xhr.response) { return; }

      const array = JSON.parse(files[0].xhr.response);

      if (array && array[0] && !me.guid) {
        me.guid = array[0].guid;
      }

      // tslint:disable-next-line: only-arrow-functions
      files.forEach(function (file, i) {
        if (file.status === 'success' && array[i] != null) {
          me.dzImages = me.dzImages.concat(array[i]);
        } else {
          me.notificationService.showError(me.translatePipe.transform(('Pro_ImageUploadingError'), file.name));
        }
        me.dz.removeFile(file);
      });

      me.imagesChange.emit(me.dzImages);
      me.layoutEventNotificationService.notifyHttpRequestEnd('dropzone');
    });

    // tslint:disable-next-line: only-arrow-functions
    this.dz.on('sending', function (file: any, xhr: any, formData: any) {
      if (!formData.has('guid') && me.guid) {
        formData.append('guid', me.guid);
      }
    });
    }

  ngOnDestroy(): void {
      this.dropzoneRef.nativeElement.dropzone.destroy();
  }

  canAddImages(): boolean {
      return !this.isDisabled && (this.hasImages() && this.dzImages.length < 10);
  }

  dropzoneAdd(): void {
      if (!this.isDisabled) {
          this.dz.hiddenFileInput.click();
      }
  }

  getActiveImage(): any {
      return this.imagePipe.transform(this.dzImages[0].blobName, 600);
  }

  getThumbnailImage(index: number): any {
      return this.imagePipe.transform(this.dzImages[index].blobName, 50);
  }

  hasImages(): boolean {
      return this.dzImages != null && this.dzImages.length > 0;
  }

  removeImage(i: number, e: any) {
      e.stopPropagation();
      e.preventDefault();

      const img = this.dzImages[i];
      const apiUrl = this.imagesDeleteEndpoint;

      const request = {
          guid: this.guid,
          blobName: img.blobName
      };

      this.dzImages = this.dzImages.filter((value: any, index: number) => index !== i);
      this.imagesChange.emit(this.dzImages);

      this.dataService.post(apiUrl, request).subscribe(() => { });
  }

  selectNextImage(): void {
      if (this.dzImages != null && this.dzImages.length > 1) {
          this.dzImages = [].concat(this.dzImages.slice(1, this.dzImages.length), [this.dzImages[0]]);
          this.imagesChange.emit(this.dzImages);
      }
  }

  selectPreviousImage(): void {
      if (this.dzImages != null && this.dzImages.length > 1) {
          this.dzImages = [].concat([this.dzImages[this.dzImages.length - 1]], this.dzImages.slice(0, this.dzImages.length - 1));
          this.imagesChange.emit(this.dzImages);
      }
  }

  sortThumbnail(event: any) {
      const fromIndex = event.sourceIndex + 1;
      const toIndex = event.targetIndex + 1;

      const images = [];

      if (fromIndex > toIndex) {
          this.dzImages.slice(0, toIndex).forEach((dzImage) => images.push(dzImage));
          images.push(this.dzImages[fromIndex]);
          this.dzImages.slice(toIndex, fromIndex).forEach((dzImage) => images.push(dzImage));
          this.dzImages.slice(fromIndex + 1).forEach((dzImage) => images.push(dzImage));

          this.dzImages = images;
      } else if (fromIndex < toIndex) {
          this.dzImages.slice(0, fromIndex).forEach((dzImage) => images.push(dzImage));
          this.dzImages.slice(fromIndex + 1, toIndex + 1).forEach((dzImage) => images.push(dzImage));
          images.push(this.dzImages[fromIndex]);
          this.dzImages.slice(toIndex + 1).forEach((dzImage) => images.push(dzImage));

          this.dzImages = images;
      }

      this.imagesChange.emit(this.dzImages);
  }

  thumbnailsCanBeSorted() {
    /*
     * Backend doesn't allow image ordering for now, therefore this logic can't be applied yet.
     *
     * return !this.isDisabled && this.dzImages.length > 2;
     * */

    return false;
  }
}

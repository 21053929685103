import { Injectable } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';

interface IdleDetectorTimer {
  id: number;
  seconds: number;
  subject: Subject<void>;
  subscription?: Subscription;
}

@Injectable({
  providedIn: 'root'
})
export class IdleDetectorService {
    private idleDetectorTimers: IdleDetectorTimer[] = [];
    private nextTimerId = 0;

    constructor() {
        document.body.addEventListener('click', () => {
            // tslint:disable-next-line: no-shadowed-variable
            for (const idleDetectorTimer of this.idleDetectorTimers) {
                idleDetectorTimer.subscription.unsubscribe();
                this.setTimerSubscription(idleDetectorTimer);
            }
        });
    }

    startDetecting(seconds: number) {
        const idleDetectorTimer: IdleDetectorTimer = {
          id: this.nextTimerId++,
          seconds,
          subject: new Subject()
        };
        this.idleDetectorTimers.push(idleDetectorTimer);

        this.setTimerSubscription(idleDetectorTimer);

        return idleDetectorTimer.subject.asObservable();
    }

    // tslint:disable-next-line: no-shadowed-variable
    private setTimerSubscription(idleDetectorTimer: IdleDetectorTimer) {
        idleDetectorTimer.subscription = timer(idleDetectorTimer.seconds * 1000).subscribe(() => {
            idleDetectorTimer.subscription.unsubscribe();
            idleDetectorTimer.subject.next();
            this.idleDetectorTimers.splice(
                this.idleDetectorTimers.findIndex(t => t.id === idleDetectorTimer.id),
                1
            );
        });
    }
}

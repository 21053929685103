import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GroupDataService, GroupPage } from './group-data.service';
import { Group } from './models/group';

@Injectable({
  providedIn: 'root'
})
export class GroupManagerService {
    private static DELAY: number = 1000 * 60 * 1;
    // tslint:disable-next-line: variable-name
    private _groups: any = {};
    // tslint:disable-next-line: variable-name
    private _vatList: any[] = [];

    constructor(private groupDataService: GroupDataService) { }

    delete(group: Group) {
        return this.groupDataService[!group.parentId ? 'deleteGroup' : 'deleteSubgroup'](group.id)
            .pipe(map(() => {
                if (!!this._groups[group.id]) {
                    this._groups[group.id] = undefined;
                }

                if (group.parentId && this._groups[group.parentId]) {
                    const subgroupIndex = this._groups[group.parentId].subgroups.findIndex((sg: Group) => sg.id === group.id);
                    if (subgroupIndex > -1) {
                        this._groups[group.parentId].subgroups.splice(subgroupIndex, 1);
                    }
                }
            }));
    }

    load(id: any): Observable<Group> {
        if (this._groups[id] && Date.now() - this._groups[id]._timespan < GroupManagerService.DELAY) {
            return of(Object.assign(new Group(), this._groups[id]));
        }

        return this.groupDataService.getProductGroup(id).pipe(map(group => {
            this._groups[group.id] = Object.assign({}, group);
            this._groups[group.id]._timespan = Date.now();

            return Object.assign(new Group(), group);
        }));
    }

    loadAll(params: any): Observable<GroupPage> {
        return this.groupDataService.getProductGroups(params).pipe(map(page => {
            page.items.forEach((item, i, array) => {
                if (this._groups[item.id] && Date.now() - this._groups[item.id]._timespan < GroupManagerService.DELAY) {
                    array[i] = this._groups[item.id];
                }

                array[i] = Object.assign(new Group(), array[i]);
            });

            return page;
        }));
    }

    loadGroupsOnly(): Observable<any> {
        return this.groupDataService.getProductGroupsOnly();
    }

    loadVATList(): Observable<any[]> {
        if (this._vatList.length > 0) {
            return of(Array.from(this._vatList));
        }

        return this.groupDataService.getVATList().pipe(map(data => {
            data.forEach((item: any) => {
                this._vatList.push({
                    id: item.id,
                    name: item.name
                });
            });
            return Array.from(this._vatList);
        }));
    }

    save(group: Group): Observable<Group> {
        return (!group.id ? this.groupDataService.saveProductGroup(group) : this.groupDataService.updateProductGroup(group));
    }
}

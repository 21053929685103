import { Injectable } from '@angular/core';
import { DataService } from '@keystone-angular/core';
import { Observable } from 'rxjs';
import { ExistingPerson } from './models/existing-person';
import { StoreAccess } from './models/store-access';
import { User } from './models/user';
import { ChangeUserType } from './models/change-user-type';
import { ChangeIntegrationUserScope } from './models/change-integration-user-scope';

export interface ChangePasswordRequest {
  newPassword: string;
  oldPassword: string;
  passwordConfirmation: string;
}

export interface GetUserResponse {
  roles: { id: number, name: string }[];
  stores: { id: number; name: string }[];
  user: User;
}

export interface UpdateStoreAccessResponse {
  addedStoreAccessIds: {
    key: number, value: string
  }[];
}

// TODO: This should probably go with the ContextService
export interface UserSetting {
  key: string;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  private usersUrl = 'v2/person';

  constructor(private dataService: DataService) { }

  addExistingUser(user: ExistingPerson): Observable<ExistingPerson> {
    const url = `${this.usersUrl}/add/existing`;

    return this.dataService.post(url, user);
  }

  addUser(user: User): Observable<string> {
    const url = this.usersUrl;

    return this.dataService.post(url, user);
  }

  changePassword(changePasswordRequest: ChangePasswordRequest): Observable<any> {
    const url = `${this.usersUrl}/password/change`;

    return this.dataService.post(url, changePasswordRequest);
  }

  changeUserType(user: ChangeUserType): Observable<User> {
    const url = `${this.usersUrl}/type/change/${user.id}`;

    return this.dataService.post(url, user);
  }

  changeIntegrationUserScope(user: ChangeIntegrationUserScope): Observable<User> {
    const url = `${this.usersUrl}/scope/change/${user.id}`;

    return this.dataService.post(url, user);
  }

  checkIfExists(email: string): Observable<boolean> {
    const url = `${this.usersUrl}/checkIfExists/${email}`;

    return this.dataService.get(url);
  }

  deleteStoreAccess(storeAccessId: string): Observable<boolean> {
    const url = `${this.usersUrl}/access/delete/${storeAccessId}`;

    return this.dataService.delete(url);
  }

  createDefaultPassword(userId: string): Observable<string> {
    const url = `${this.usersUrl}/password/default/${userId}`;

    return this.dataService.post(url, {}, { responseType: 'text' });
  }

  deleteUser(userId: string): Observable<boolean> {
    const url = `${this.usersUrl}/${userId}`;

    return this.dataService.delete(url);
  }

  getBrands(): Observable<any[]> {
    const url = `${this.usersUrl}/brands`;

    return this.dataService.get(url);
  }

  getUserProfile(): Observable<User> {
    return this.dataService.get(`${this.usersUrl}/profile`);
  }

  getStores(): Observable<any[]> {
    const url = `${this.usersUrl}/stores`;

    return this.dataService.get(url);
  }

  getStoreName(key: any): Observable<any> {
    const url = `${this.usersUrl}/store-name/${key}`;

    return this.dataService.get(url);
  }

  getStore(key: any): Observable<any> {
    const url = `${this.usersUrl}/store/${key}`;

    return this.dataService.get(url);
  }

  getUser(userId: string): Observable<GetUserResponse> {
    const url = `${this.usersUrl}/${userId}`;

    return this.dataService.get(url);
  }

  getUserByEmail(email: string): Observable<User> {
    const url = `${this.usersUrl}/loaduser/${email}`;

    return this.dataService.get(url);
  }

  getUsers(): Observable<User[]> {
    const url = this.usersUrl;

    return this.dataService.get(url);
  }

  getIntegrationUsers(): Observable<User[]> {
    const url = `${this.usersUrl}/integration-users/`;

    return this.dataService.get(url);
  }

  getUsersByStore(): Observable<User[]> {
    const url = `${this.usersUrl}/usersBystore`;

    return this.dataService.get(url);
  }

  getUserSettings(): Observable<any> {
    const url = `${this.usersUrl}/settings`;

    return this.dataService.get(url);
  }

  isCurrentPasswordCorrect(password: string): Observable<boolean> {
    const url = `${this.usersUrl}/checkIfPasswordCorrect`;

    return this.dataService.post<boolean>(url, { currentPassword: password }, {
      isBlocking: false,
      observe: 'body',
      responseType: 'json'
    });
  }

  checkIfPasswordBreached(password: string): Observable<boolean> {
    const url = `${this.usersUrl}/checkIfPasswordBreached`;

    return this.dataService.post<boolean>(url, { password }, {
      isBlocking: false,
      observe: 'body',
      responseType: 'json'
    });
  }

  // TODO: user ID should not be needed here at all
  isUniqueEmailAddress(id: string, email: string): Observable<boolean> {
    const url = `${this.usersUrl}/unique/email?id=${id}&emailAddress=${email}`;

    return this.dataService.get(url, {
      isBlocking: false,
      observe: 'body',
      responseType: 'json'
    });
  }

  // TODO: Type response
  sendInvitation(id: string): Observable<any> {
    const url = `${this.usersUrl}/invite/${id}`;

    return this.dataService.post(url, {});
  }

  updateAssignedBrands(userId: string, assignedBrands: any) {
    const url = `${this.usersUrl}/${userId}/brands/update`;

    return this.dataService.post(url, assignedBrands);
  }

  updateStoreAccess(userId: string, storeAccess: StoreAccess): Observable<UpdateStoreAccessResponse> {
    const url = `${this.usersUrl}/${userId}/access/${storeAccess.id}`;

    return this.dataService.post(url, storeAccess);
  }

  updateUser(user: User): Observable<UpdateStoreAccessResponse> {
    const url = `${this.usersUrl}/${user.id}`;

    return this.dataService.post(url, user);
  }

  updateUserProfile(user: User): Observable<User> {
    const url = `${this.usersUrl}/profile`;

    return this.dataService.post(url, user);
  }

  updateUserSettings(settings: UserSetting[]): Observable<any> {
    const url = `${this.usersUrl}/settings`;

    return this.dataService.post(url, settings);
  }

  getApiKey(personId: string, storeId: number): Observable<string> {
    const url = `${this.usersUrl}/apikey/${personId}` + (storeId ? `/${storeId}` : '');

    return this.dataService.get(url);
  }

  createApiKey(personId: string, storeId: number): Observable<string> {
    const url = `${this.usersUrl}/apikey/create`;

    return this.dataService.post(url, { personId, storeId });
  }

  revokeApiKey(personId: string, storeId: number): Observable<boolean> {
    const url = `${this.usersUrl}/apikey/revoke`;

    return this.dataService.post(url, { personId, storeId });
  }
}

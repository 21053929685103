import { Component } from '@angular/core';
import { RouterHistoryService } from './framework/navigation/router-history.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(routerHistoryService: RouterHistoryService) {
        routerHistoryService.start();
    }
}
